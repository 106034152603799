import { Component } from '@angular/core';
import { circularDataset, lineDataset } from '../models/graphs.model';
import Chart from 'chart.js/auto';
import { empleosGeneradosanioSubData, empleosGeneradosMesSubData } from '../models/empleosGenerados.model';

declare var bootstrap: any;

@Component({
  selector: 'app-competitivo',
  templateUrl: './competitivo.component.html',
  styleUrl: './competitivo.component.css',
})
export class CompetitivoComponent {
  public leyend = true;
  private meses: string[];

  // Bar chart
  public barDS: string;

  // Line chart
  public lineDS: string;

  // Circular chart
  public arcDS: string;
  public arcMonth: string = '';
  public arcYear: string = '';
  public dataDestinosComerciales : any;
  public dataOrigenComercial: any;
  public dataProductosVendidos: any;
  public dataProductosComprados: any;
  public dataVentasInternacionales: any;




  constructor() {
    this.meses = require('../../assets/json/meses.json');
    this.barDS = this.getBarDS();
    this.lineDS = this.getLineDS();
    this.arcDS = this.getArcDS();
  }

  ngOnInit(): void {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  getBarDS() {
    return `[
    {
      "name": "2026",
      "value": 70
    },
    {
      "name": "2025",
      "value": 30
    },
    {
      "name": "2024",
      "value": 120
    },
    {
      "name": "2023",
      "value": 70
    },
    {
      "name": "2022",
      "value": 60
    }
  ]`;
  }

  getLineDS() {
    return `{
  "dataset": [
    {
      "name": "PIB 2023",
      "data": [
        26,
        27,
        25,
        26,
        29,
        25
      ]
    },
    {
      "name": "PIB 2024",
      "data": [
        27,
        28,
        30,
        23,
        25,
        26
      ]
    }
  ],
  "dataLabels": [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun"
  ]
}`;
  }

  getArcDS() {
    const wsResponse = require('../../assets/json/empleoEstado.json');
    let anteriores: number = 0;
    let esteAnio: number = 2000;
    let nuevos: number = 0;
    let perdidos: number = 0;
    let mesid: number = 0;
    wsResponse.forEach((anio: any) => {
      esteAnio = parseInt(Object.keys(anio)[0]);
      for (const mes of anio[esteAnio]) {
        let alpha: number = 0;
        mesid = parseInt(Object.keys(mes)[0])
        let estemes = mes[mesid];
        if (anteriores == 0) {
          anteriores = parseInt(estemes)
        }
        alpha = parseInt(estemes) - anteriores;
        if (alpha > 0) {
          nuevos += alpha
        } else {
          perdidos += Math.abs(alpha);
        }
        anteriores = parseInt(estemes)
      };
    });
    this.arcMonth = this.meses[mesid]
    this.arcYear = esteAnio.toString();

    return '[{"name":"Nuevos","data":' + nuevos + ',"color":"rgb(16, 104, 235)"},{"name":"Perdidos","data":' + 
      perdidos + ',"color":"rgb(233, 233, 233)"}]';
  }

  // Tarejetas TOP

  getDataCardDestino() {
    return '{"title": "Destinos comerciales", "period": "Junio 2023", "icon": "../../assets/el-comercio-internacional.png", "data": [{"nombre": "Estados Unidos",    "porcentaje": "80.3%"}, {"nombre": "Honduras", "porcentaje": "4.43%"}, {"nombre": "Cánada","porcentaje": "3.93%" }]}';
  }

  getDataCardOrigenComercial() {
    return '{"title": "Origen de compras comerciales", "period": "Junio 2023", "icon": "../../assets/internacional_compras.png", "data": [{"nombre": "Estados Unidos",    "porcentaje": "56.8%"}, {"nombre": "China", "porcentaje": "10.7%"}, {"nombre": "Corea del Sur","porcentaje": "10.5%" }]}';
  }

  getProductosVendidos() {
    return '{"title": "Productos vendidos", "period": "Junio 2023", "icon": "../../assets/compras.png", "data": [{"nombre": "Alambres y Cables Eléctricos", "porcentaje": "26%"}, {"nombre": "Partes y Accesorios de Vehículos Automotores", "porcentaje": "16.2%"} , {   "nombre": "Oro", "porcentaje": "13.4%"}, { "nombre": "Manufacturas de Caucho Vulcanizado", "porcentaje": "9.29%"}, {"nombre": "Animales vivos (Bovinos)",   "porcentaje": "4.92%"}]}';
  }

  getProductosComprados() {
    return '{"title": "Productos comprados", "period": "Junio 2023", "icon": "../../assets/internacional.png", "data": [{"nombre": "Maíz", "porcentaje": "19.3%"}, {"nombre": "Partes y Accesorios de Vehículos Automotores", "porcentaje": "7.45%"} , {   "nombre": "Protección para Circuitos Eléctricos", "porcentaje": "13.4%"}, { "nombre": "Alambres y Cables Eléctricos", "porcentaje": "4.82%"}, {"nombre": "Alambre de Cobre",   "porcentaje": "3.86%"}]}';
  }

  getVentasInternacionales() {
    return'{"title": "Ventas internacionales", "period": "Junio 2023","data":[{"nombreTab":"Anual", "valor":"US$2,247M", "iconUrl":"../../assets/billete-de-banco.png","descripcion":""}, {"nombreTab":"Trimestral", "valor":"US$484M", "iconUrl":"../../assets/billete-de-banco.png","descripcion":""}, {"nombreTab":"Mensual", "valor":"US$149M", "iconUrl":"../../assets/billete-de-banco.png","descripcion":""}]}';
  }

}
