import { Component, Input } from '@angular/core';
import { DataCardInformation } from '../models/cardInformation.model';

@Component({
  selector: 'app-card-information',
  templateUrl: './card-information.component.html',
  styleUrl: './card-information.component.css',
})
export class CardInformationComponent {
  @Input({ required: true }) dataCard!: DataCardInformation;
}
