<footer class="footer">


  <div class="">
    <div class="row">

      <div class="col-md-5">
        <div class="row">
          <div class="col-sm-2">
            <img src="../../assets/gobdg.png" class="logo-footer">
          </div>
          <div class="col-sm-1"></div>
          <div class="col-sm-9 text-start">
            <span class="textClear">Secretaría de Desarrollo Económico</span>
            <p class="m-0">sedeco.durango.gob.mx</p>
            <h3>
              <a href="#" class="m-1">
                <i class="bi bi-facebook redes"></i>
              </a>
              <a href="#" class="m-1">
                <i class="bi bi-twitter-x redes"></i>
              </a>
            </h3>
            <small>
              <b>Dirección</b>
              <span class="redes">
                Blvd. Felipe Pescador 800, Zona Centro, CP 34000,<br />Durango, Dgo.
              </span>
              <br>
              <span>
                <b>Horario de oficina:</b>
                Lunes - Viernes 9:00 - 16:00 <br>
                <b>Teléfono: </b> 6181377947
              </span>
            </small><br><br>
            <button type="button" class="btn btn-dark">
              <i class="bi bi-sign-turn-right"></i>
              ¿Cómo llegar?
            </button> &nbsp;
            <button type="button" class="btn btn-dark">
              <i class="bi bi-telephone-fill"></i>
              Llamar
            </button>
          </div>
        </div>
      </div>

      <div class="col-md-4 px-5"></div>

      <div class="col-md-3 text-start">
        <span class="numTitle">Números de emergencia</span>
        <ul>
          <li class="m-1"><img src="../../assets/call.png" width="18px" height="18px"><b>911</b> Emergencias</li>
          <li class="m-1"><img src="../../assets/bomberos.png" width="18px" height="18px"><b>618 137.9598</b>
            Protección Civil</li>
          <li class="m-1"><img src="../../assets/cruz.png" width="18px" height="18px"><b>618 817.3444</b> Cruz Roja
          </li>
        </ul>
      </div>

    </div>
  </div>

  <div class="site-info">
    <div class="container">
      <div class="row">
        <div class="col">
          <span>© 2022 | Secretaría de Desarrollo Económico</span>
        </div>
        <div class="col">
          <a href="https://sedeco.durango.gob.mx/aviso-de-privacidad" class="privacy">
            Aviso de Privacidad
          </a>
        </div>
      </div>
    </div>
  </div>

</footer>