[
    {
        "rubro": "Alambres y cables electricos",
        "monto": 449
    },
    {
        "rubro": "Partes y Accesorios de Vehiculos Automotores",
        "monto": 311
    },
    {
        "rubro": "Metales preciosos",
        "monto": 257
    },
    {
        "rubro": "Plasticos y Cauchos ",
        "monto": 179
    },
    {
        "rubro": "Textiles",
        "monto": 63
    },
    {
        "rubro": "Rieles",
        "monto": 15
    }
]