
<h2>PIB Anual
  <button type="button" class="btn btn-white" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true"
    title="<b>Valor de la Producción</b><br/>
          Valor monetario total de los minerales extraídos y procesados en el estado durante un periodo
          específico, generalmente un año. Este indicador refleja la contribución económica directa de la industria minera.">
    <i class="bi bi-info-circle"></i>
  </button>
</h2>
<p>Periodo de Junio de 2023 a Junio 2024</p>
<div class="mainChart">
  <graph-line [ds]=pibJSON></graph-line>
</div>
<div class="text-sm-end">
</div>
