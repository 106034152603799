import { Component, Input, SimpleChanges } from '@angular/core';
import { dataCardTop } from '../models/cardTop.model';

@Component({
  selector: 'app-card-top',
  templateUrl: './card-top.component.html',
  styleUrl: './card-top.component.css'
})
export class CardTopComponent {
  @Input({ required: true }) data!: string;

  dataCard! : dataCardTop;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] !== undefined) {
      this.dataCard = JSON.parse(changes['data'].currentValue);      
    }
  }

 

  






}
