<div class="container">
    <h5>{{ dataCard.title }}</h5>
    <p>{{ dataCard.period }}</p>
    <div class="card">
        <div class="card-body">
            <div class="row">
                @for (item of dataCard.data; track $index) {
                <div class="col-sm-6">
                    <div class="item">
                        <img src="{{item.iconUrl}}" alt="">
                        <h6>{{ item.descripcion }}</h6>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
</div>