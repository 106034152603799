<div class="col-md-12">
  <h3>Filtros</h3>
  <hr>
  <div class="row">
    <div class="col-md-3">
      <label for="startDate" class="form-label">Fecha Inicio:</label>
      <input type="date" id="startDate" class="form-control">
    </div>
    <div class="col-md-3">
      <label for="endDate" class="form-label">Fecha Fin:</label>
      <input type="date" id="endDate" class="form-control">
    </div>
    <div class="col-md-3">
      <label for="cmb-sectores">Sectores:</label>
      <div class="custom-select-wrapper">
        <select id="cmb-sectores" class="custom-select" (change)="onSelectChangeSectores($event)">
          <option value="sec-inactive-null">--Seleccione--</option>
          <option value="sec-active-0">Todo</option>
          <option value="sec-active-1">Industrias de la Transformación</option>
          <option value="sec-active-2">Comercio</option>
          <option value="sec-active-3">Servicios para Empresas, personas y el hogar </option>
          <option value="sec-active-4">Servicios Sociales y Comunales</option>
          <option value="sec-active-5">Industria de la Construcción</option>
          <option value="sec-active-6">Transporte y Comunicaciones</option>
          <option value="sec-active-7">Agricultura, Ganadería, Silvicultura, Pezca y Caza </option>
          <option value="sec-active-8">Industrias Extractivas</option>
          <option value="sec-active-9">Ind. Eléctrica y Capacitación y Suministro de Agua Potable</option>
        </select>
      </div>
    </div>
    <div class="col-md-3">
      <label for="cmb-ciudadanos">Ciudadanos:</label>
      <select id="cmb-ciudadanos" class="custom-select" (change)="onSelectChangeCiudadanos($event)">
        <option value="ciu-inactive-null">--Seleccione--</option>
        <option value="ciu-active-1">Hombres</option>
        <option value="ciu-active-2">Mujeres</option>
        <option value="ciu-active-0">Todos</option>
      </select>
    </div>
    <div class="col-md-3 mt-3">
      <button class="btn w-100" [ngClass]="{'btn-primary': isMapPoliticoActive, 'btn-secondary': !isMapPoliticoActive}"
        (click)="setMapActionPolitico()">
        {{ isMapPoliticoActive ? 'Ocultar Mapa Político' : 'Mostrar Mapa Político' }}
      </button>
    </div>
  </div>
</div>