import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { barsDataDS, barsDataset } from '../../models/graphs.model';
@Component({
  selector: 'graph-bar',
  templateUrl: './bar.component.html',
  styleUrl: './bar.component.css',
})
export class BarComponent {
  constructor() {}

  @Input({ required: true }) ds?: string;
  dataset?: barsDataset;
  serieX: string[] = [];
  serieY: number[] = [];
  colors: string[] = [];
  cid: string = '';
  label: string = '';
  legend: boolean = false;
  bgColor: string = 'rgb(243, 246, 248)';
  aspect: boolean = false;
  pluginGraphIconEnergy: any = { id: 'graphiconnull' };
  pluginTextTopBar: any = { id: 'graphtexttopnull' };
  beginAt: number = 0;
  axis: "y" | "x" | undefined = 'x';
  showx: boolean = true;
  showy: boolean = false;
  width: number | null = null; 
  height: number | null = null; 
  public chart: any;

  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.dataset = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }

    this.cid = this.dataset?.cid ? this.dataset.cid : this.makeid();
    this.width = this.dataset?.width ? this.dataset.width : null;
    this.height = this.dataset?.height ? this.dataset.height : null;
  }

  ngAfterViewInit(): void {
    this.makeChart();
  }

  makeChart() {
    if (this.dataset) {
      this.legend = this.dataset.legend ? this.dataset.legend : false;
      this.bgColor = this.dataset.bgColor
        ? this.dataset.bgColor
        : 'rgb(243, 246, 248)';
      this.aspect = this.dataset.aspect ? this.dataset.aspect : false;
      this.beginAt = this.dataset.beginAt ? this.dataset.beginAt : 0;
      this.axis = this.dataset.axis;    

      this.showx = this.dataset.showx ? this.dataset.showx : true;
      this.showy = this.dataset.showy ? this.dataset.showy : false;
      this.dataset.dataset.forEach((ds) => {
        var color = ds.color ? ds.color : 'rgb(233, 233, 233)';
        this.serieX.push(ds.name);
        this.serieY.push(ds.value);
        this.colors.push(color);
      });

      if (this.dataset.topBar === 'icon') {
        // grahpIcon plugin block
        this.pluginGraphIconEnergy = {
          id: 'graphiconenergy',
          beforeInit: (chart: any, args: any, plugins: any) => {
            const fitValue = chart.legend.fit;
            chart.legend.find = function fit() {
              fitValue.bind(chart.legend)();
              return (this.height += 30);
            };
          },
          afterDatasetsDraw: (chart: any, args: any, plugins: any) => {
            const {
              ctx,
              data,
              chartArea: { top, left, right },
            } = chart;

            const energy = [
              'water',
              'sun',
              'arrow-up-from-ground-water',
              'wind',
              'seedling',
            ];

            const energyUnicode = [
              '\uF617',
              '\uF1D2',
              '\uF5A5',
              '\uF61D',
              '\uF3CF',
            ];
            ctx.save();

            data.energy.forEach((condition: any, index: number) => {
              const yPos = chart.getDatasetMeta(0).data[index]
                ? chart.getDatasetMeta(0).data[index]['y']
                : 0;
              const energyIndex = energy.indexOf(condition);
              ctx.fillStyle = '#1068EB';
              ctx.font = '18px bootstrap-icons';
              ctx.verticalAlign = 'middle';
              ctx.fillText(energyUnicode[energyIndex], 663, 7 + yPos);
            });
          },
        };
      } else if (this.dataset.topBar === 'dato') {
        this.pluginTextTopBar = {
          id: 'graphtexttop',
          afterDatasetsDraw(chart: any, args: any, plugins: any) {
            const { ctx, data } = chart;
            ctx.save();
            chart.getDatasetMeta(0).data.forEach((datapoint: any, index: number) => {
                ctx.beginPath();
                ctx.font = 'bold 12px sans-serif';
                ctx.fillStyle = 'black';
                ctx.fillText(`$${data.datasets[0].data[index]}`, datapoint.x, datapoint.y + 4);

            })

          }
        }
      }
      const data = {
        labels: this.serieX,
        datasets: [
          {
            label: this.dataset.label,
            axis: 'x',
            data: this.serieY,
            backgroundColor: this.colors,
            borderWidth: 1,
            //, barThickness: 50
            barPercentage: 0.8,
            borderRadius: 5,
            scale: 5
          },
        ],
        energy: [
          'water',
          'sun',
          'arrow-up-from-ground-water',
          'wind',
          'seedling',
        ],
      };

      this.chart = new Chart(this.cid, {
        type: 'bar',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: this.aspect,
          plugins: {
            legend: {
              display: this.legend,
            },
            title: {
              display: false,
              text: 'Inversión<br/>nnn',
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let thisval = context.dataset.data[context.dataIndex];
                  let label = thisval?.toString();
                  if(typeof thisval == "number"){
                    let value = Intl.NumberFormat('es-MX', { style: 'decimal' }).format( thisval ? thisval : 0, );
                    switch (context.dataset.label) {
                      case "Millones de Dólares":
                        label = value + " millones de dólares" ;
                      break;
                      case "Millones de Pesos":
                        label = value + " millones de pesos" ;
                      break;
                      default:
                        label = context.dataset.label + ": " + value ;
                        break;
                    }
                  }
                  return label;
                },
                footer: function (context) {
                  let ftr = "";
                  if(context[0].dataset.label == "Empleos"){
                    let dt = context[0].dataset.data;
                    let res = 0;
                    if(context[0].dataset.data[context[0].dataIndex - 1]){
                      let ini = context[0].dataset.data[context[0].dataIndex];
                      let fin = context[0].dataset.data[context[0].dataIndex-1];
                      if(typeof(ini) == "number" && typeof(fin) == "number"){
                        res = (ini - fin);
                      }
                    }
                    if(res > 0){
                      ftr = "Ganados: " + res.toString();
                    }else if(res < 0){
                      ftr = "Perdidos: " + res.toString();
                    }else{
                      ftr = "";
                    }
                  }
                  return ftr;
                }
              },/*
              titleFont: {
                size: 12
              },
              bodyFont: {
                size: 20
              },
              footerFont: {
                size: 10
              }*/
            }
          },
          scales: {
            y: {
              display: this.showy,
              beginAtZero: false,
              suggestedMin: this.beginAt,
              grid: {
                display: false,
              },
            },
            x: {
              display: this.showx,
              beginAtZero: true,
              grid: {
                display: false,
              },

            },          
          },
          color: this.bgColor,
          indexAxis: this.axis
        },
        plugins: [this.pluginBgColor, this.pluginGraphIconEnergy, this.pluginTextTopBar],

      });
      
      if (this.dataset.width ? this.dataset.width : 0 > 0 &&
        this.dataset.height ? this.dataset.height : 0 > 0) {
        this.chart.resize(this.dataset.width, this.dataset.height);
      }
    }
  }

  makeid(length: number = 5) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  pluginBgColor = {
    id: 'pluginBgColor',
    beforeDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = this.bgColor || 'rgb(243, 246, 248)';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    },
  };
}
