import { Component } from '@angular/core';
import { DataCardInformation } from '../../models/cardInformation.model';

@Component({
  selector: 'competitivo-energia',
  templateUrl: './energia.component.html',
  styleUrl: './energia.component.css',
})
export class CompetitivoEnergiaComponent {
  urlIconEficiencia!: string;
  indicEficiencia! : string;
  vEficiencia!: string;
  nEficiciencia!: string;
  i: number = 0;


  datosProdEnergetica!: string;
  datosCalentadorSolar!: string;
  datosForoEnergetico!: string;
  datosPIBEstatal!: string;
  datosKwhPbi!: string;
  datosTalleres!: string;
  datosMunicipios!: string;
  datosEspecialistas!: string;
  datosProduccionEnergeticaGraph!: string;
  dataConsumoEnerElectrica!: string;
  dataConsumoEnergeticoGraph!: string;
  dataUsuariosEnerElectrica!: string;
  dataEmpleos!: string;
  dataEnergiasRenovables!: string;
  datosEficiencia = [
    {
      icon: "../../../assets/transporte.svg",
      indicator: "Transporte",
      value: "1.07 - 1.77",
      note: "Emsiones de dióxido de carbono equivalente 2015 - Co2e" ,
    },
    {
      icon: "../../../assets/energia_2.svg",
      indicator: "Sector Energía Eléctrica",
      value: "0.45 - 1.98",
      note: "Emsiones de dióxido de carbono equivalente 2015 - Co2 Mton",
    },
    {
      icon: "../../../assets/basura.svg",
      indicator: "Residuos Sólidos",
      value: "0.0030 - 0.0155",
      note: "Emisiones de metano - 2014 - CH4 Mton",
    }
  ]

  dataImpactoAmbientalSocial!: Array<DataCardInformation>;
  dataPorcentajeSuprestaurada!: Array<DataCardInformation>;
  dataMedioAmbiente!: Array<DataCardInformation>;
  dataCuencashidrologicas!: Array<DataCardInformation>;

  constructor() {
    this.datosProdEnergetica = this.getDatosProdEnergetica();
    this.datosCalentadorSolar = this.getDatosCalentadoresSolares();
    this.datosForoEnergetico = this.getDatosForoEnergético();
    this.datosPIBEstatal = this.getDatosPIBEstatal();
    this.datosKwhPbi = this.getDatosKwhPbi();
    this.datosTalleres = this.getDatosTalleres();
    this.datosMunicipios = this.getDatosMunicipios();
    this.datosEspecialistas = this.getDatosEspecialistas();
    this.datosProduccionEnergeticaGraph =
      this.getDataProduccionEnergeticaGraph();
    this.dataConsumoEnerElectrica = this.getDataConsumoEnerElectrica();
    this.dataConsumoEnergeticoGraph = this.getDataConsumoEnergeticoGraph();
    this.dataUsuariosEnerElectrica = this.getDataUsuariosEnerElectrica();

    this.dataImpactoAmbientalSocial = [
      {
        title: 'Producción forestal',
        color: 'green',
        subtitle: '4.5',
        description: '2.8',
        unit: 'm',
        sup: true,
        supValue: 3
      },
      {
        title: 'Porcentaje de superficie restaurada',
        color: 'orange',
        subtitle: '0%',
        description: '22%',
      }
    ];

    this.dataMedioAmbiente = [
      {
        title:
          'Posición estatal en el subíndice de manejo sostenible del medio ambiente ',
        color: 'green',
        subtitle: '11',
        description: '9',
      },
      {
        title:
          'Porcentaje de residuos sólidos dispuestos correctamente',
        color: 'yellow',
        subtitle: '13%',
        description: '32%',
      },
      {
        title:
          'Porcentaje de municipios que operan la disposición final de residuos sólidos urbanos apegados a la NOM-083-20',
        color: 'yellow',
        subtitle: '80%',
        description: '100%',
      },
    ];

    this.dataCuencashidrologicas = [
      {
        title:'Porcentaje de superficie Restaurada',
        color: 'orange',
        subtitle: '0%',
        description: '15%',
      },
      {
        title:'Proporción de cuencas prioritarias atendidas',
        color: 'yellow',
        subtitle: '30%',
        description: '25%',
      },
      {
        title:'Proporción de usuarios capacitados en enfoque de Cuenca',
        color: 'green',
        subtitle: '30%',
        description: '30%',
      },
    ]

    this.dataEmpleos = '{"label":"GWh/a","aspect":true,"axis":"y", "bgColor": "#ffffff", "showy": true, "showx": true ,"icon": false , "width": 600,"height": 200,"dataset":[{"name":"Abril 2023","value":3363,"color":"rgb(233, 233, 233)"},{"name":"Mayo 2023","value":3346,"color":"rgb(233, 233, 233)"},{"name":"Junio 2023","value":3384,"color":"rgb(16, 104, 235)"},{"name":"Julio 2023","value":3394,"color":"rgb(107, 160, 240)"},{"name":"Agosto 2023","value":3370,"color":"rgb(186, 210, 245)"},{"name":"Septiembre 2023","value":3347,"color":"rgb(16, 104, 235)"},{"name":"Octubre 2023","value":3341,"color":"rgb(16, 104, 235)"},{"name":"Noviembre 2023","value":3338,"color":"rgb(16, 104, 235)"},{"name":"Diciembre 2023","value":3360,"color":"rgb(16, 104, 235)"},{"name":"Enero 2024","value":3333,"color":"rgb(16, 104, 235)"},{"name":"Febrero 2024","value":3421,"color":"rgb(16, 104, 235)"},{"name":"Marzo 2024","value":3413,"color":"rgb(16, 104, 235)"}, {"name":"Abril 2024","value":3406,"color":"rgb(16, 104, 235)"}, {"name":"Mayo 2024","value":3452,"color":"rgb(16, 104, 235)"}, {"name":"Junio 2024","value":3465,"color":"rgb(16, 104, 235)"}, {"name":"Julio 2024","value":3485,"color":"rgb(16, 104, 235)"}]}';

    this.dataEnergiasRenovables = '{"label":"GWh/a","aspect":true,"axis":"y", "bgColor": "#ffffff", "showy": true, "showx": true,"topBar": "icon", "width": 700, "height": 650, "dataset":[{"name":"Hidráulica","value":2284,"color":"rgb(233, 233, 233)"},{"name":"Solar","value":1028,"color":"rgb(233, 233, 233)"},{"name":"Geotérmica","value":910,"color":"rgb(16, 104, 235)"},{"name":"Eólica","value":387,"color":"rgb(107, 160, 240)"},{"name":"Biomasa","value":72,"color":"rgb(186, 210, 245)"}]}';

    this.setDatosEficiencia(this.i)


  }

  getDatosProdEnergetica() {
    return '{"title": "Producción energética", "value":"1592 MWatts", "description":"Año 2021", "titleTooltip":"Lorem Ipsum"}';
  }

  getDatosCalentadoresSolares() {
    return '{"value":"106", "description":"Calentadores solares"}';
  }

  getDatosForoEnergético() {
    return '{"value":"$202,590.00", "description":"Foro energético"}';
  }

  getDatosPIBEstatal() {
    return '{"value":"1.7$", "description":"PIB estatal"}';
  }

  getDatosKwhPbi() {
    return '{"value":"0.30", "description":"kWh/$PBI"}';
  }

  getDatosTalleres() {
    return '{"value":"$88,615.00","description":"en talleres"}';
  }

  getDatosMunicipios() {
    return '{"value":"12", "description":"Municipios"}';
  }

  getDatosEspecialistas() {
    return '{"value":"87", "description":"Especialistas"}';
  }

  getDataProduccionEnergeticaGraph() {
    return '{"dataset": [{ "name": "Ciclo combinado", "data": 49, "color": "rgb(255,195,0)" },{"name": "Termoeléctrica", "data": 37, "color": "rgb(45,47,239)" },{ "name": "Turbogas", "data": 14, "color": "rgb(181,23,158)" }],  "rotate": 0,"cutout": 30,"title": "","width": 300,"height": 200,"bgColor": "#FFFFFF","legend": true,"aspect": true,"reverse": true,"summary": false,"cid": ""}';
  }

  getDataConsumoEnerElectrica() {
    return '{"dataset": [{"name": "Anual: ","data": [2810.073, 2965.821, 2912.509, 2918.63, 2817.406, 2819.06, 3053.831, 2929.102, 3.237726, 3.383069, 3.168656, 3.288431, 1.683039],"tension": 0,"color": "rgb(16, 104, 235)","label": "GW/H"}],"dataLabels": ["I/2010","I/2011", "I/2013", "I/2014", "I/2015", "I/2016", "I/2017", "I/2018", "I/2019", "I/2020", "I/2021", "I/2022"],"aspect":false,"legend":false, "cid":""}';
  }

  getDataConsumoEnergeticoGraph() {
    return '{"dataset": [{ "name": "Industria", "data": 50, "color": "rgb(255,195,0)" },{"name": "Hogares", "data": 20, "color": "rgb(45,47,239)" },{ "name": "Otros", "data": 30, "color": "rgb(181,23,158)" }],  "rotate": 0,"cutout": 30,"title": "","width": 300,"height": 200,"bgColor": "#FFFFFF","legend": true,"aspect": true,"reverse": true,"summary": false,"cid": ""}';
  }

  getDataUsuariosEnerElectrica() {
    return '{"dataset": [{"name": "Anual: ","data": [2810073, 2965821, 2912509, 291863, 2817406, 281906, 3053831, 2929102, 3237726, 3383069, 3168656, 3288431, 1683039],"tension": 0,"color": "rgb(16, 104, 235)","label": "Usuarios"}],"dataLabels": ["I/2010","I/2011", "I/2013", "I/2014", "I/2015", "I/2016", "I/2017", "I/2018", "I/2019", "I/2020", "I/2021", "I/2022"],"aspect":false,"legend":false, "cid":""}';
  }

   //#region Cambio de gráfica
   changeInversion(atras: boolean) {
    let invs = this.datosEficiencia.length;
    if (atras) {
      if (this.i == 0) {
        this.i = invs - 1;
      } else {
        this.i -= 1;
      }
    } else {
      if (this.i == invs - 1) {
        this.i = 0;
      } else {
        this.i += 1;
      }
    }
    console.log(this.i);
    
    this.setDatosEficiencia(this.i);
  }

  setDatosEficiencia(i: number) {
    this.indicEficiencia = this.datosEficiencia[i].indicator;
    this.vEficiencia = this.datosEficiencia[i].value;
    this.nEficiciencia = this.datosEficiencia[i].note;
    this.urlIconEficiencia = this.datosEficiencia[i].icon;
    // this.titleEficiencia = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(this.inversiones[this.i].inversion,);
  }
}
