<div class="row">
    <h4>Ernergías renovables</h4>
    <!-- <div class="col-md-3"></div> -->
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <graph-bar class="content-graph" [ds]="dataEnergiasRenovables"></graph-bar>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <h4>Resilencia energética</h4>
    <div class="col-md-4">
        <app-card-label [data]='datosTalleres'></app-card-label>
    </div>
    <div class="col-md-4">
        <app-card-label [data]='datosMunicipios'></app-card-label>
    </div>
    <div class="col-md-4">
        <app-card-label [data]='datosEspecialistas'></app-card-label>
    </div>
</div>

<div class="row">
    <h4>Consumo de energía eléctrica</h4>
    <div class="col-md-4">
        <div class="container">
            <h5>Gigawatts/hora</h5>
            <div class="card">
                <div class="card-body card-min">
                    <graph-line class="content-graph" [ds]="dataConsumoEnerElectrica"></graph-line>
                </div>
            </div>
        </div>

    </div>
    <div class="col-md-4 d-flex align-items-end">
        <div class="container">
            <div class="card card-min">
                <div class="d-flex card-body">
                    <graph-circ class="content-graph" [ds]="dataConsumoEnergeticoGraph"></graph-circ>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="container">
            <h5>Usuarios</h5>
            <div class="card card-min">
                <div class="card-body">
                    <graph-line class="content-graph" [ds]="dataUsuariosEnerElectrica"></graph-line>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="row">
    <h4>Empleos en el sector energético</h4>
    <div class="row">
        <div class="col-md-4">
            <div class="content">
                <div class="card card-min">
                    <div class="card-body">
                        <graph-bar class="content-graph" [ds]="dataEmpleos"></graph-bar>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <app-card-label [data]='datosPIBEstatal' [min_h]="'15rem'"></app-card-label>
        </div>
        <div class="col-md-4">
            <app-card-label [data]='datosKwhPbi' [min_h]="'15rem'"></app-card-label>
        </div>
    </div>

</div>

<div class="row">
    <div class="col-md-6 col-ms-12">
        <!-- <h4></h4> -->
        <h4>Producción energética</h4>
        <app-card-label class="min-12" [data]='datosProdEnergetica'></app-card-label>
        <div class="container mt-3">
            <div class="card min-12">
                <div class="d-flex card-body">
                    <graph-circ class="content-graph" [ds]="datosProduccionEnergeticaGraph"></graph-circ>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6 d-flex flex-column">
        <h4>Eficiencia</h4>
        <div [ngStyle]="{'height': '10rem'}">
            <p>El rápido aumento de las energías renovables las cuales cubren el 40% del aumento en la demanda primaria y
                las mejoras en la eficiencia energética que desempeñan unn papel muy importante al eliminar la tensión del
                lado de la oferta, ya que sin ellas, el aumento proyectado en el uso final de la energíasería más del doble.
            </p>
        </div>
        <h5>Gases y Compuestos de Efecto Invernadero (Mton)</h5>
        <div class="d-flex align-items-end mt-3">
            <div class="card w-card">
                <div class="card-body min-15">
                    <div class="row">
                        <div class="col-sm-2 d-flex align-items-center justify-content-center">
                            <button type="button" class="btn btn-white" (click)="changeInversion(true)">&#60;</button>
                        </div>
                        <div class="col-sm-8">
                            <div class="d-flex flex-column justify-content-center">
                                <div class="eficiencia">
                                    <img src="{{ urlIconEficiencia }}" class="img-size" alt="">
                                    <h3>{{ indicEficiencia }}</h3>
                                    <h5>{{ vEficiencia }}</h5>
                                    <p>{{ nEficiciencia }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2 d-flex align-items-center justify-content-center">
                            <button type="button" class="btn btn-white" (click)="changeInversion(false)">&#62;</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  

</div>

<div class="row">
    <h4>Impacto ambiental y social</h4>
    <div class="row">
        <h6>Aprovechamiento forestal</h6>
        <div class="col-md-2"></div>
        @for (item of dataImpactoAmbientalSocial; track $index) {
        <div class="col-md-4">
            <app-card-information [dataCard]="item"></app-card-information>
        </div>
        }
    </div>

    <div class="row">
        <h6>Medio Ambiente</h6>
        @for (item of dataMedioAmbiente; track $index) {
        <div class="col-md-4">
            <app-card-information [dataCard]="item"></app-card-information>
        </div>
        }
    </div>

    <div class="row">
        <h6>Cuencas hidrológicas</h6>
        @for (item of dataCuencashidrologicas; track $index) {
        <div class="col-md-4">
            <app-card-information [dataCard]="item"></app-card-information>
        </div>
        }
    </div>


</div>

<div class="row">
    <div class="col-md-6 col-ms-12">
        <h4>Inversión en infraestructura energética</h4>
        <app-card-label [data]='datosCalentadorSolar'></app-card-label>

        <div class="mt-3">
            <app-card-label [data]='datosForoEnergetico' [min_h]="'15rem'"></app-card-label>
        </div>
    </div>
    <div class="col-md-6 col-sm-12">
        <h4>Innovación y tecnología</h4>
        <div class="innovacion">
            <div class="mb3">
                <div class="row">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                </div>
                <div class="row">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                </div>
                <div class="row">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                    <img src="../../../assets/cell_energy_solar.svg" alt="">
                </div>
            </div>
            <div>
                <p>
                    EOSOL subestación elevadora de potencia y un parque solar de generación y transmisión de energia
                    eléctrica.
                </p>
            </div>
        </div>
    </div>
</div>