import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompetitivoComponent } from './competitivo/competitivo.component';
import { EmpleosGeneradosComponent } from './empleos-generados/empleos-generados.component';

const routes: Routes = [
  { path: '', component: CompetitivoComponent },
  { path: 'empleosGenerados', component: EmpleosGeneradosComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }