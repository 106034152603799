[
    {
        "municipio": "Canatlán",
        "municipioId": 1,
        "x": 352.15244,
        "y": 568.89063
    },
    {
        "municipio": "Canelas",
        "municipioId": 2,
        "x": -2.9874871,
        "y": 423.13956
    },
    {
        "municipio": "Coneto de Comonfort",
        "municipioId": 3,
        "x": 362.4166,
        "y": 439.56223
    },
    {
        "municipio": "Cuencamé",
        "municipioId": 4,
        "x": 499.09436,
        "y": 499.09436
    },
    {
        "municipio": "Durango",
        "municipioId": 5,
        "x": 341.88828,
        "y": 696.1662
    },
    {
        "municipio": "General Simón Bolivar",
        "municipioId": 6,
        "x": 680.60553,
        "y": 517.56982
    },
    {
        "municipio": "Gómez Palacio",
        "municipioId": 7,
        "x": 621.07343,
        "y": 293.81116
    },
    {
        "municipio": "Guadalupe Victoria",
        "municipioId": 8,
        "x": 506.11484,
        "y": 612.00012
    },
    {
        "municipio": "Guanaceví",
        "municipioId": 9,
        "x": 89.389977,
        "y": 217.85641
    },
    {
        "municipio": "Hidalgo",
        "municipioId": 10,
        "x": 352.15247,
        "y": 199.38094
    },
    {
        "municipio": "Indé",
        "municipioId": 11,
        "x": 331.62415,
        "y": 318.44519
    },
    {
        "municipio": "Lerdo",
        "municipioId": 12,
        "x": 596.43945,
        "y": 351.2905
    },
    {
        "municipio": "Mapimí",
        "municipioId": 13,
        "x": 502.00919,
        "y": 215.80359
    },
    {
        "municipio": "Mezquital",
        "municipioId": 14,
        "x": 395.26193,
        "y": 924.03058
    },
    {
        "municipio": "Nazas",
        "municipioId": 15,
        "x": 502.00919,
        "y": 406.71695
    },
    {
        "municipio": "Nombre de Dios",
        "municipioId": 16,
        "x": 465.05823,
        "y": 720.80023
    },
    {
        "municipio": "Ocampo",
        "municipioId": 17,
        "x": 120.18248,
        "y": 88.528023
    },
    {
        "municipio": "El Oro",
        "municipioId": 18,
        "x": 259.77505,
        "y": 326.65652
    },
    {
        "municipio": "Otáez",
        "municipioId": 19,
        "x": 120.18249,
        "y": 513.46423
    },
    {
        "municipio": "Pánuco de Coronado",
        "municipioId": 20,
        "x": 444.47708,
        "y": 562.73218
    },
    {
        "municipio": "Peñón Blanco",
        "municipioId": 21,
        "x": 499.90353,
        "y": 509.35855
    },
    {
        "municipio": "Poanas",
        "municipioId": 22,
        "x": 508.11487,
        "y": 687.9549
    },
    {
        "municipio": "Pueblo Nuevo",
        "municipioId": 23,
        "x": 243.29956,
        "y": 827.54749
    },
    {
        "municipio": "Rodeo",
        "municipioId": 24,
        "x": 405.47327,
        "y": 402.6113
    },
    {
        "municipio": "San Bernardo",
        "municipioId": 25,
        "x": 183.76743,
        "y": 180.90546
    },
    {
        "municipio": "San Dimas",
        "municipioId": 26,
        "x": 157.08061,
        "y": 614.05298
    },
    {
        "municipio": "San Juan Guadalupe",
        "municipioId": 27,
        "x": 772.93018,
        "y": 546.30951
    },
    {
        "municipio": "San Juan del Río",
        "municipioId": 28,
        "x": 423.94876,
        "y": 490.88306
    },
    {
        "municipio": "San Luis del Cordero",
        "municipioId": 29,
        "x": 454.74124,
        "y": 369.76599
    },
    {
        "municipio": "San Pedro del Gallo",
        "municipioId": 30,
        "x": 442.42426,
        "y": 318.44519
    },
    {
        "municipio": "Santa Clara",
        "municipioId": 31,
        "x": 641.49609,
        "y": 566.83783
    },
    {
        "municipio": "Santiago Papasquiaro",
        "municipioId": 32,
        "x": 212.45424,
        "y": 447.77359
    },
    {
        "municipio": "Súchil",
        "municipioId": 33,
        "x": 475.21671,
        "y": 792.64935
    },
    {
        "municipio": "Tamazula",
        "municipioId": 34,
        "x": -62.625237,
        "y": 449.82645
    },
    {
        "municipio": "Tepehuantes",
        "municipioId": 35,
        "x": 101.60131,
        "y": 343.07919
    },
    {
        "municipio": "Tlahualilo",
        "municipioId": 36,
        "x": 598.3866,
        "y": 150.11301
    },
    {
        "municipio": "Topia",
        "municipioId": 37,
        "x": -23.621452,
        "y": 380.03018
    },
    {
        "municipio": "Vicente Guerrero",
        "municipioId": 38,
        "x": 522.69598,
        "y": 739.2229
    },
    {
        "municipio": "Nuevo Ideal",
        "municipioId": 39,
        "x": 313.30713,
        "y": 478.51324
    }
]