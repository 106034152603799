[
    {
        "municipio": "Durango",
        "planta": "Industrial an Logistic Center of Durango"
    },
    {
        "municipio": "Gómez Palacio",
        "planta": "CG Park"
    },
    {
        "municipio": "Gómez Palacio",
        "planta": "La Encantada Industrial Park"
    },
    {
        "municipio": "Gómez Palacio",
        "planta": "Centalia Industrial Park"
    },
    {
        "municipio": "Gómez Palacio",
        "planta": "Laguna Industrial Park"
    }
]