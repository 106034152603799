import Chart from "chart.js/auto";
import { barsDataDS, circularDataDS, circularDataset, lineDS } from "../app/models/graphs.model";

export class durangoCommon {


  //#region Gráficos

  /**
   * Actualiza un gráfico de lineas existente
   * @param chartID {string} id del canvas que contiene el gráfico
   * @param dataset {lineDS[]} nuevos valores para el gráfico
   */
  public updateLineChart(
    chartID: string,
    dataset: lineDS[]
  ) {
    let chart = Chart.getChart(chartID);
    // Cambiar la escala
    var minI = 9999999;
    var maxI = 0;
    dataset.forEach(sy => {
      minI = minI > Math.min.apply(null, sy.data) ? Math.min.apply(null, sy.data) : minI;
      maxI = maxI < Math.max.apply(null, sy.data) ? Math.max.apply(null, sy.data) : maxI;
    });
    minI = minI < 1 ? minI : minI - 5;
    maxI = maxI + 5;

    if (chart) {
      if (chart.config.options) {
        if (chart.config.options.scales) {
          if (chart.config.options.scales['y']) {
            chart.config.options.scales['y'].max = maxI;
            chart.config.options.scales['y'].min = minI;
          }
        }
      }
      chart.data.datasets = dataset;
      chart.update();
    }
  }

  /**
   * Actualiza un gráfico de barras existente
   * @param chartID {string} id del canvas que contiene el gráfico
   * @param dataset {barsDataDS[]} nuevos valores para el gráfico
   */
  public updateBarChart(
    chartID: string,
    dataset: barsDataDS[]
  ) {
    let chart = Chart.getChart(chartID);
    if (chart) {
      let labels: string[] = []
      let data: number[] = []
      let colors: string[] = []
      dataset.forEach(dt => {
        labels.push(dt.name);
        data.push(dt.value);
        if (dt.color)
          colors.push(dt.color)
      });
      if (colors.length == 0)
        colors = this.getStdColors();

      chart.data.datasets[0].data = data;
      chart.data.datasets[0].backgroundColor = colors;
      chart.data.labels = labels;
      chart.update();
    }

  }

  /**
   * Actualiza un gráfico de arco existente
   * @param chartID {string} id del canvas que contiene el gráfico
   * @param dataset {circularDataDS[]} nuevos valores para el gráfico
   */
  public updateArcChart(
    chartID: string,
    dataset: circularDataDS[]) {
    let chart = Chart.getChart(chartID);
    if (chart) {
      var names: string[] = [];
      var bgc: string[] = [];
      var borders: number[] = [];
      var datas: number[] = [];
      dataset.forEach(sy => {
        names.push(sy.name);
        datas.push(sy.data);
        if (sy.color) {
          bgc.push(sy.color);
        } else {
          bgc.push("rgb(16, 104, 235)");
        }
        if (sy.border) {
          borders.push(sy.border);
        } else {
          borders.push(2);
        }
      });
      const datag = {
        labels: names,
        datasets: [{
          data: datas,
          backgroundColor: bgc,
          borderWidth: borders,
        }]
      };
      chart.data = datag;
      chart.update();
    }
  }

  //#endregion


  //#region Colores

  /**
   * Array de colores en tonos de azul
   * @returns color[]
   */
  public getStdColors() {
    return [
      'rgb(16, 104, 235)',
      'rgb(107, 160, 240)',
      'rgb(186, 210, 245)'
    ]
  }

  /**
   * Array de colores amarillo, azul, morado, gris
   * @returns color []
   */
  public getAmbColors() {
    return [
      'rgb(255,195,0)',
      'rgb(45,47,239)',
      'rgb(181,23,158)',
      'rgb(213,213,213)'
    ]
  }

  //#endregion


}