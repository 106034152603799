import { Component, Input, SimpleChanges } from '@angular/core';
import { DataCardWithTabs } from '../models/cardWithBars.model';

@Component({
  selector: 'app-card-with-bars',
  templateUrl: './card-with-bars.component.html',
  styleUrl: './card-with-bars.component.css',
})
export class CardWithBarsComponent {
  @Input({ required: true }) data!: string;

  dataCard!: DataCardWithTabs;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] !== undefined) {
      this.dataCard = JSON.parse(changes['data'].currentValue);
    }
  }
}
