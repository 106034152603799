[
    {
        "2022": [
            {
                "12": 255680
            }
        ]
    },
    {
        "2023": [
            {
                "1": 258022
            },
            {
                "2": 260506
            },
            {
                "3": 260333
            },
            {
                "4": 260826
            },
            {
                "5": 261211
            },
            {
                "6": 260830
            },
            {
                "7": 259414
            },
            {
                "8": 260941
            },
            {
                "9": 261590
            },
            {
                "10": 262051
            },
            {
                "11": 261715
            },
            {
                "12": 256548
            }
        ]
    },
    {
        "2024": [
            {
                "1": 259169
            },
            {
                "2": 260555
            },
            {
                "3": 259834
            },
            {
                "4": 261976
            },
            {
                "5": 261246
            },
            {
                "6": 261612
            },
            {
                "7": 259013
            }
        ]
    }
]