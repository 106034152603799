import { Component, Input, SimpleChanges } from '@angular/core';
import { dataCardActEconomica } from '../models/cardActividadEconomica.model';

@Component({
  selector: 'app-card-act-econ',
  templateUrl: './card-act-econ.component.html',
  styleUrl: './card-act-econ.component.css'
})
export class CardActEconComponent {
  @Input({required:  true}) data!: string;
  dataCard!: dataCardActEconomica;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] !== undefined) {
      this.dataCard = JSON.parse(changes['data'].currentValue);   
    }
  }


}
