<div class="container">
  <div class="my-5">
    <h1>
      Bienvenido a Durango Competitivo
    </h1>
    <p>
      Un espacio donde convergen la innovación, la industria, y el progreso.
      Aquí, trabajamos juntos para fortalecer la economía del estado, apoyando a
      empresas y emprendedores en su camino hacia el éxito.
    </p>
  </div>
  <div class="row">
    <div class="col-xl-4">
      <competitivo-inversion [ds]=barDS></competitivo-inversion>
    </div>
    <div class="col-xl-4">
      <competitivo-pib-anual [ds]=lineDS></competitivo-pib-anual>
    </div>
    <div class="col-xl-4">
      <competitivo-empleos [ds]=arcDS [anio]=arcYear [mes]=arcMonth></competitivo-empleos>
    </div>
  </div>

  <div class="m-5"></div>

  <div class="accordion">
    <div class="accordion-item my-5">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed accordion-h" type="button" data-bs-toggle="collapse"
          data-bs-target="#accordionMineria" aria-expanded="false" aria-controls="accordionMineria">
          Minería
        </button>
      </h2>
      <div id="accordionMineria" class="accordion-collapse collapse">
        <div class="accordion-body">
          <competitivo-mineria></competitivo-mineria>
        </div>
      </div>
    </div>
    <div class="accordion-item my-5">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed accordion-h" type="button" data-bs-toggle="collapse"
          data-bs-target="#accordionEnergia" aria-expanded="false" aria-controls="accordionEnergia">
          Energía
        </button>
      </h2>
      <div id="accordionEnergia" class="accordion-collapse collapse">
        <div class="accordion-body">
          <competitivo-energia></competitivo-energia>
        </div>
      </div>
    </div>
    <div class="accordion-item my-5">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed accordion-h" type="button" data-bs-toggle="collapse"
          data-bs-target="#accordionPYMES" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          PYMES
        </button>
      </h2>
      <div id="accordionPYMES" class="accordion-collapse collapse">
        <div class="accordion-body">
          <competitivo-pymes></competitivo-pymes>
        </div>
      </div>
    </div>
    <div class="accordion-item my-5">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed accordion-h" type="button" data-bs-toggle="collapse"
          data-bs-target="#accordionIndustria" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          Industria
        </button>
      </h2>
      <div id="accordionIndustria" class="accordion-collapse collapse">
        <div class="accordion-body">
          <competitivo-industria></competitivo-industria>
        </div>
      </div>
    </div>
    <div class="accordion-item my-5">
      <h2 class="accordion-header">
        <button class="accordion-button collapsed accordion-h" type="button" data-bs-toggle="collapse"
          data-bs-target="#accordionComercio" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
          Comercio
        </button>
      </h2>
      <div id="accordionComercio" class="accordion-collapse collapse">
        <div class="accordion-body">
          <competitivo-comercio></competitivo-comercio>
        </div>
      </div>
    </div>
  </div>
</div>
