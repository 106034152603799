<h2>
    Comparativa Empleos
</h2>
<div class="graphContainer border rounded p-3 h9">
    <div class=" mx-auto">
        <div class="container">

            <div class="my-1 ">
                <i class="bi bi-gear-fill h1" style="color:rgb(178, 178, 178);"></i><br>
                <h2>{{generadostStr}}</h2>
                <div class="progress">
                    <div class="progress-bar" role="progressbar" id="progGen">
                    </div>
                </div>
                <small>Empleos Generados
                    <button type="button" class="btn btn-white" data-bs-toggle="tooltip" data-bs-placement="top"
                        data-bs-html="true"
                        title="<b>Generados</b><br/>Lorem Ipsum Dolom">
                        <i class="bi bi-info-circle"></i>
                    </button>
                </small>
            </div>
            <br>
            <div class="my-1">
                <i class="bi bi-gear h1" style="color:rgb(178, 178, 178);"></i><br>
                <h2>{{perdidostStr}}</h2>
                <div class="progress">                    
                    <div class="progress-bar" role="progressbar" id="progPerd" style="background-color: rgb(181, 23, 158);">
                    </div>
                </div>
                <small>Empleos Perdidos
                    <button type="button" class="btn btn-white" data-bs-toggle="tooltip" data-bs-placement="top"
                        data-bs-html="true"
                        title="<b>Perdidos</b><br/>Lorem Ipsum Dolom">
                        <i class="bi bi-info-circle"></i>
                    </button>
                </small>
            </div>

        </div>
    </div>
</div>