<div class="row">
    <div class="col-md-4">
        <div class="container">
            <h5>Total de ventas en dólares</h5>
            <p>Junio 2023</p>
            <div class="card card-min">
                <div class="card-body c-align">
                    <div class="dato-content">
                        <h2>{{dataVentaDolares.valor}}</h2>
                    </div>
                    <div class="icon-content">
                        <img src="{{dataVentaDolares.icon}}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <app-card-top [data]="dataDestinosComerciales"></app-card-top>
    </div>
    <div class="col-md-4">
        <app-card-top [data]="dataOrigenComercial"></app-card-top>
    </div>
</div>

<div class="row">
    <div class="col-md-4">
        <app-card-with-tabs [data]="dataVentasInternacionales"></app-card-with-tabs>
    </div>

    <!-- <div class="col-md-6">
        <div class="container">
            <h5>Productos vendidos</h5>
            <p>Junio 2023</p>
            <div class="card card-min">
                <div class="card-body cp-0">
                    <div class="d-flex content-graph">
                        <graph-circ [ds]="dataProductosVendidosGrafica"></graph-circ>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

</div>

<div class="row">
    <div class="col-md-6 col-sm-12">
        <div class="container">
            <div class="contain-tooltip">
                <p class="title">
                    Productos vendidos
                </p>
                <button type="button" class="btn btn-white p-0" data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-html="true" title="Lorem ipsum">
                    <i class="bi bi-info-circle"></i>
                </button>
            </div>
            <p>Junio 2023</p>
            <div class="card h-14">
                <div class="card-body">
                    <div class="d-flex content-graph">
                        <graph-circ [ds]="dataProductosVendidosGrafica"></graph-circ>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-6 col-sm-12">
        <div class="container">
            <div class="contain-tooltip">
                <p class="title">Productos comprados</p>
                <button type="button" class="btn btn-white p-0" data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-html="true" title="Lorem ipsum">
                    <i class="bi bi-info-circle"></i>
                </button>
            </div>
            <p>Junio 2023</p>
            <div class="card h-14">
                <div class="card-body">
                    <div class="d-flex content-graph">
                        <graph-circ [ds]="dataProductosCompradossGrafica"></graph-circ>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="row">
    <div class="col-md-6">
        <app-card-top [data]="dataProductosVendidos"></app-card-top>
    </div>
    <div class="col-md-6">
        <app-card-top [data]="dataProductosComprados"></app-card-top>
    </div>
</div>

<div class="row">
    <div class="col-md-6 d-flex flex-column justify-content-between">
        <app-card-act-econ [data]="dataActividadesEconomicas"></app-card-act-econ>
        <app-card-with-tabs-and-graph [data]="dataVentasIntGrafica"></app-card-with-tabs-and-graph>
    </div>
    <div class="col-md-6">
        <app-card-with-bars [data]="dataTop15Ventas"></app-card-with-bars>
    </div>
</div>