import { Component, Input } from '@angular/core';
import { circularDataDS, circularDataset } from '../../models/graphs.model';

@Component({
  selector: 'empleos-totales',
  templateUrl: './totales.component.html',
  styleUrl: './totales.component.css'
})
export class EmpleosTotalesComponent {
  
  @Input({ required: true }) ds?: string;
  @Input({ required: true }) ultAnio?: string;
  @Input({ required: true }) ultMes?: string;

  public empleosJSON: string = '{}';
  private arcData?: circularDataDS[];

  constructor() {
  }

  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.arcData = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }
    this.empleosJSON = this.getEmpleosDS();
  }
  
  getEmpleosDS() {
    let resStr = '{}';
    if(this.arcData){
      let arcDS: circularDataset ={
        dataset: this.arcData,
        rotate: 70,
        cutout: 70,
        title: "",
        width: 400,
        height: 250,
        bgColor: "#FFFFFF",
        legend: true,
        aspect: false,
        reverse: true,
        summary: true
      }
      resStr = JSON.stringify(arcDS);

    }
    return resStr;
  }
}
