
<h2>Empleos Generados
    <button type="button" class="btn btn-white" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true"
      title="<b>Valor de la Producción</b><br/>
          Valor monetario total de los minerales extraídos y procesados en el estado durante un periodo
          específico, generalmente un año. Este indicador refleja la contribución económica directa de la industria minera.">
      <i class="bi bi-info-circle"></i>
    </button>
  </h2>
  <p>Periodo de Diciembre de 2022 a {{mes}} {{anio}}</p>
  <div class="mainChart">

    <div class="mainChartDonut d-flex justify-content-center">
      <graph-circ [ds]=empleoJSON></graph-circ>
    </div>
  </div>
  <div class="text-sm-end">
    <a routerLink="/empleosGenerados" class="link-warning">Ver detalle</a>
  </div>
