<div class="container">
    <p class="title">{{ dataCard.title }}</p>

    <div class="d-flex flex-column align-items-center">
        <div class="circle" [ngClass]="dataCard.color"> </div>
        <p class="number">{{ dataCard.subtitle }} {{ dataCard.unit }}<sup *ngIf="dataCard.sup">{{ dataCard.supValue
                }}</sup></p>
        <p> Meta: {{ dataCard.description }} {{ dataCard.unit}}<sup *ngIf="dataCard.sup">{{ dataCard.supValue }}</sup>
        </p>
    </div>
</div>