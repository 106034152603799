import { Component, OnInit, Input, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { Chart, ChartDataset } from 'chart.js';
import { GeoJSONData, PopulationData, SectorData } from '../models/durango.model';
import { color } from 'd3';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { durangoCommon } from '../../shared/common';

@Component({
  selector: 'app-info-map',
  templateUrl: './info-map.component.html',
  styleUrl: './info-map.component.css'
})
export class InfoMapComponent implements OnChanges, AfterViewInit {
  @Input() mapAction!: string;
  @Input() municipalityData!: any;
  @Input() employeeData!: any;
  populationData: PopulationData[] = [];
  employmentData: PopulationData[] = [];
  sectoresData: SectorData[] = [];
  showSectores: boolean = false;
  showCiudadanos: boolean = false;
  showMapaPoliticos: boolean = false;


  partidosItem = [
    { color: '#FF6961', number: 21, imagen: ['assets/partidos/ico_verde.svg','assets/partidos/ico_pt.svg','assets/partidos/ico_morena.svg','assets/partidos/ico_rsp.svg'] },
    { color: '#000080', number: 13, imagen: ['assets/partidos/ico_pan.svg','assets/partidos/ico_pri.svg','assets/partidos/ico_prd.svg'] },
    { color: '#1C542D', number: 3, imagen: ['assets/partidos/ico_pri.svg']},
    { color: '#ffff00', number: 1,imagen: ['assets/partidos/ico_prd.svg'] },
    { color: '#F28930', number: 1, imagen: ['assets/partidos/ico_mc.svg']}
  ];

  private readonly geojsonUrl = 'assets/durango.geojson';
  private employmentChart: Chart | undefined;
  private pyramidChart: Chart | undefined;
  private sectorChart: Chart | undefined;
  constructor() { }

  ngAfterViewInit(): void {

    fetch(this.geojsonUrl)
      .then(response => response.json())
      .then((data: GeoJSONData) => {
        // Procesar los datos de población
        this.populationData = data.features[0].properties.poblacion.map(d => ({
          ageRange: Object.keys(d)[0],
          male: d[Object.keys(d)[0]].male,
          female: d[Object.keys(d)[0]].female
        }));

        // Procesar los datos de empleos
        this.employmentData = data.features[0].properties.empleos.map(d => ({
          ageRange: Object.keys(d)[0],
          male: d[Object.keys(d)[0]].male,
          female: d[Object.keys(d)[0]].female
        }));
        debugger;
        // Procesar los datos de sectores
        this.sectoresData = data.features[0].properties.sectores.map(d => ({
          option: Object.keys(d)[0],
          title: d[Object.keys(d)[0]].title,
          value: d[Object.keys(d)[0]].value,
          positions: d[Object.keys(d)[0]].positions // Asegúrate de que posiciones sea LatLng
        }));


        // Dibujar las gráficas iniciales
        this.drawEmploymentChart('Todos', this.employmentData);
        //this.drawCitizenPyramidChart('0', this.populationData);
        //this.drawSectorChart(this.sectoresData);
        let divJobs = document.getElementById('newJobsChart');
        let progJobs = this.drawProgressCharts('Empleos Nuevos', 1000, 3000);

        if (divJobs)
          divJobs.innerHTML += progJobs;
        let divJobsExisting = document.getElementById('existingJobsChart');
        let progobsExisting = this.drawProgressCharts('Empleos Pre-existentes', 3000, 10000);
        if (divJobsExisting)
          divJobsExisting.innerHTML += progobsExisting;

      })
      .catch(error => {
        console.error('Error al cargar los datos del GeoJSON:', error);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    debugger;
    if (changes['mapAction'] && changes['mapAction'].currentValue) {
      const comboPress = this.mapAction.split('-');
      this.decideFunctionMap(comboPress[0], comboPress[1], comboPress[2]);
    }

    if (changes['municipalityData'] && this.municipalityData?.populationData) {
      const comboPress = this.mapAction.split('-');
      
        this.updatePyramidChart(comboPress[0], comboPress[1], comboPress[2], this.municipalityData.populationData);
    }

    if (changes['employeeData'] && this.employeeData?.populationData) {

      this.drawEmploymentChart('Todos', this.employeeData.populationData)
    }
  }

  private decideFunctionMap(selectedCombo: string, decisionbool: string, valueSelected: string) {

    switch (selectedCombo) {
      case "sec":
        const sectores = this.sectoresData;
        let filteredSectores: SectorData[] = [];
        this.showSectores = decisionbool === "active";

        if (valueSelected === null || valueSelected === "0") {
          filteredSectores = sectores;
        } else {
          filteredSectores = sectores.filter(sector => sector.option === valueSelected);
        }
        setTimeout(() => {
          this.drawSectorChart(filteredSectores);
        }, 2000);


        break;
      case "ciu":
        this.showCiudadanos = decisionbool == "active" ? true : false;

        setTimeout(() => {
          this.drawCitizenPyramidChart(valueSelected, this.populationData);
        }, 2000);

        break;
      case "pol":
        this.showMapaPoliticos = decisionbool == "active" ? true : false;
        break;

      default:
        this.showSectores = false;
        this.showCiudadanos = false;
        this.showMapaPoliticos = false;
        break;
    }

  }


  private drawSectorChart(sectores: SectorData[]) {
    let comun = new durangoCommon;

    const sectorTitles = sectores.map(sector => sector.title);
    const sectorValues = sectores.map(sector => sector.value);
    const colores: string[] = [
      'rgba(255,255,255, 0.8)',
      'rgba(255,255,255, 0.5)',
      'rgba(255,255,255, 0.4)',
      'rgba(255,255,255, 0.3)',
      'rgba(255,255,255, 0.2)',
      'rgba(255,255,255, 0.8)',
      'rgba(255,255,255, 0.4)',
      'rgba(255,255,255, 0.3)',
    ]
    const pinesColors = comun.getAmbColors();

    // Destruir el gráfico existente si existe
    if (this.sectorChart) {
      this.sectorChart.destroy();
    }

    // Crear el gráfico
    this.sectorChart = new Chart('sectorChart', {
      type: 'bar',
      data: {
        labels: sectorTitles,
        datasets: [
          {
            label: 'Sectores',
            data: sectorValues,
            backgroundColor: pinesColors,
            borderRadius: 5
          }
        ]
      },
      options: {
        indexAxis: 'y', // Cambiar a gráfico de barras horizontales
        scales: {
          x: {
            display: true,
            beginAtZero: true,
            grid: {
              display: true,
              lineWidth: ({ tick }) => tick.value == 0 ? 1 : 0,
              color: "#FFF"
            },
            stacked: true, // Asegúrate de que las barras estén apiladas si es necesario
            ticks: {
              display: false,
              color: '#FFF', // Color de los textos en el eje y
              font: {
                size: 14, // Tamaño del texto
                weight: 'bold' // Negrita en los textos
              },
              callback: function (value: number | string) {
                return typeof value === 'number' ? Math.abs(value) : value;
              }
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              color: "#FFF",
              font: {
                size: 10,
                weight: 'normal',
              }
              // Quitar para ver eje Y completo
              ,callback: function (a, b, c) {
                const este = sectorTitles[b].split(' ');
                return este[este.length -1] + ' ●';
              }
            },
            stacked: true,
            position: 'left',

          }
        },
        plugins: {
          legend: {
            display: false,
            labels: {

              color: '#FFF', // Color del texto en la leyenda
              font: {
                size: 14, // Tamaño del texto en la leyenda
                weight: 'bold' // Negrita en la leyenda
              }
            }
          },
          datalabels: {
            color: '#000',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 10
                }
              }
            },
            align: 'end',
            anchor: 'end',
            offset: -25,
            formatter: function (value, context) {
              //return Math.abs(value);
              return Math.abs(Math.trunc(value / 1000)) + 'K';
            }
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let thisval = context.dataset.data[context.dataIndex];
                let label = thisval?.toString();
                if (typeof thisval == "number") {
                  let value = Intl.NumberFormat('es-MX', { style: 'decimal' }).format(thisval ? Math.abs(thisval) : 0,);
                  switch (context.label) {
                    default:
                      label = context.dataset.label + ": " + value;
                      break;
                  }
                }
                return label;
              }
            },
          }
        },
        maintainAspectRatio: false,
      },
      plugins: [
        ChartDataLabels,
        this.pluginLabeled
      ]
    });

  }
  

  pluginLabeled = {
    id: 'pluginLabeled',
    afterDraw: (chart: any) => {
      var ctx = chart.ctx;
      var xAxis = chart.scales['x'];
      var yAxis = chart.scales['y'];
      ctx.save();
      ctx.textAlign = 'center';
      ctx.font = '12px Arial';
      chart.data.labels.forEach((l: any, i: any) => {
        var value = chart.data.datasets[0].data[i];
        var x = yAxis.getPixelForValue(l);        
        ctx.fillStyle = value == 60 ? 'red' : 'blue';
        ctx.fillText(value, x, xAxis.bottom + 17);
      });
      ctx.restore();
    },
  };


  private drawCitizenPyramidChart(selectedGender: string, populationData: PopulationData[]): void {

    var elementExists = document.getElementById('pyramidChart');
    if(!elementExists)
      return ;
    const labels = populationData.map(d => d.ageRange);
    const maleData = populationData.map(d => d.male);
    const femaleData = populationData.map(d => d.female);

    // Definir el tipo para datasets
    let datasets: ChartDataset<'bar', number[]>[] = [];
    const colores: string[] = [
      'rgba(255,255,255, 0.8)',
      'rgba(255,255,255, 0.5)',
      'rgba(255,255,255, 0.4)',
      'rgba(255,255,255, 0.3)',
      'rgba(255,255,255, 0.2)',
      'rgba(255,255,255, 0.8)',
      'rgba(255,255,255, 0.4)',
      'rgba(255,255,255, 0.3)',
    ]

    if (selectedGender === '0') {
      datasets = [
        {
          label: 'Hombres',
          data: maleData.map(d => -d),
          backgroundColor: colores,
          borderRadius: 5,
          datalabels: {
            align: 'start',
            anchor: 'start'
          }
        },
        {
          label: 'Mujeres',
          data: femaleData,
          backgroundColor: colores,
          borderRadius: 5
        }
      ];
    } else if (selectedGender === '1') {
      datasets = [
        {
          label: 'Hombres',
          data: maleData.map(d => -d), // Convertir los valores a negativos
          backgroundColor: colores,
          borderRadius: 4
        }
      ];
    } else if (selectedGender === '2') {
      datasets = [
        {
          label: 'Mujeres',
          data: femaleData, // Mantener los valores positivos
          backgroundColor: colores,
          borderRadius: 4
        }
      ];
    }

    if (this.pyramidChart) {
      this.pyramidChart.destroy();
    }

    this.pyramidChart = new Chart('pyramidChart', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        indexAxis: 'y', // Cambiar a gráfico de barras horizontales
        scales: {
          x: {
            display: true,
            beginAtZero: true,
            grid: {
              display: true,
              lineWidth: ({ tick }) => tick.value == 0 ? 1 : 0,
              color: "#FFF"
            },
            stacked: true, // Asegúrate de que las barras estén apiladas si es necesario
            ticks: {
              display: false,
              color: '#FFF', // Color de los textos en el eje y
              font: {
                size: 14, // Tamaño del texto
                weight: 'bold' // Negrita en los textos
              },
              callback: function (value: number | string) {
                return typeof value === 'number' ? Math.abs(value) : value;
              }
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              color: '#FFF', // Color de los textos en el eje y
              font: {
                size: 10, // Tamaño del texto
                weight: 'normal', // Negrita en los textos
              }
            },
            stacked: true, // Asegúrate de que las barras estén apiladas si es necesario
            position: 'left' // Para que las barras estén en el lado izquierdo y derecho
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#FFF', // Color del texto en la leyenda
              font: {
                size: 14, // Tamaño del texto en la leyenda
                weight: 'bold' // Negrita en la leyenda
              }
            }
          },
          datalabels: {
            color: '#000',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 10
                }
              }
            },
            align: 'end',
            anchor: 'end',
            offset: -25,
            formatter: function (value, context) {
              //return Math.abs(value);
              return Math.abs(Math.trunc(value / 1000)) + 'K';
            }
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let thisval = context.dataset.data[context.dataIndex];
                let label = thisval?.toString();
                if (typeof thisval == "number") {
                  let value = Intl.NumberFormat('es-MX', { style: 'decimal' }).format(thisval ? Math.abs(thisval) : 0,);
                  switch (context.label) {
                    default:
                      label = context.dataset.label + ": " + value;
                      break;
                  }
                }
                return label;
              }
            },
          }
        },
        maintainAspectRatio: false,
      },
      plugins: [
        ChartDataLabels
      ]
    });


  }

  private updatePyramidChart(selectedCombo: string, decisionbool: string, valueSelected: string, populationData: PopulationData[]): void {

    console.log("Datos de población para la gráfica: ", populationData);
    this.drawCitizenPyramidChart(valueSelected, populationData);

  }

  private drawEmploymentChart(selectedGender: string, employmentData: PopulationData[]): void {
    // Filtrar los datos basados en el parámetro selectedGender
    const labels = employmentData.map(d => d.ageRange);
    const maleData = employmentData.map(d => d.male);
    const femaleData = employmentData.map(d => d.female);


    // Definir el tipo para datasets
    let datasets: ChartDataset<'bar', number[]>[] = [];
    const colores: string[] = [
      'rgba(255,255,255, 0.8)',
      'rgba(255,255,255, 0.5)',
      'rgba(255,255,255, 0.4)',
      'rgba(255,255,255, 0.3)',
      'rgba(255,255,255, 0.2)',
      'rgba(255,255,255, 0.8)',
      'rgba(255,255,255, 0.4)',
      'rgba(255,255,255, 0.3)',
    ]

    if (selectedGender === 'Todos') {
      datasets = [
        {
          label: 'Hombres',
          data: maleData.map(d => -d), // Convertir los valores a negativos para las barras hacia la izquierda
          backgroundColor: colores,
          //borderColor: 'rgba(54, 162, 235, 1)',
          //borderWidth: 2,
          borderRadius: 5,
          datalabels: {
            align: 'start',
            anchor: 'start'
          }
        },
        {
          label: 'Mujeres',
          data: femaleData, // Mantener los valores positivos para las barras hacia la derecha
          backgroundColor: colores,
          //borderColor: 'rgba(255, 99, 132, 1)',
          //borderWidth: 2,
          borderRadius: 5
        }
      ];
    } else if (selectedGender === 'Hombres') {
      datasets = [
        {
          label: 'Hombres',
          data: maleData.map(d => -d), // Convertir los valores a negativos
          backgroundColor: colores,
          //borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 2,
          borderRadius: 10
        }
      ];
    } else if (selectedGender === 'Mujeres') {
      datasets = [
        {
          label: 'Mujeres',
          data: femaleData, // Mantener los valores positivos
          backgroundColor: colores,
          //borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 2,
          borderRadius: 10
        }
      ];
    }

    if (this.employmentChart) {
      this.employmentChart.destroy();
    }



    this.employmentChart = new Chart('employmentChart', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets
      },
      options: {
        indexAxis: 'y', // Cambiar a gráfico de barras horizontales
        scales: {
          x: {
            display: true,
            beginAtZero: true,
            grid: {
              display: true,
              lineWidth: ({ tick }) => tick.value == 0 ? 1 : 0,
              color: "#FFF"
            },
            stacked: true, // Asegúrate de que las barras estén apiladas si es necesario
            ticks: {
              display: false,
              color: '#FFF', // Color de los textos en el eje y
              font: {
                size: 14, // Tamaño del texto
                weight: 'bold' // Negrita en los textos
              },
              callback: function (value: number | string) {
                return typeof value === 'number' ? Math.abs(value) : value;
              }
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              display: false
            },
            ticks: {
              color: '#FFF', // Color de los textos en el eje y
              font: {
                size: 10, // Tamaño del texto
                weight: 'normal', // Negrita en los textos
              }
            },
            stacked: true, // Asegúrate de que las barras estén apiladas si es necesario
            position: 'left' // Para que las barras estén en el lado izquierdo y derecho
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#FFF', // Color del texto en la leyenda
              font: {
                size: 14, // Tamaño del texto en la leyenda
                weight: 'bold' // Negrita en la leyenda
              }
            }
          },
          datalabels: {
            color: '#000',
            labels: {
              title: {
                font: {
                  weight: 'bold',
                  size: 10
                }
              }
            },
            align: 'end',
            anchor: 'end',
            offset: -25,
            formatter: function (value, context) {
              //return Math.abs(value);
              return Math.abs(Math.trunc(value / 1000)) + 'K';
            }
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let thisval = context.dataset.data[context.dataIndex];
                let label = thisval?.toString();
                if (typeof thisval == "number") {
                  let value = Intl.NumberFormat('es-MX', { style: 'decimal' }).format(thisval ? Math.abs(thisval) : 0,);
                  switch (context.label) {
                    default:
                      label = context.dataset.label + ": " + value;
                      break;
                  }
                }
                return label;
              }
            },
          }
        },
        maintainAspectRatio: false,
      },
      plugins: [
        ChartDataLabels
      ]
    });

  }

  private drawProgressCharts(title: string, now: number, max: number, color: string = 'rgb(20,163,77)', impulso: boolean = false) {

    let nowString = Intl.NumberFormat('es-MX', { style: 'decimal', maximumFractionDigits: 0 }).format(now,)
    let moneda = impulso ? '(MXN)' : '';
    let width = (now / max) * 100;
    let html = `
                <div class="container">
                    <div class="text-left">
                        <b>` + title + `</b>
                    </div>
                    <span class="h2">` + nowString + `</span>` + moneda + `
                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="` + now + `"
                        aria-valuemin="0" aria-valuemax="` + max + `" style="--bs-progress-bar-bg:` + color + ` !important;">
                        <div class="progress-bar" style="width: ` + width.toString() + `%"></div>
                    </div>
                </div><br>`;


    return html;
  }

}
