
<div class="container-fluid">
  <div class="row mb-4">
    <app-dashboard (mapAction)="mapAction = $event"></app-dashboard>
  </div>
  <hr>
  <div class="row mb-4" id="map-container"
    style="background-image: url('../assets/mapa_azul_background.jpg');background-size: cover; min-height: 700px; position:relative;">
    <div class="col-md-5">
      <div class="row">
        <app-info-map [employeeData]="selectedEmployeeData" [municipalityData]="selectedMunicipalityData"  [mapAction]="mapAction"></app-info-map>
      </div>
    </div>
    <div class="col-md-7">
      <div class="row">
        <app-mapa (employeSelected)="selectedEmployeeData = $event" (municipalitySelected)="selectedMunicipalityData = $event" [mapAction]="mapAction"></app-mapa>
      </div>
    </div>
  </div>
</div>
