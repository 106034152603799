<div class="container">
  <a href="/" class="d-flex justify-content-center">
    <img src="../../assets/sedeco_logo.png" alt="SEDECO">
  </a>

</div>
<hr class="border border-dark border-2 opacity-75 m-0">
<nav class="navbar navbar-expand-lg">
  <div class="container">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
      aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed">
      <ul class="navbar-nav">
  
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" (mouseover)=clickHover(navSecre.id) #navSecre href="#" 
          role="button" id="navSecre" data-bs-toggle="dropdown" aria-expanded="false">
            Secretaría
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Secretaría 1</a></li>
            <li><a class="dropdown-item" href="#">secretaría 2</a></li>
          </ul>
        </li>
  
        <li class="nav-item">
          <a class="nav-link" href="#">Inversión</a>
        </li>      
  
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" (mouseover)=clickHover(navPyMES.id) #navPyMES
          href="#" role="button" id="navPyMES" data-bs-toggle="dropdown" aria-expanded="false">
            PyMES
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">PyMES 1</a></li>
            <li><a class="dropdown-item" href="#">PyMES 2</a></li>
          </ul>
        </li>
  
        <li class="nav-item">
          <a class="nav-link" href="#">Minería y Energía</a>
        </li>      
  
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" (mouseover)=clickHover(Regional.id) #Regional
          href="#" role="button" id="Regional" data-bs-toggle="dropdown" aria-expanded="false">
            Regional
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" href="#">Regional 1</a></li>
            <li><a class="dropdown-item" href="#">Regional 2</a></li>
          </ul>
        </li>
  
        <li class="nav-item">
          <a class="nav-link" href="#">Transparencia</a>
        </li>
  
        <li class="nav-item">
          <a class="nav-link" href="#">Mapa Interactivo</a>
        </li>
  
        <li class="nav-item">
          <a class="nav-link" href="#">Boletines</a>
        </li>
      </ul>
    </div>
    <div class="mx-3">
      <a href="Tel: 123-456-7890">
        <button class="btn btn-outline-light">
          <img src="../../assets/callcenter.png" alt="">
          +15076091087
        </button>
      </a>
    </div>
  </div>

</nav>