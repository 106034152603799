<div class="row">
    <div class="col-xl-4 mh-100 d-inline-block">
        <app-card-with-tabs [data]=mineriaCardDS></app-card-with-tabs>
    </div>
    <div class="col-xl-4 mh-100 d-inline-block">
        <app-card-stack [ds]=mineriaProdDS></app-card-stack>
    </div>
    <div class="col-xl-4 mh-100 d-inline-block">
        <app-card-stack [ds]=mineriaEmplDS></app-card-stack>
    </div>
</div>

<div class="m-4"></div>

<div class="row">
    <div class="col-xl-4">
        <app-card-stack [ds]=mineriaInveDS></app-card-stack>
    </div>
    <div class="col-xl-4">
        <app-card-stack [ds]=mineriaRegaDS></app-card-stack>
    </div>
    <div class="col-xl-4">
        <app-card-stack [ds]=mineriaInfrDS></app-card-stack>
    </div>
</div>

<div class="m-4"></div>

<div class="row">
    <div class="col-xl-6">
        <app-card-with-tabs [data]=mineriaImpaDS></app-card-with-tabs>
    </div>
    <div class="col-xl-6">
        <app-card-stack [ds]=mineriaExpoDS></app-card-stack>
    </div>
</div>


<div class="accordion">
    <div class=" my-5">
        <h2 class="border-bottom">
            <button class="accordion-button collapsed accordion-h" type="button" data-bs-toggle="collapse"
                data-bs-target="#accEmpresasMineras" aria-expanded="false" aria-controls="accEmpresasMineras">
                <b>Conoce las empresas que hacen posible el Desarrollo del Sector Minero</b>
            </button>
        </h2>
        <div id="accEmpresasMineras" class="accordion-collapse collapse">
            <div class="accordion-body">
                <div class="container">
                    @for (emp of empresasMiner; track $index) {
                    <div class="border-bottom m-3">
                        <div class="row  mb-3">
                            <div class="col-sm-1">
                                <img src={{emp.icon}} class="rounded-circle">
                            </div>
                            <div class="col-sm-9">{{emp.name}}</div>
                            <div class="col-sm-2"><button type="button" class="btn btn-primary m-0 p-1"
                                (click)="openModal($index)">
                                <small>Detalle Empresa</small>
                                    </button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="mdlMineria" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">{{empresasMiner[estaEmpresa].name}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="row px-3">
                    <div class="col-sm-6 my-2">
                        Producción
                    </div>
                    <div class="col-sm-6 text-end fw-bold">
                        {{empresasMiner[estaEmpresa].produccion | currency }}<span class="text-danger">*</span>
                    </div>
                    <div class="col-sm-6 my-2">
                        Concesiones
                    </div>
                    <div class="col-sm-6 text-end fw-bold">
                        {{empresasMiner[estaEmpresa].concesiones}}
                    </div>
                    <div class="col-sm-6 my-2">
                        Empleados Directos
                    </div>
                    <div class="col-sm-6 text-end fw-bold">
                        {{empresasMiner[estaEmpresa].emplDirectos}}
                    </div>
                    <div class="col-sm-6 my-2">
                        Empleados Indirectos
                    </div>
                    <div class="col-sm-6 text-end fw-bold">
                        {{empresasMiner[estaEmpresa].emplIndirectos}}
                    </div>
                    <div class="col-sm-6 my-2">
                        Contribución
                    </div>
                    <div class="col-sm-6 text-end fw-bold">
                        $ {{empresasMiner[estaEmpresa].contibucion | currency}} MDP<span class="text-danger">*</span>
                    </div>
                </div>
                <div class="text-center smaller">
                    <span class="text-danger">*</span>Información preliminar a corte de Junio 2024.<br>
                    Fuente CIES con datos de INEGI y la Secretaría de Economía Federal.<br>
                    Los datos de PIB (Producto Interno Bruto) y Exportaciones son estimados.<br>
                    Nomenglatura: MDP Millones de Pesos. MDD Millones de Dolares<br>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>

<button type="button" class="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#mdlMineria" id="btnModal">
    Lorem Ipsum Dolor
  </button>
  