import { Component, EventEmitter, Output} from '@angular/core';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  @Output() mapAction = new EventEmitter<string>();
  isMapPoliticoActive = false;
  
  onSelectChangeSectores(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.setMapActionSectores(selectedValue);
  }

  setMapActionSectores(action: string): void { 

    if(action === ''){
      action = '';
    }
    this.mapAction.emit(action);
  }

  onSelectChangeCiudadanos(event: Event) {

    const selectedValue = (event.target as HTMLSelectElement).value;
    this.setMapActionCiudadanos(selectedValue);

  }

  setMapActionCiudadanos(action: string): void {  
    
    if(action === ''){
      action = '';
    }
    this.mapAction.emit(action);
  }

  setMapActionPolitico(): void {

    this.isMapPoliticoActive = !this.isMapPoliticoActive;
    // Emite la acción en base al estado
    if (this.isMapPoliticoActive) {
      this.mapAction.emit('pol-active-1');
    } else {
      this.mapAction.emit('pol-inactive-null');
    }
  }
}