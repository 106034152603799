<div class="container">
    <h5>{{ dataCard.title }}</h5>
    <p> {{ dataCard.period }}</p>
    <div class="card">
        <div class="card-body mb-2">
            <h6>{{ dataCard.subtitle }}</h6>
            <div>
                @for (item of dataCard.data; track $index) {
                <div class="content">
                    <span class="value">{{item.value}}</span>
                    <div class="progress" role="progressbar" aria-label="Example 1px high" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100" style="height: 8px">
                        <div class="progress-bar bg-succes"
                            [style]="{width: item.percentage, background: item.color}"></div>
                    </div>
                    <span class="description">{{item.descripcion}}</span>
                </div>
                }
            </div>
        </div>
    </div>
</div>