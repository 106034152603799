import { Component, Input, SimpleChanges } from '@angular/core';
import { dataCardTab } from '../models/cardWithTabs.model';
import {
  DataCardWithGraphTab,
  DataCardWithGraphTabs,
} from '../models/cardTabsWithGraph.model';
import { lineDataset, lineDS } from '../models/graphs.model';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-card-with-tabs-and-graph',
  templateUrl: './card-with-tabs-and-graph.component.html',
  styleUrl: './card-with-tabs-and-graph.component.css',
})
export class CardWithTabsAndGraphComponent {
  @Input({ required: true }) data!: string;

  dataCard!: DataCardWithGraphTabs;
  tabSelect: number = 0;
  dataSelect!: DataCardWithGraphTab | undefined;
  dataGrafica!: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] !== undefined) {
      this.dataCard = JSON.parse(changes['data'].currentValue);
      this.selectContentCard(0);
    }
  }

  tabHandle(i: number) {
    this.tabSelect = i;
    this.selectContentCard(i);
  }

  selectContentCard(i: number) {
    const data = this.dataCard.data;
    this.dataSelect = data
      ? data.find((el: any, index: number) => index === i)
      : undefined;

    this.dataGrafica = JSON.stringify(this.dataSelect?.dataGraph);   

    const dataGraficaUpdate : lineDataset = JSON.parse(this.dataGrafica);

    this.updateChart(dataGraficaUpdate);
  }

  updateChart(data: lineDataset) { 
    if (data.cid) {
      let chart = Chart.getChart(data.cid);
      var datasets: lineDS[] = [];
      data.dataset.forEach(el => {
        datasets.push(el)
      })

      // Cambiar la escala
      var minI = 9999999;
      var maxI = 0;
      datasets.forEach((sy) => {
        minI =
          minI > Math.min.apply(null, sy.data)
            ? Math.min.apply(null, sy.data)
            : minI;
        maxI =
          maxI < Math.max.apply(null, sy.data)
            ? Math.max.apply(null, sy.data)
            : maxI;
      });
      minI = minI < 1 ? minI : minI - 5;
      maxI = maxI + 5;

      if (chart) {
        if (chart.config.options) {
          if (chart.config.options.scales) {
            if (chart.config.options.scales['y']) {
              chart.config.options.scales['y'].max = maxI;
              chart.config.options.scales['y'].min = minI;
            }
          }
        }
        chart.data.datasets = datasets;
        chart.data.labels = data.dataLabels;
        chart.update();
      }
    }
  }
}
