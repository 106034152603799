[
    {
        "rubro" :"Comercio al por mayor",
        "monto" : 185615
    },
    {
        "rubro" :"Manufacturas",
        "monto" : 543236
    },
    {
        "rubro" :"Hoteles y restaurantes",
        "monto" : 608280
    },
    {
        "rubro" :"Otros servicios Excepto Gobierno",
        "monto" : 669446
    },
    {
        "rubro" :"Comercio al por menor",
        "monto" : 2041735
    }   
]