<div class="container">
    <h5>{{dataCard.title}}
        
        <button type="button" class="btn btn-white p-0" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true"
            title="{{dataCard.titleTooltip}}" *ngIf="dataCard.titleTooltip">
            <i class="bi bi-info-circle"></i>
        </button>
    </h5>
    <p>{{dataCard.period}}</p>
    <div class="card">
        <div class="card-body">
            <ul class="nav justify-content-center">
                @for (item of dataCard.data; track $index) {
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': tabSelect == $index}" (click)="tabHandle($index)">{{
                        item.nombreTab }}</a>
                    @if (tabSelect === $index) {
                    <hr>
                    }
                </li>
                }
            </ul>
            <div class="row">
                <div class="col-sm-12">
                    <div [attr.class]="arcDS ? 'd-flex justify-content-center': 'content'">
                        <graph-bar [ds]="barDS" *ngIf="barDS" ></graph-bar>
                        <graph-circ [ds]="arcDS" *ngIf="arcDS" ></graph-circ>
                        <div class="dato-content" *ngIf="!arcDS">
                            <h2>{{dataSelect?.valor}}</h2>
                            <p>{{dataSelect?.descripcion}}</p>
                        </div>
                        <div class="icon-content" *ngIf="!arcDS">
                            <img src="{{dataSelect?.iconUrl}}" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>