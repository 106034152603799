[
    {
        "municipio": "Canatlán",
        "monto": 2100000,
        "sector": "automotriz"
    },
    {
        "municipio": "Durango",
        "monto": 18400000,
        "sector": "automotriz, comercio e infraestructura"
    },
    {
        "municipio": "Centro Logistico e Industrial de durango",
        "monto": 88385364,
        "sector": "automotriz e industrial"
    },
    {
        "municipio": "Gómez Palacio",
        "monto": 350587368,
        "sector": "inmobilario, automotriz, textil, industrial y comercio"
    },
    {
        "municipio": "Lerdo",
        "monto": 1205000000,
        "sector": "abarrotes, textil e industrial"
    },
    {
        "municipio": "San Juan del Río",
        "monto": 421052000,
        "sector": "automotriz"
    },
    {
        "municipio": "Tlahualilo",
        "monto": 5000000,
        "sector": "Automotriz"
    }
]