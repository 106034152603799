{
	"Actividad": "Industrias manufactureras",
	"Datos":
	[
		{
			"Año": 2003,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 92.823898354644
				},
				{
					"Mes": "Febrero",
					"Valor": 109.292700743299
				},
				{
					"Mes": "Marzo",
					"Valor": 115.594056941618
				},
				{
					"Mes": "Abril",
					"Valor": 102.754366135952
				},
				{
					"Mes": "Mayo",
					"Valor": 88.621527157312
				},
				{
					"Mes": "Junio",
					"Valor": 103.49574463556
				},
				{
					"Mes": "Julio",
					"Valor": 86.499864398971
				},
				{
					"Mes": "Agosto",
					"Valor": 91.270323571933
				},
				{
					"Mes": "Septiembre",
					"Valor": 84.805280124948
				},
				{
					"Mes": "Octubre",
					"Valor": 89.909967704932
				},
				{
					"Mes": "Noviembre",
					"Valor": 88.602795181363
				},
				{
					"Mes": "Diciembre",
					"Valor": 89.073822990797
				},
				{
					"Mes": "Anual",
					"Valor": 95.2286956617774
				}
			]
		},
		{
			"Año": 2004,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 78.380185944244
				},
				{
					"Mes": "Febrero",
					"Valor": 89.61123312672
				},
				{
					"Mes": "Marzo",
					"Valor": 98.951375912409
				},
				{
					"Mes": "Abril",
					"Valor": 79.363779082201
				},
				{
					"Mes": "Mayo",
					"Valor": 84.208298542964
				},
				{
					"Mes": "Junio",
					"Valor": 85.327136085801
				},
				{
					"Mes": "Julio",
					"Valor": 86.529971632546
				},
				{
					"Mes": "Agosto",
					"Valor": 84.507040618216
				},
				{
					"Mes": "Septiembre",
					"Valor": 74.254356643486
				},
				{
					"Mes": "Octubre",
					"Valor": 78.062000850124
				},
				{
					"Mes": "Noviembre",
					"Valor": 77.012239261436
				},
				{
					"Mes": "Diciembre",
					"Valor": 76.339275384127
				},
				{
					"Mes": "Anual",
					"Valor": 82.7122410903562
				}
			]
		},
		{
			"Año": 2005,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 71.287208759235
				},
				{
					"Mes": "Febrero",
					"Valor": 75.823355091223
				},
				{
					"Mes": "Marzo",
					"Valor": 84.712989043301
				},
				{
					"Mes": "Abril",
					"Valor": 78.084246766649
				},
				{
					"Mes": "Mayo",
					"Valor": 78.833942367886
				},
				{
					"Mes": "Junio",
					"Valor": 83.353508255526
				},
				{
					"Mes": "Julio",
					"Valor": 74.116243916788
				},
				{
					"Mes": "Agosto",
					"Valor": 75.536102871646
				},
				{
					"Mes": "Septiembre",
					"Valor": 68.518839500467
				},
				{
					"Mes": "Octubre",
					"Valor": 75.322654019394
				},
				{
					"Mes": "Noviembre",
					"Valor": 75.996394122124
				},
				{
					"Mes": "Diciembre",
					"Valor": 77.671563189993
				},
				{
					"Mes": "Anual",
					"Valor": 76.6047539920193
				}
			]
		},
		{
			"Año": 2006,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 78.797352770774
				},
				{
					"Mes": "Febrero",
					"Valor": 90.49385688857
				},
				{
					"Mes": "Marzo",
					"Valor": 92.117086646936
				},
				{
					"Mes": "Abril",
					"Valor": 74.935308402228
				},
				{
					"Mes": "Mayo",
					"Valor": 71.281939861298
				},
				{
					"Mes": "Junio",
					"Valor": 82.58574208428
				},
				{
					"Mes": "Julio",
					"Valor": 74.884770409083
				},
				{
					"Mes": "Agosto",
					"Valor": 87.544373602504
				},
				{
					"Mes": "Septiembre",
					"Valor": 75.043658772684
				},
				{
					"Mes": "Octubre",
					"Valor": 88.807426216311
				},
				{
					"Mes": "Noviembre",
					"Valor": 89.793160763675
				},
				{
					"Mes": "Diciembre",
					"Valor": 84.569333050193
				},
				{
					"Mes": "Anual",
					"Valor": 82.5711674557113
				}
			]
		},
		{
			"Año": 2007,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 98.861327650237
				},
				{
					"Mes": "Febrero",
					"Valor": 95.306449553775
				},
				{
					"Mes": "Marzo",
					"Valor": 100.485975988652
				},
				{
					"Mes": "Abril",
					"Valor": 83.089247568865
				},
				{
					"Mes": "Mayo",
					"Valor": 82.912169379998
				},
				{
					"Mes": "Junio",
					"Valor": 89.61465473682
				},
				{
					"Mes": "Julio",
					"Valor": 78.481638204246
				},
				{
					"Mes": "Agosto",
					"Valor": 79.49177837504
				},
				{
					"Mes": "Septiembre",
					"Valor": 79.499928298919
				},
				{
					"Mes": "Octubre",
					"Valor": 94.299163447663
				},
				{
					"Mes": "Noviembre",
					"Valor": 89.251688778406
				},
				{
					"Mes": "Diciembre",
					"Valor": 77.50093385916
				},
				{
					"Mes": "Anual",
					"Valor": 87.3995796534817
				}
			]
		},
		{
			"Año": 2008,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 85.174425330792
				},
				{
					"Mes": "Febrero",
					"Valor": 89.509066715438
				},
				{
					"Mes": "Marzo",
					"Valor": 87.924938689626
				},
				{
					"Mes": "Abril",
					"Valor": 91.742139563491
				},
				{
					"Mes": "Mayo",
					"Valor": 93.113196113787
				},
				{
					"Mes": "Junio",
					"Valor": 92.934442195364
				},
				{
					"Mes": "Julio",
					"Valor": 90.916366546195
				},
				{
					"Mes": "Agosto",
					"Valor": 100.699267150409
				},
				{
					"Mes": "Septiembre",
					"Valor": 69.874020338702
				},
				{
					"Mes": "Octubre",
					"Valor": 76.798996298257
				},
				{
					"Mes": "Noviembre",
					"Valor": 74.283932160018
				},
				{
					"Mes": "Diciembre",
					"Valor": 62.367447817193
				},
				{
					"Mes": "Anual",
					"Valor": 84.6115199099393
				}
			]
		},
		{
			"Año": 2009,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 75.168758276013
				},
				{
					"Mes": "Febrero",
					"Valor": 67.531997924369
				},
				{
					"Mes": "Marzo",
					"Valor": 95.604122804922
				},
				{
					"Mes": "Abril",
					"Valor": 83.201910681509
				},
				{
					"Mes": "Mayo",
					"Valor": 86.535525297845
				},
				{
					"Mes": "Junio",
					"Valor": 99.303783540907
				},
				{
					"Mes": "Julio",
					"Valor": 93.407818346036
				},
				{
					"Mes": "Agosto",
					"Valor": 91.855079766925
				},
				{
					"Mes": "Septiembre",
					"Valor": 76.540069969551
				},
				{
					"Mes": "Octubre",
					"Valor": 68.223681641324
				},
				{
					"Mes": "Noviembre",
					"Valor": 70.072429755009
				},
				{
					"Mes": "Diciembre",
					"Valor": 87.963971088996
				},
				{
					"Mes": "Anual",
					"Valor": 82.9507624244505
				}
			]
		},
		{
			"Año": 2010,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 64.555356808764
				},
				{
					"Mes": "Febrero",
					"Valor": 72.450026439278
				},
				{
					"Mes": "Marzo",
					"Valor": 115.338130854728
				},
				{
					"Mes": "Abril",
					"Valor": 111.077389746489
				},
				{
					"Mes": "Mayo",
					"Valor": 88.583025556465
				},
				{
					"Mes": "Junio",
					"Valor": 108.099169125383
				},
				{
					"Mes": "Julio",
					"Valor": 99.672352472218
				},
				{
					"Mes": "Agosto",
					"Valor": 89.528200530184
				},
				{
					"Mes": "Septiembre",
					"Valor": 64.437943310283
				},
				{
					"Mes": "Octubre",
					"Valor": 83.358838404949
				},
				{
					"Mes": "Noviembre",
					"Valor": 87.141811981065
				},
				{
					"Mes": "Diciembre",
					"Valor": 85.246917937815
				},
				{
					"Mes": "Anual",
					"Valor": 89.1240969306351
				}
			]
		},
		{
			"Año": 2011,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 74.213504105545
				},
				{
					"Mes": "Febrero",
					"Valor": 82.666042395998
				},
				{
					"Mes": "Marzo",
					"Valor": 109.877542648596
				},
				{
					"Mes": "Abril",
					"Valor": 93.713274957313
				},
				{
					"Mes": "Mayo",
					"Valor": 108.236748015786
				},
				{
					"Mes": "Junio",
					"Valor": 110.195898980037
				},
				{
					"Mes": "Julio",
					"Valor": 95.37083392484
				},
				{
					"Mes": "Agosto",
					"Valor": 101.432192396263
				},
				{
					"Mes": "Septiembre",
					"Valor": 96.988811132319
				},
				{
					"Mes": "Octubre",
					"Valor": 85.156165394804
				},
				{
					"Mes": "Noviembre",
					"Valor": 87.748317931647
				},
				{
					"Mes": "Diciembre",
					"Valor": 92.243269704733
				},
				{
					"Mes": "Anual",
					"Valor": 94.8202167989901
				}
			]
		},
		{
			"Año": 2012,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 82.527008175896
				},
				{
					"Mes": "Febrero",
					"Valor": 83.854204753564
				},
				{
					"Mes": "Marzo",
					"Valor": 116.912248381962
				},
				{
					"Mes": "Abril",
					"Valor": 103.085794087342
				},
				{
					"Mes": "Mayo",
					"Valor": 145.512876664688
				},
				{
					"Mes": "Junio",
					"Valor": 142.156341589061
				},
				{
					"Mes": "Julio",
					"Valor": 108.022575019528
				},
				{
					"Mes": "Agosto",
					"Valor": 125.971885217643
				},
				{
					"Mes": "Septiembre",
					"Valor": 111.53297111394
				},
				{
					"Mes": "Octubre",
					"Valor": 149.388318600004
				},
				{
					"Mes": "Noviembre",
					"Valor": 102.684699243029
				},
				{
					"Mes": "Diciembre",
					"Valor": 81.934563027566
				},
				{
					"Mes": "Anual",
					"Valor": 112.798623822852
				}
			]
		},
		{
			"Año": 2013,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 98.59928344855
				},
				{
					"Mes": "Febrero",
					"Valor": 98.95599450442
				},
				{
					"Mes": "Marzo",
					"Valor": 103.437671125231
				},
				{
					"Mes": "Abril",
					"Valor": 111.560881117096
				},
				{
					"Mes": "Mayo",
					"Valor": 105.034546544427
				},
				{
					"Mes": "Junio",
					"Valor": 114.594662560125
				},
				{
					"Mes": "Julio",
					"Valor": 104.380294355534
				},
				{
					"Mes": "Agosto",
					"Valor": 105.135012488236
				},
				{
					"Mes": "Septiembre",
					"Valor": 89.283031126037
				},
				{
					"Mes": "Octubre",
					"Valor": 108.245272029617
				},
				{
					"Mes": "Noviembre",
					"Valor": 106.333206215511
				},
				{
					"Mes": "Diciembre",
					"Valor": 77.859289793198
				},
				{
					"Mes": "Anual",
					"Valor": 101.951595442332
				}
			]
		},
		{
			"Año": 2014,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 90.705214658972
				},
				{
					"Mes": "Febrero",
					"Valor": 93.207262048869
				},
				{
					"Mes": "Marzo",
					"Valor": 107.389906826257
				},
				{
					"Mes": "Abril",
					"Valor": 104.547711318317
				},
				{
					"Mes": "Mayo",
					"Valor": 110.644403687582
				},
				{
					"Mes": "Junio",
					"Valor": 116.1445732404
				},
				{
					"Mes": "Julio",
					"Valor": 105.785772533859
				},
				{
					"Mes": "Agosto",
					"Valor": 97.989059701519
				},
				{
					"Mes": "Septiembre",
					"Valor": 85.015051599862
				},
				{
					"Mes": "Octubre",
					"Valor": 106.032913663764
				},
				{
					"Mes": "Noviembre",
					"Valor": 96.655284021519
				},
				{
					"Mes": "Diciembre",
					"Valor": 82.774230021275
				},
				{
					"Mes": "Anual",
					"Valor": 99.7409486101829
				}
			]
		},
		{
			"Año": 2015,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 101.396553323655
				},
				{
					"Mes": "Febrero",
					"Valor": 92.110417578768
				},
				{
					"Mes": "Marzo",
					"Valor": 119.030747431179
				},
				{
					"Mes": "Abril",
					"Valor": 119.186520267144
				},
				{
					"Mes": "Mayo",
					"Valor": 108.9121924748
				},
				{
					"Mes": "Junio",
					"Valor": 116.647139556932
				},
				{
					"Mes": "Julio",
					"Valor": 98.248719150582
				},
				{
					"Mes": "Agosto",
					"Valor": 104.812921040926
				},
				{
					"Mes": "Septiembre",
					"Valor": 86.795371716746
				},
				{
					"Mes": "Octubre",
					"Valor": 101.162679372083
				},
				{
					"Mes": "Noviembre",
					"Valor": 114.603662051056
				},
				{
					"Mes": "Diciembre",
					"Valor": 85.611506744823
				},
				{
					"Mes": "Anual",
					"Valor": 104.043202559058
				}
			]
		},
		{
			"Año": 2016,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 93.970777309402
				},
				{
					"Mes": "Febrero",
					"Valor": 99.540854370711
				},
				{
					"Mes": "Marzo",
					"Valor": 109.207625690421
				},
				{
					"Mes": "Abril",
					"Valor": 112.422764691828
				},
				{
					"Mes": "Mayo",
					"Valor": 112.744416357099
				},
				{
					"Mes": "Junio",
					"Valor": 129.217163743083
				},
				{
					"Mes": "Julio",
					"Valor": 103.588468922441
				},
				{
					"Mes": "Agosto",
					"Valor": 108.967977395794
				},
				{
					"Mes": "Septiembre",
					"Valor": 96.402106782131
				},
				{
					"Mes": "Octubre",
					"Valor": 94.946642004341
				},
				{
					"Mes": "Noviembre",
					"Valor": 114.23966051891
				},
				{
					"Mes": "Diciembre",
					"Valor": 90.535407480813
				},
				{
					"Mes": "Anual",
					"Valor": 105.481988772248
				}
			]
		},
		{
			"Año": 2017,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 94.133782182234
				},
				{
					"Mes": "Febrero",
					"Valor": 104.100014639004
				},
				{
					"Mes": "Marzo",
					"Valor": 126.744650294541
				},
				{
					"Mes": "Abril",
					"Valor": 104.453901381547
				},
				{
					"Mes": "Mayo",
					"Valor": 105.977795214034
				},
				{
					"Mes": "Junio",
					"Valor": 122.278512783763
				},
				{
					"Mes": "Julio",
					"Valor": 113.646159218459
				},
				{
					"Mes": "Agosto",
					"Valor": 115.621734768218
				},
				{
					"Mes": "Septiembre",
					"Valor": 97.974758744409
				},
				{
					"Mes": "Octubre",
					"Valor": 95.661805840271
				},
				{
					"Mes": "Noviembre",
					"Valor": 104.596816015612
				},
				{
					"Mes": "Diciembre",
					"Valor": 84.073303290955
				},
				{
					"Mes": "Anual",
					"Valor": 105.771936197754
				}
			]
		},
		{
			"Año": 2018,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 102.335238614855
				},
				{
					"Mes": "Febrero",
					"Valor": 99.6476101224
				},
				{
					"Mes": "Marzo",
					"Valor": 113.382096780805
				},
				{
					"Mes": "Abril",
					"Valor": 104.24480616003
				},
				{
					"Mes": "Mayo",
					"Valor": 103.034110039122
				},
				{
					"Mes": "Junio",
					"Valor": 113.022763294125
				},
				{
					"Mes": "Julio",
					"Valor": 97.521396854291
				},
				{
					"Mes": "Agosto",
					"Valor": 108.452748688903
				},
				{
					"Mes": "Septiembre",
					"Valor": 95.240445883992
				},
				{
					"Mes": "Octubre",
					"Valor": 90.089539651007
				},
				{
					"Mes": "Noviembre",
					"Valor": 92.154407760508
				},
				{
					"Mes": "Diciembre",
					"Valor": 80.874836149963
				},
				{
					"Mes": "Anual",
					"Valor": 100
				}
			]
		},
		{
			"Año": 2019,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 97.382083597699
				},
				{
					"Mes": "Febrero",
					"Valor": 109.500724935576
				},
				{
					"Mes": "Marzo",
					"Valor": 122.612390399165
				},
				{
					"Mes": "Abril",
					"Valor": 95.450979001933
				},
				{
					"Mes": "Mayo",
					"Valor": 110.749899505379
				},
				{
					"Mes": "Junio",
					"Valor": 122.778622204341
				},
				{
					"Mes": "Julio",
					"Valor": 99.081010184202
				},
				{
					"Mes": "Agosto",
					"Valor": 108.509844313363
				},
				{
					"Mes": "Septiembre",
					"Valor": 90.40304860346
				},
				{
					"Mes": "Octubre",
					"Valor": 93.511932873801
				},
				{
					"Mes": "Noviembre",
					"Valor": 96.191335830249
				},
				{
					"Mes": "Diciembre",
					"Valor": 82.409120708127
				},
				{
					"Mes": "Anual",
					"Valor": 102.381749346441
				}
			]
		},
		{
			"Año": 2020,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 97.045699493958
				},
				{
					"Mes": "Febrero",
					"Valor": 102.182719714018
				},
				{
					"Mes": "Marzo",
					"Valor": 111.470238175616
				},
				{
					"Mes": "Abril",
					"Valor": 99.512142156007
				},
				{
					"Mes": "Mayo",
					"Valor": 94.835400400642
				},
				{
					"Mes": "Junio",
					"Valor": 110.355666271783
				},
				{
					"Mes": "Julio",
					"Valor": 98.049364360224
				},
				{
					"Mes": "Agosto",
					"Valor": 108.993058548335
				},
				{
					"Mes": "Septiembre",
					"Valor": 92.762881690042
				},
				{
					"Mes": "Octubre",
					"Valor": 105.073252440152
				},
				{
					"Mes": "Noviembre",
					"Valor": 103.856052232419
				},
				{
					"Mes": "Diciembre",
					"Valor": 94.644924876715
				},
				{
					"Mes": "Anual",
					"Valor": 101.565116696659
				}
			]
		},
		{
			"Año": 2021,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 93.75583020793
				},
				{
					"Mes": "Febrero",
					"Valor": 98.178129691111
				},
				{
					"Mes": "Marzo",
					"Valor": 122.624278355126
				},
				{
					"Mes": "Abril",
					"Valor": 106.517182525638
				},
				{
					"Mes": "Mayo",
					"Valor": 109.012664988823
				},
				{
					"Mes": "Junio",
					"Valor": 120.234179098323
				},
				{
					"Mes": "Julio",
					"Valor": 113.562303030434
				},
				{
					"Mes": "Agosto",
					"Valor": 125.875369464558
				},
				{
					"Mes": "Septiembre",
					"Valor": 113.794719333038
				},
				{
					"Mes": "Octubre",
					"Valor": 107.496638657938
				},
				{
					"Mes": "Noviembre",
					"Valor": 104.584331952435
				},
				{
					"Mes": "Diciembre",
					"Valor": 95.164111165834
				},
				{
					"Mes": "Anual",
					"Valor": 109.233311539266
				}
			]
		},
		{
			"Año": 2022,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 100.323477701443
				},
				{
					"Mes": "Febrero",
					"Valor": 110.509148159149
				},
				{
					"Mes": "Marzo",
					"Valor": 130.704270190786
				},
				{
					"Mes": "Abril",
					"Valor": 117.54113746966
				},
				{
					"Mes": "Mayo",
					"Valor": 121.274700387214
				},
				{
					"Mes": "Junio",
					"Valor": 138.761603012056
				},
				{
					"Mes": "Julio",
					"Valor": 117.647019205958
				},
				{
					"Mes": "Agosto",
					"Valor": 116.733878777215
				},
				{
					"Mes": "Septiembre",
					"Valor": 93.03932574855
				},
				{
					"Mes": "Octubre",
					"Valor": 95.317047736429
				},
				{
					"Mes": "Noviembre",
					"Valor": 102.880049192663
				},
				{
					"Mes": "Diciembre",
					"Valor": 85.044363453315
				},
				{
					"Mes": "Anual",
					"Valor": 110.814668419536
				}
			]
		},
		{
			"Año": 2023,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 94.357175236454
				},
				{
					"Mes": "Febrero",
					"Valor": 107.174593494886
				},
				{
					"Mes": "Marzo",
					"Valor": 118.527607047879
				},
				{
					"Mes": "Abril",
					"Valor": 102.181948249719
				},
				{
					"Mes": "Mayo",
					"Valor": 93.250598579248
				},
				{
					"Mes": "Junio",
					"Valor": 110.522092609705
				},
				{
					"Mes": "Julio",
					"Valor": 97.600192160215
				},
				{
					"Mes": "Agosto",
					"Valor": 111.07945635269
				},
				{
					"Mes": "Septiembre",
					"Valor": 89.601498381421
				},
				{
					"Mes": "Octubre",
					"Valor": 97.397025419795
				},
				{
					"Mes": "Noviembre",
					"Valor": 96.604292251101
				},
				{
					"Mes": "Diciembre",
					"Valor": 79.478579904321
				},
				{
					"Mes": "Anual",
					"Valor": 99.814588307286
				}
			]
		},
		{
			"Año": 2024,
			"Meses":[
				{
					"Mes": "Enero",
					"Valor": 91.640468817383
				},
				{
					"Mes": "Febrero",
					"Valor": 99.552655632698
				},
				{
					"Mes": "Marzo",
					"Valor": 96.673150714657
				},
				{
					"Mes": "Abril",
					"Valor": 98.516669278681
				},
				{
					"Mes": "Mayo",
					"Valor": 96.840893288081
				}
			]
		}
	]
}