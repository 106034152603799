[
    {
        "2022":[{
            "12":[
                { "Canatlán": 1410},
                { "Canelas": 131},
                { "Cuencamé": 2278},
                { "Durango": 118730},
                { "El Oro": 286},
                { "General Simón Bolívar": 11},
                { "Gómez Palacio": 89766},
                { "Guadalupe Victoria": 2022},
                { "Guanaceví": 1563},
                { "Hidalgo": 14},
                { "Indé": 340},
                { "Lerdo": 12836},
                { "Mapimí": 1256},
                { "Mezquital": 67},
                { "Nazas": 361},
                { "Nombre de Dios": 301},
                { "Nuevo Ideal": 1841},
                { "Ocampo": 66},
                { "Otáez": 563},
                { "Pánuco de Coronado": 440},
                { "Peñón Blanco": 34},
                { "Poanas": 421},
                { "Pueblo Nuevo": 4375},
                { "Rodeo": 443},
                { "San Bernardo": 2},
                { "San Dimas": 2176},
                { "San Juan de Guadalupe": 4},
                { "San Juan del Río": 610},
                { "San Luis del Cordero": 4},
                { "San Pedro del Gallo": 7},
                { "Santa Clara": 23},
                { "Santiago Papasquiaro": 5081},
                { "Súchil": 23},
                { "Tamazula": 223},
                { "Tepehuanes": 668},
                { "Tlahualilo": 4576},
                { "Topia": 591},
                { "Vicente Guerrero": 2137}
            ]
        }]
    },
    {
        "2023":[
            {
                "1":[
                    { "Canatlán": 1588},
                    { "Canelas": 113},
                    { "Cuencamé": 3376},
                    { "Durango": 119007},
                    { "El Oro": 296},
                    { "General Simón Bolívar": 11},
                    { "Gómez Palacio": 90621},
                    { "Guadalupe Victoria": 1932},
                    { "Guanaceví": 1557},
                    { "Hidalgo": 15},
                    { "Indé": 352},
                    { "Lerdo": 12555},
                    { "Mapimí": 1191},
                    { "Mezquital": 65},
                    { "Nazas": 341},
                    { "Nombre de Dios": 335},
                    { "Nuevo Ideal": 1837},
                    { "Ocampo": 67},
                    { "Otáez": 595},
                    { "Pánuco de Coronado": 439},
                    { "Peñón Blanco": 35},
                    { "Poanas": 419},
                    { "Pueblo Nuevo": 4594},
                    { "Rodeo": 456},
                    { "San Bernardo": 2},
                    { "San Dimas": 2131},
                    { "San Juan de Guadalupe": 4},
                    { "San Juan del Río": 683},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 22},
                    { "Santiago Papasquiaro": 5047},
                    { "Súchil": 22},
                    { "Tamazula": 229},
                    { "Tepehuanes": 694},
                    { "Tlahualilo": 4637},
                    { "Topia": 589},
                    { "Vicente Guerrero": 2155}

                ]
            },
            {
                "2":[
                    { "Canatlán": 1649},
                    { "Canelas": 122},
                    { "Cuencamé": 3326},
                    { "Durango": 118488},
                    { "El Oro": 292},
                    { "General Simón Bolívar": 13},
                    { "Gómez Palacio": 91245},
                    { "Guadalupe Victoria": 1899},
                    { "Guanaceví": 1573},
                    { "Hidalgo": 13},
                    { "Indé": 341},
                    { "Lerdo": 12603},
                    { "Mapimí": 1200},
                    { "Mezquital": 64},
                    { "Nazas": 348},
                    { "Nombre de Dios": 2186},
                    { "Nuevo Ideal": 1874},
                    { "Ocampo": 66},
                    { "Otáez": 598},
                    { "Pánuco de Coronado": 426},
                    { "Peñón Blanco": 37},
                    { "Poanas": 439},
                    { "Pueblo Nuevo": 4682},
                    { "Rodeo": 464},
                    { "San Bernardo": 2},
                    { "San Dimas": 2207},
                    { "San Juan de Guadalupe": 4},
                    { "San Juan del Río": 806},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 24},
                    { "Santiago Papasquiaro": 5104},
                    { "Súchil": 22},
                    { "Tamazula": 230},
                    { "Tepehuanes": 700},
                    { "Tlahualilo": 4682},
                    { "Topia": 612},
                    { "Vicente Guerrero": 2155}
                ]
            },
            {
                "3":[
                    { "Canatlán": 1675},
                    { "Canelas": 130},
                    { "Cuencamé": 3363},
                    { "Durango": 118726},
                    { "El Oro": 298},
                    { "General Simón Bolívar": 13},
                    { "Gómez Palacio": 91411},
                    { "Guadalupe Victoria": 1874},
                    { "Guanaceví": 1585},
                    { "Hidalgo": 14},
                    { "Indé": 341},
                    { "Lerdo": 11609},
                    { "Mapimí": 1209},
                    { "Mezquital": 69},
                    { "Nazas": 349},
                    { "Nombre de Dios": 2341},
                    { "Nuevo Ideal": 1854},
                    { "Ocampo": 66},
                    { "Otáez": 604},
                    { "Pánuco de Coronado": 454},
                    { "Peñón Blanco": 23},
                    { "Poanas": 441},
                    { "Pueblo Nuevo": 4749},
                    { "Rodeo": 462},
                    { "San Bernardo": 2},
                    { "San Dimas": 2124},
                    { "San Juan de Guadalupe": 4},
                    { "San Juan del Río": 914},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 24},
                    { "Santiago Papasquiaro": 5219},
                    { "Súchil": 22},
                    { "Tamazula": 223},
                    { "Tepehuanes": 711},
                    { "Tlahualilo": 4689},
                    { "Topia": 628},
                    { "Vicente Guerrero": 2103}
                ]
            },
            {
                "4":[
                    { "Canatlán": 1666},
                    { "Canelas": 135},
                    { "Cuencamé": 3342},
                    { "Durango": 118816},
                    { "El Oro": 296},
                    { "General Simón Bolívar": 13},
                    { "Gómez Palacio": 91582},
                    { "Guadalupe Victoria": 1851},
                    { "Guanaceví": 1607},
                    { "Hidalgo": 17},
                    { "Indé": 356},
                    { "Lerdo": 11624},
                    { "Mapimí": 1223},
                    { "Mezquital": 44},
                    { "Nazas": 352},
                    { "Nombre de Dios": 2387},
                    { "Nuevo Ideal": 1878},
                    { "Ocampo": 71},
                    { "Otáez": 553},
                    { "Pánuco de Coronado": 453},
                    { "Peñón Blanco": 23},
                    { "Poanas": 434},
                    { "Pueblo Nuevo": 4782},
                    { "Rodeo": 460},
                    { "San Bernardo": 2},
                    { "San Dimas": 2170},
                    { "San Juan de Guadalupe": 4},
                    { "San Juan del Río": 921},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 24},
                    { "Santiago Papasquiaro": 5187},
                    { "Súchil": 24},
                    { "Tamazula": 204},
                    { "Tepehuanes": 680},
                    { "Tlahualilo": 4716},
                    { "Topia": 629},
                    { "Vicente Guerrero": 2290}
                ]
            },
            {
                "5":[
                    { "Canatlán": 1525},
                    { "Canelas": 127},
                    { "Cuencamé": 3330},
                    { "Durango": 119027},
                    { "El Oro": 294},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 91915},
                    { "Guadalupe Victoria": 1817},
                    { "Guanaceví": 1632},
                    { "Hidalgo": 17},
                    { "Indé": 361},
                    { "Lerdo": 11639},
                    { "Mapimí": 1229},
                    { "Mezquital": 43},
                    { "Nazas": 354},
                    { "Nombre de Dios": 2344},
                    { "Nuevo Ideal": 1855},
                    { "Ocampo": 65},
                    { "Otáez": 631},
                    { "Pánuco de Coronado": 445},
                    { "Peñón Blanco": 23},
                    { "Poanas": 434},
                    { "Pueblo Nuevo": 4815},
                    { "Rodeo": 461},
                    { "San Bernardo": 2},
                    { "San Dimas": 2244},
                    { "San Juan de Guadalupe": 4},
                    { "San Juan del Río": 904},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 24},
                    { "Santiago Papasquiaro": 5162},
                    { "Súchil": 23},
                    { "Tamazula": 206},
                    { "Tepehuanes": 698},
                    { "Tlahualilo": 4390},
                    { "Topia": 629},
                    { "Vicente Guerrero": 2529}
                ]
            },
            {
                "6":[
                    { "Canatlán": 1554},
                    { "Canelas": 261},
                    { "Cuencamé": 3527},
                    { "Durango": 118686},
                    { "El Oro": 288},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 92726},
                    { "Guadalupe Victoria": 1794},
                    { "Guanaceví": 1644},
                    { "Hidalgo": 16},
                    { "Indé": 374},
                    { "Lerdo": 11643},
                    { "Mapimí": 1215},
                    { "Mezquital": 40},
                    { "Nazas": 351},
                    { "Nombre de Dios": 2205},
                    { "Nuevo Ideal": 1819},
                    { "Ocampo": 65},
                    { "Otáez": 638},
                    { "Pánuco de Coronado": 441},
                    { "Peñón Blanco": 23},
                    { "Poanas": 430},
                    { "Pueblo Nuevo": 4515},
                    { "Rodeo": 477},
                    { "San Bernardo": 2},
                    { "San Dimas": 2193},
                    { "San Juan de Guadalupe": 4},
                    { "San Juan del Río": 876},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 23},
                    { "Santiago Papasquiaro": 4982},
                    { "Súchil": 24},
                    { "Tamazula": 190},
                    { "Tepehuanes": 693},
                    { "Tlahualilo": 4365},
                    { "Topia": 627},
                    { "Vicente Guerrero": 2106}
                ]
            },
            {
                "7":[
                    { "Canatlán": 1643},
                    { "Canelas": 253},
                    { "Cuencamé": 3531},
                    { "Durango": 118534},
                    { "El Oro": 286},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 92027},
                    { "Guadalupe Victoria": 1772},
                    { "Guanaceví": 1617},
                    { "Hidalgo": 101},
                    { "Indé": 373},
                    { "Lerdo": 11759},
                    { "Mapimí": 1217},
                    { "Mezquital": 41},
                    { "Nazas": 361},
                    { "Nombre de Dios": 2135},
                    { "Nuevo Ideal": 1795},
                    { "Ocampo": 68},
                    { "Otáez": 563},
                    { "Pánuco de Coronado": 431},
                    { "Peñón Blanco": 24},
                    { "Poanas": 436},
                    { "Pueblo Nuevo": 4135},
                    { "Rodeo": 466},
                    { "San Bernardo": 0},
                    { "San Dimas": 2072},
                    { "San Juan de Guadalupe": 4},
                    { "San Juan del Río": 877},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 22},
                    { "Santiago Papasquiaro": 4921},
                    { "Súchil": 24},
                    { "Tamazula": 186},
                    { "Tepehuanes": 667},
                    { "Tlahualilo": 4339},
                    { "Topia": 615},
                    { "Vicente Guerrero": 2106}
                ]
            },
            {
                "8":[
                    { "Canatlán": 1537},
                    { "Canelas": 253},
                    { "Cuencamé": 3513},
                    { "Durango": 118651},
                    { "El Oro": 291},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 92840},
                    { "Guadalupe Victoria": 1818},
                    { "Guanaceví": 1596},
                    { "Hidalgo": 15},
                    { "Indé": 366},
                    { "Lerdo": 11963},
                    { "Mapimí": 1225},
                    { "Mezquital": 41},
                    { "Nazas": 361},
                    { "Nombre de Dios": 2065},
                    { "Nuevo Ideal": 1836},
                    { "Ocampo": 70},
                    { "Otáez": 798},
                    { "Pánuco de Coronado": 434},
                    { "Peñón Blanco": 25},
                    { "Poanas": 452},
                    { "Pueblo Nuevo": 4194},
                    { "Rodeo": 485},
                    { "San Bernardo": 0},
                    { "San Dimas": 2046},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1002},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 22},
                    { "Santiago Papasquiaro": 5034},
                    { "Súchil": 23},
                    { "Tamazula": 162},
                    { "Tepehuanes": 659},
                    { "Tlahualilo": 4431},
                    { "Topia": 594},
                    { "Vicente Guerrero": 2121}
                ]
            },
            {
                "9":[
                    { "Canatlán": 1517},
                    { "Canelas": 249},
                    { "Cuencamé": 3521},
                    { "Durango": 118837},
                    { "El Oro": 287},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 92637},
                    { "Guadalupe Victoria": 1866},
                    { "Guanaceví": 1593},
                    { "Hidalgo": 15},
                    { "Indé": 373},
                    { "Lerdo": 12149},
                    { "Mapimí": 1201},
                    { "Mezquital": 45},
                    { "Nazas": 365},
                    { "Nombre de Dios": 2142},
                    { "Nuevo Ideal": 1958},
                    { "Ocampo": 73},
                    { "Otáez": 737},
                    { "Pánuco de Coronado": 440},
                    { "Peñón Blanco": 28},
                    { "Poanas": 454},
                    { "Pueblo Nuevo": 4338},
                    { "Rodeo": 517},
                    { "San Bernardo": 0},
                    { "San Dimas": 2052},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1021},
                    { "San Luis del Cordero": 4},
                    { "San Pedro del Gallo": 5},
                    { "Santa Clara": 22},
                    { "Santiago Papasquiaro": 4974},
                    { "Súchil": 23},
                    { "Tamazula": 162},
                    { "Tepehuanes": 665},
                    { "Tlahualilo": 4617},
                    { "Topia": 586},
                    { "Vicente Guerrero": 2109}
                ]
            },
            {
                "10":[
                    { "Canatlán": 1505},
                    { "Canelas": 252},
                    { "Cuencamé": 3570},
                    { "Durango": 118912},
                    { "El Oro": 279},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 92626},
                    { "Guadalupe Victoria": 1915},
                    { "Guanaceví": 1542},
                    { "Hidalgo": 19},
                    { "Indé": 384},
                    { "Lerdo": 12212},
                    { "Mapimí": 1211},
                    { "Mezquital": 46},
                    { "Nazas": 366},
                    { "Nombre de Dios": 2216},
                    { "Nuevo Ideal": 1966},
                    { "Ocampo": 69},
                    { "Otáez": 794},
                    { "Pánuco de Coronado": 436},
                    { "Peñón Blanco": 26},
                    { "Poanas": 446},
                    { "Pueblo Nuevo": 4519},
                    { "Rodeo": 503},
                    { "San Bernardo": 0},
                    { "San Dimas": 2085},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1030},
                    { "San Luis del Cordero": 5},
                    { "San Pedro del Gallo": 5},
                    { "Santa Clara": 18},
                    { "Santiago Papasquiaro": 4957},
                    { "Súchil": 23},
                    { "Tamazula": 158},
                    { "Tepehuanes": 685},
                    { "Tlahualilo": 4670},
                    { "Topia": 500},
                    { "Vicente Guerrero": 2093}
                ]
            },
            {
                "11":[
                    { "Canatlán": 1496},
                    { "Canelas": 250},
                    { "Cuencamé": 3596},
                    { "Durango": 117738},
                    { "El Oro": 283},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 92700},
                    { "Guadalupe Victoria": 1992},
                    { "Guanaceví": 1478},
                    { "Hidalgo": 19},
                    { "Indé": 386},
                    { "Lerdo": 12380},
                    { "Mapimí": 1232},
                    { "Mezquital": 41},
                    { "Nazas": 373},
                    { "Nombre de Dios": 2286},
                    { "Nuevo Ideal": 1980},
                    { "Ocampo": 104},
                    { "Otáez": 831},
                    { "Pánuco de Coronado": 438},
                    { "Peñón Blanco": 27},
                    { "Poanas": 450},
                    { "Pueblo Nuevo": 4700},
                    { "Rodeo": 478},
                    { "San Bernardo": 0},
                    { "San Dimas": 2151},
                    { "San Juan de Guadalupe": 7},
                    { "San Juan del Río": 1025},
                    { "San Luis del Cordero": 6},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 21},
                    { "Santiago Papasquiaro": 4954},
                    { "Súchil": 23},
                    { "Tamazula": 181},
                    { "Tepehuanes": 697},
                    { "Tlahualilo": 4789},
                    { "Topia": 490},
                    { "Vicente Guerrero": 2104}
                ]
            },
            {
                "12":[
                    { "Canatlán": 1480},
                    { "Canelas": 236},
                    { "Cuencamé": 3576},
                    { "Durango": 116168},
                    { "El Oro": 281},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 90729},
                    { "Guadalupe Victoria": 1996},
                    { "Guanaceví": 1478},
                    { "Hidalgo": 18},
                    { "Indé": 381},
                    { "Lerdo": 12081},
                    { "Mapimí": 1222},
                    { "Mezquital": 39},
                    { "Nazas": 375},
                    { "Nombre de Dios": 2271},
                    { "Nuevo Ideal": 1934},
                    { "Ocampo": 71},
                    { "Otáez": 774},
                    { "Pánuco de Coronado": 425},
                    { "Peñón Blanco": 27},
                    { "Poanas": 443},
                    { "Pueblo Nuevo": 4254},
                    { "Rodeo": 408},
                    { "San Bernardo": 0},
                    { "San Dimas": 2081},
                    { "San Juan de Guadalupe": 7},
                    { "San Juan del Río": 1018},
                    { "San Luis del Cordero": 5},
                    { "San Pedro del Gallo": 6},
                    { "Santa Clara": 20},
                    { "Santiago Papasquiaro": 4630},
                    { "Súchil": 23},
                    { "Tamazula": 185},
                    { "Tepehuanes": 679},
                    { "Tlahualilo": 4702},
                    { "Topia": 455},
                    { "Vicente Guerrero": 2067}
                ]
            }
    ]
    },
    {
        "2024":[
            {
                "1":[
                    { "Canatlán": 1467},
                    { "Canelas": 220},
                    { "Cuencamé": 3702},
                    { "Durango": 116910},
                    { "El Oro": 274},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 91334},
                    { "Guadalupe Victoria": 1969},
                    { "Guanaceví": 1474},
                    { "Hidalgo": 19},
                    { "Indé": 397},
                    { "Lerdo": 12182},
                    { "Mapimí": 1472},
                    { "Mezquital": 39},
                    { "Nazas": 373},
                    { "Nombre de Dios": 2389},
                    { "Nuevo Ideal": 1911},
                    { "Ocampo": 73},
                    { "Otáez": 834},
                    { "Pánuco de Coronado": 417},
                    { "Peñón Blanco": 27},
                    { "Poanas": 443},
                    { "Pueblo Nuevo": 4546},
                    { "Rodeo": 393},
                    { "San Bernardo": 0},
                    { "San Dimas": 2195},
                    { "San Juan de Guadalupe": 6},
                    { "San Juan del Río": 1117},
                    { "San Luis del Cordero": 5},
                    { "San Pedro del Gallo": 11},
                    { "Santa Clara": 19},
                    { "Santiago Papasquiaro": 4750},
                    { "Súchil": 23},
                    { "Tamazula": 208},
                    { "Tepehuanes": 677},
                    { "Tlahualilo": 4778},
                    { "Topia": 479},
                    { "Vicente Guerrero": 2033}
                ]
            },
            {
                "2":[
                    { "Canatlán": 1483},
                    { "Canelas": 213},
                    { "Cuencamé": 3714},
                    { "Durango": 116969},
                    { "El Oro": 280},
                    { "General Simón Bolívar": 4},
                    { "Gómez Palacio": 92252},
                    { "Guadalupe Victoria": 1957},
                    { "Guanaceví": 1472},
                    { "Hidalgo": 19},
                    { "Indé": 408},
                    { "Lerdo": 12311},
                    { "Mapimí": 1626},
                    { "Mezquital": 39},
                    { "Nazas": 371},
                    { "Nombre de Dios": 2312},
                    { "Nuevo Ideal": 1922},
                    { "Ocampo": 70},
                    { "Otáez": 805},
                    { "Pánuco de Coronado": 429},
                    { "Peñón Blanco": 27},
                    { "Poanas": 436},
                    { "Pueblo Nuevo": 4662},
                    { "Rodeo": 401},
                    { "San Bernardo": 0},
                    { "San Dimas": 2192},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1079},
                    { "San Luis del Cordero": 6},
                    { "San Pedro del Gallo": 15},
                    { "Santa Clara": 12},
                    { "Santiago Papasquiaro": 4780},
                    { "Súchil": 23},
                    { "Tamazula": 221},
                    { "Tepehuanes": 621},
                    { "Tlahualilo": 4858},
                    { "Topia": 508},
                    { "Vicente Guerrero": 2053}
                ]
            },
            {
                "3":[
                    { "Canatlán": 1446},
                    { "Canelas": 207},
                    { "Cuencamé": 3612},
                    { "Durango": 116564},
                    { "El Oro": 273},
                    { "General Simón Bolívar": 4},
                    { "Gómez Palacio": 92596},
                    { "Guadalupe Victoria": 1957},
                    { "Guanaceví": 1468},
                    { "Hidalgo": 19},
                    { "Indé": 405},
                    { "Lerdo": 11984},
                    { "Mapimí": 1671},
                    { "Mezquital": 38},
                    { "Nazas": 376},
                    { "Nombre de Dios": 2282},
                    { "Nuevo Ideal": 1897},
                    { "Ocampo": 70},
                    { "Otáez": 777},
                    { "Pánuco de Coronado": 417},
                    { "Peñón Blanco": 26},
                    { "Poanas": 424},
                    { "Pueblo Nuevo": 4429},
                    { "Rodeo": 400},
                    { "San Bernardo": 0},
                    { "San Dimas": 2211},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1042},
                    { "San Luis del Cordero": 6},
                    { "San Pedro del Gallo": 12},
                    { "Santa Clara": 13},
                    { "Santiago Papasquiaro": 4690},
                    { "Súchil": 24},
                    { "Tamazula": 220},
                    { "Tepehuanes": 643},
                    { "Tlahualilo": 5025},
                    { "Topia": 497},
                    { "Vicente Guerrero": 2104}
                ]
            },
            {
                "4":[
                    { "Canatlán": 1415},
                    { "Canelas": 181},
                    { "Cuencamé": 3532},
                    { "Durango": 117552},
                    { "El Oro": 271},
                    { "General Simón Bolívar": 4},
                    { "Gómez Palacio": 93037},
                    { "Guadalupe Victoria": 2027},
                    { "Guanaceví": 1475},
                    { "Hidalgo": 17},
                    { "Indé": 413},
                    { "Lerdo": 12000},
                    { "Mapimí": 1687},
                    { "Mezquital": 37},
                    { "Nazas": 373},
                    { "Nombre de Dios": 2228},
                    { "Nuevo Ideal": 1867},
                    { "Ocampo": 98},
                    { "Otáez": 853},
                    { "Pánuco de Coronado": 426},
                    { "Peñón Blanco": 26},
                    { "Poanas": 428},
                    { "Pueblo Nuevo": 4772},
                    { "Rodeo": 396},
                    { "San Bernardo": 0},
                    { "San Dimas": 2294},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1024},
                    { "San Luis del Cordero": 7},
                    { "San Pedro del Gallo": 11},
                    { "Santa Clara": 15},
                    { "Santiago Papasquiaro": 4677},
                    { "Súchil": 24},
                    { "Tamazula": 604},
                    { "Tepehuanes": 658},
                    { "Tlahualilo": 4940},
                    { "Topia": 533},
                    { "Vicente Guerrero": 2069}
                ]
            },
            {
                "5":[
                    { "Canatlán": 1389},
                    { "Canelas": 322},
                    { "Cuencamé": 3251},
                    { "Durango": 117198},
                    { "El Oro": 240},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 93635},
                    { "Guadalupe Victoria": 1904},
                    { "Guanaceví": 1467},
                    { "Hidalgo": 19},
                    { "Indé": 425},
                    { "Lerdo": 12039},
                    { "Mapimí": 1671},
                    { "Mezquital": 39},
                    { "Nazas": 374},
                    { "Nombre de Dios": 2162},
                    { "Nuevo Ideal": 1841},
                    { "Ocampo": 72},
                    { "Otáez": 844},
                    { "Pánuco de Coronado": 421},
                    { "Peñón Blanco": 25},
                    { "Poanas": 432},
                    { "Pueblo Nuevo": 4731},
                    { "Rodeo": 399},
                    { "San Bernardo": 0},
                    { "San Dimas": 2341},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1062},
                    { "San Luis del Cordero": 7},
                    { "San Pedro del Gallo": 11},
                    { "Santa Clara": 15},
                    { "Santiago Papasquiaro": 4511},
                    { "Súchil": 24},
                    { "Tamazula": 236},
                    { "Tepehuanes": 669},
                    { "Tlahualilo": 4879},
                    { "Topia": 533},
                    { "Vicente Guerrero": 2050}
                ]
            },
            {
                "6":[
                    { "Canatlán": 1353},
                    { "Canelas": 340},
                    { "Cuencamé": 3215},
                    { "Durango": 117632},
                    { "El Oro": 265},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 93999},
                    { "Guadalupe Victoria": 1890},
                    { "Guanaceví": 1464},
                    { "Hidalgo": 18},
                    { "Indé": 423},
                    { "Lerdo": 12088},
                    { "Mapimí": 1683},
                    { "Mezquital": 41},
                    { "Nazas": 381},
                    { "Nombre de Dios": 2141},
                    { "Nuevo Ideal": 1843},
                    { "Ocampo": 73},
                    { "Otáez": 807},
                    { "Pánuco de Coronado": 411},
                    { "Peñón Blanco": 24},
                    { "Poanas": 433},
                    { "Pueblo Nuevo": 4604},
                    { "Rodeo": 389},
                    { "San Bernardo": 1},
                    { "San Dimas": 2343},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1054},
                    { "San Luis del Cordero": 7},
                    { "San Pedro del Gallo": 10},
                    { "Santa Clara": 24},
                    { "Santiago Papasquiaro": 4470},
                    { "Súchil": 24},
                    { "Tamazula": 220},
                    { "Tepehuanes": 674},
                    { "Tlahualilo": 4770},
                    { "Topia": 522},
                    { "Vicente Guerrero": 2008}
                ]
            },
            {
                "7":[
                    { "Canatlán": 1336},
                    { "Canelas": 340},
                    { "Cuencamé": 3159},
                    { "Durango": 116528},
                    { "El Oro": 259},
                    { "General Simón Bolívar": 3},
                    { "Gómez Palacio": 93235},
                    { "Guadalupe Victoria": 1836},
                    { "Guanaceví": 1457},
                    { "Hidalgo": 18},
                    { "Indé": 428},
                    { "Lerdo": 11889},
                    { "Mapimí": 1625},
                    { "Mezquital": 42},
                    { "Nazas": 381},
                    { "Nombre de Dios": 2229},
                    { "Nuevo Ideal": 1805},
                    { "Ocampo": 71},
                    { "Otáez": 821},
                    { "Pánuco de Coronado": 430},
                    { "Peñón Blanco": 24},
                    { "Poanas": 436},
                    { "Pueblo Nuevo": 4373},
                    { "Rodeo": 395},
                    { "San Bernardo": 1},
                    { "San Dimas": 2281},
                    { "San Juan de Guadalupe": 5},
                    { "San Juan del Río": 1026},
                    { "San Luis del Cordero": 6},
                    { "San Pedro del Gallo": 10},
                    { "Santa Clara": 23},
                    { "Santiago Papasquiaro": 4395},
                    { "Súchil": 23},
                    { "Tamazula": 223},
                    { "Tepehuanes": 671},
                    { "Tlahualilo": 4685},
                    { "Topia": 528},
                    { "Vicente Guerrero": 2016}
                ]
            }
        ]
    }
]