import { Component, Input, SimpleChanges } from '@angular/core';
import { InformacionCardCarrousel, InformacionItem } from '../models/cardCarrousel.model';

@Component({
  selector: 'app-card-carrousel',
  templateUrl: './card-carrousel.component.html',
  styleUrl: './card-carrousel.component.css'
})
export class CardCarrouselComponent {
  @Input() data!: Array<InformacionItem>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] !== undefined) {
      this.data = changes['data'].currentValue; 
      this.setDatosEficiencia(0);    
    }
  }

  urlIcon!: string  | undefined;
  indice! : string;
  valor!: string;
  nota!: string;
  i: number = 0;

  //#region Cambio de gráfica
  changeInversion(atras: boolean) {
    let invs = this.data.length;
    if (atras) {
      if (this.i == 0) {
        this.i = invs - 1;
      } else {
        this.i -= 1;
      }
    } else {
      if (this.i == invs - 1) {
        this.i = 0;
      } else {
        this.i += 1;
      }
    }
    console.log(this.i);
    
    this.setDatosEficiencia(this.i);
  }

  setDatosEficiencia(i: number) {
    this.indice = this.data[i].subtitulo;
    this.nota = this.data[i].acciones[0];
    this.urlIcon = this.data[i].iconoUrl ? this.data[i].iconoUrl : '../../assets/energia.svg';
    // this.titleEficiencia = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(this.inversiones[this.i].inversion,);
  }
}
