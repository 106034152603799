import { Component, Input } from '@angular/core';
import { circularDataDS, circularDataset } from '../../models/graphs.model';

@Component({
  selector: 'competitivo-empleos',
  templateUrl: './empleos.component.html',
  styleUrl: './empleos.component.css'
})
export class CompetitivoEmpleosComponent {

  @Input({ required: true }) ds?: string;
  @Input({ required: true }) mes?: string;
  @Input({ required: true }) anio?: string;

  public empleoJSON: string = '{}';
  private arcData?: circularDataDS[];

  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.arcData = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }
    this.empleoJSON = this.getPIBDS();
  }
  
  getPIBDS(){
    let arcDataJSON = '{}';

    if(this.arcData){
      let existe = this.arcData.find(x => x.name === 'Nuevos');
      let arcdataset: circularDataset = {
        rotate: 80,
        cutout: 70,
        title: "EMPLEOS",
        summary: true,
        custSummary: Intl.NumberFormat('es-MX', { style: 'decimal' }).format( existe?.data ? existe?.data : 0, ),
        dataset: this.arcData
      }

      arcDataJSON = JSON.stringify(arcdataset);
    }

    return arcDataJSON;
  }
}
