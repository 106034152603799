import { Component, Input } from '@angular/core';
import { stackCardModel } from '../models/cardStack,model';

declare var bootstrap: any;

@Component({
  selector: 'app-card-stack',
  templateUrl: './card-stack.component.html',
  styleUrl: './card-stack.component.css'
})
export class CardStackComponent {
  @Input({ required: true }) ds?: string;
  dataCard!: stackCardModel;
  public stackJSON: string = '{}';

  constructor() {
  }

  ngOnInit(): void {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.dataCard = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }
  }

  ngAfterViewInit(): void {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  getWidth(){
    return '25%';
  }


}
