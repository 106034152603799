<div class="container">
    <h5 *ngIf="dataCard.title">
        {{ dataCard.title }}
        <button type="button" class="btn btn-white p-0" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true"
            title="{{dataCard.titleTooltip}}" *ngIf="dataCard.titleTooltip">
            <i class="bi bi-info-circle"></i>
        </button>
    </h5>
    <p>{{ dataCard.period }}</p>
    <div class="card">
        <div class="card-body" attr.style="background-color: {{dataCard.bgColor}}; color: {{dataCard.color}}">
            @for (item of dataCard.data; track $index) {
            <div class="row {{ item.contentClass ? item.contentClass : '' }}">
                <div class="col-xl">
                    <span>{{item.title}}</span>
                    <h3>{{item.value}}</h3>
                    <h1>{{item.valueh1}}</h1>
                </div>
                <div class="col-xl-3 m-auto" *ngIf="item.iconUrl">
                    <img src="{{item.iconUrl}}" alt="" srcset="">
                </div>
                <div class="container" *ngIf="item.progresbar">
                    <div class="progress" role="progressbar" aria-label="Basic example"
                        attr.aria-valuenow="{{item.progresbar.value}}" attr.aria-valuemin="{{item.progresbar.min}}"
                        attr.aria-valuemax="{{item.progresbar.max}}" attr.style="--bs-progress-bar-bg: {{item.progresbar.color}} !important;">
                        <div class="progress-bar"
                            attr.style="width: {{ (item.progresbar.value / item.progresbar.max ) * 100}}%;"></div>
                    </div>
                </div>
                <small>
                    {{item.footer}}
                    <button type="button" class="btn btn-white" data-bs-toggle="tooltip" data-bs-placement="top"
                        data-bs-html="true" title="{{item.footerTooltip}}" *ngIf="item.footerTooltip">
                        <i class="bi bi-info-circle"></i>
                    </button>
                </small>

            </div>
            }
        </div>
    </div>
</div>