<div class="card">
    <div class="card-body card-min">
        <div class="row">
            <div class="contenedor">
                <div class="boton d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-white" (click)="changeInversion(true)">&#60;</button>
                </div>
                <div class="contenido">
                    <img src="{{ urlIcon }}" class="img-size" alt="">
                    <p class="subtitle">{{ indice }}</p>
                    <h5>{{ valor }}</h5>
                    <p class="nota">{{ nota }}</p>
                </div>
                <div class="boton d-flex align-items-center justify-content-center">
                    <button type="button" class="btn btn-white" (click)="changeInversion(false)">&#62;</button>
                </div>
            </div>
        </div>
    </div>
</div>