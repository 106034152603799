<div class="row">
    <div class="col-xl-9">
        <h5>
            Producción Industrial
            <button type="button" class="btn btn-white p-0" data-bs-toggle="tooltip" data-bs-placement="top"
                data-bs-html="true" title="<b>Producción industrial</b>: Medida en términos de valor agregado bruto de la producción industrial en el estado. Este indicador refleja la contribución del sector industrial a la economía estatal.
                quitar subitutlo de contribución al PIB y dejarlo en el texto del indicador del PIB">
                <i class="bi bi-info-circle"></i>
            </button>
        </h5>
        <div class="lineChart">
            <graph-line [ds]=lineJSON></graph-line>
        </div>
    </div>
    <div class="col-xl-3">
        <h5 class="text-center">Producción Industrial</h5>
        <app-card-stack [ds]=PIBdata></app-card-stack>
        <br>
        <app-card-stack [ds]=PIBdataPesos></app-card-stack>
    </div>
</div>

<div class="row m-4"></div>


<div class="row">
    <h5>
        Empleos en el Sector Industrial
    </h5>
    <div class="my-2"></div>
    <div class="lineChart justify-content-center">
        <!--
        <graph-bar [ds]=barJSON></graph-bar>
        -->
        <div class="container rounded border cbox h9">
            <div class="row">
                <div class="col-xl-1">
                    <button type="button" class="btn btn-white" (click)="actEmpleo(true)">&#60;</button>
                </div>
                <div class="col-xl-10">
                    <h3>{{empTitle}}</h3>
                </div>
                <div class="col-xl-1">
                    <button type="button" class="btn btn-white" (click)="actEmpleo(false)">&#62;</button>
                </div>
            </div>
            <div class="row">
                <div class="my-4">
                    <graph-bar [ds]=barJSON></graph-bar>
                </div>
            </div>
        </div>
    </div>


    <div class="row m-4"></div>

    <div class="row">
        <h5>
            Valor de las exportaciones
        </h5>
        <div class="lineChart rounded border py-4">
            <graph-bar [ds]=barJSON2></graph-bar>
        </div>
    </div>

    <div class="row m-4"></div>

    <div class="row">
        <div class="col-xl-6">
            <h3>Inversión en Infraestructura Industrial</h3>
            <div class="border">
                <div class="row " style="height: 12em;">
                    <div class="col-md-8">
                        <div class="row d-flex justify-content-between my-3">
                            <div class="col-lg-2">
                                <button type="button" class="btn btn-white" (click)="minuChange(true)">&#60;</button>
                            </div>
                            <div class="col-lg-8 mt-2">
                                <h5 class="text-center align-middle">{{municipio}}</h5>
                            </div>
                            <div class="col-lg-2">
                                <button type="button" class="btn btn-white" (click)="minuChange(false)">&#62;</button>
                            </div>
                        </div>
                        <h3 class="text-center mb-3">$ {{municipioMonto}} dólares</h3>
                        <h6 class="text-center">{{municipioSector}}</h6>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center">
                        <img class="resizable-image" src="../../../assets/municipios/{{municipio}}.png"
                            onerror="this.src='../../../assets/municipios/DuragoEdo.png';" id="imgMunicipio">
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <h3>Tasa de Utilización de la Capacidad Instalada</h3>
            <div id="tooltip" display="none" style="position: absolute; display: none;"></div>
            <div class="container border d-flex justify-content-center" style="height: 320px;">
                <svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#"
                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" width="500.3934"
                    height="500.2612" version="1.0" id="mapDurangoInd">
                    <g inkscape:label="Capa 1" inkscape:groupmode="layer" id="layer1"
                        transform="translate(150,-2), scale(0.3)">
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 796.16059,48.52799 L 778.86668,74.78912 L 789.75544,79.913243 L 782.70977,93.364065 L 796.80111,98.488188 L 787.19338,115.14159 L 783.9908,130.51396 L 841.63719,200.97065 L 855.08801,240.6826 L 864.05522,240.04208 L 888.39481,259.25754 L 900.5646,247.72827 L 909.53181,254.13342 L 918.49903,252.85239 L 909.53181,232.3559 L 919.13954,208.65683 L 922.98264,182.3957 L 938.355,160.61818 L 915.29645,123.46829 L 915.93697,93.364065 L 843.55873,49.809021 L 796.16059,48.52799 z "
                            id="path2348" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 887.75429,325.87114 L 865.97677,313.06083 L 845.48028,280.39455 L 857.00955,276.55146 L 871.10089,245.80672 L 887.75429,258.61703 L 900.5646,247.08775 L 909.53181,254.77394 L 918.49903,252.85239 L 922.34212,267.58424 C 922.12862,268.43826 921.91511,269.29228 921.70161,270.14631 C 920.99186,272.98531 919.19532,277.11761 917.218,279.75404 C 914.90067,282.8438 914.46137,284.43221 912.09387,286.7997 C 909.77309,289.12049 908.25078,291.64441 908.25078,295.1264 C 908.25078,299.48222 908.62947,300.7017 905.68872,302.17207 C 903.64885,303.19201 900.40425,305.53499 899.28357,306.65568 C 897.04303,308.89622 895.23656,307.47134 894.15944,311.7798 C 893.38771,314.86674 890.95687,314.38399 890.95687,318.82547 C 890.95687,322.93569 891.20851,323.28048 887.75429,325.87114 z "
                            id="path2350" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 845.48028,279.75404 L 833.31049,275.91094 L 799.36317,275.27043 L 787.83389,288.72125 L 795.52008,291.28331 L 812.17348,329.07372 L 798.72266,333.55733 L 787.83389,329.71423 L 776.94513,313.70135 L 766.69689,319.46599 L 752.60555,316.90393 L 743.63833,307.2962 L 751.32452,271.42734 L 735.31164,271.42734 L 727.62545,281.67558 L 721.2203,268.86527 L 723.14184,258.61703 L 710.33154,247.08775 L 696.88071,247.08775 L 691.75659,241.32311 L 670.61958,239.40157 L 661.65237,225.95075 L 670.61958,222.10765 L 667.41701,195.20601 L 680.22731,193.28446 L 684.71092,166.38282 L 672.54113,153.57251 L 666.77649,137.55963 L 684.07041,122.18726 L 682.78938,110.65798 L 698.80226,83.756335 L 719.93927,67.743451 L 726.98494,54.292628 L 723.78236,36.358198 L 732.74957,28.031498 L 745.55988,45.965928 L 762.8538,44.044382 L 773.74256,52.371082 L 787.83389,41.482321 L 796.80111,49.168505 L 778.86668,74.78912 L 789.75544,80.553758 L 782.06926,93.364065 L 795.52008,98.488188 L 787.19338,114.50107 L 784.63132,129.87344 L 840.99667,200.33013 L 854.44749,240.04208 L 864.05522,240.04208 L 871.10089,245.16621 L 857.65007,276.55146 L 845.48028,279.75404 z "
                            id="path2352" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 887.18539,325.63642 L 910.77306,355.00067 L 900.66406,355.00067 L 880.92743,381.95801 L 885.74124,390.14149 L 872.74395,398.32497 L 862.15357,397.3622 L 853.00733,404.58292 L 831.34518,403.62016 L 816.42236,364.62829 L 823.1617,355.48205 L 814.01546,343.9289 L 802.94369,345.85443 L 793.79745,346.81719 L 788.02088,329.48747 L 799.09264,333.8199 L 812.57131,328.52471 L 795.24159,290.97698 L 787.05812,287.60731 L 799.57402,274.12864 L 833.2707,275.57278 L 845.78661,279.90521 L 865.52324,312.63913 L 887.18539,325.63642 z "
                            id="path2354" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 885.74124,390.62287 L 932.43521,434.90993 L 928.58416,444.53755 L 949.28355,460.42313 L 949.76493,473.9018 L 965.16913,489.306 L 1006.0865,502.78467 L 992.60785,518.18886 L 975.27813,517.70748 L 973.83399,525.89096 L 991.64509,551.40416 L 1005.6051,564.40145 L 1008.9748,574.02907 L 1004.161,582.69393 L 989.71956,566.80835 L 984.42437,574.99183 L 975.75951,566.32697 L 961.79946,583.17531 L 944.46974,577.88012 L 942.06283,550.4414 L 931.95383,550.4414 L 917.5124,539.85101 L 896.81301,504.22881 L 904.99649,486.41771 L 904.03373,472.93904 L 896.81301,462.34865 L 907.88477,463.31142 L 906.92201,453.68379 L 888.62953,448.3886 L 903.07096,432.02164 L 892.48058,428.65198 L 881.40881,428.65198 L 872.26257,436.83545 L 857.82114,428.17059 L 879.96467,408.91535 L 872.74395,405.54568 L 872.26257,397.3622 L 885.74124,390.62287 z "
                            id="path2356" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 1003.6796,582.69393 L 1054.2246,585.58222 L 1069.6288,578.3615 L 1088.4027,578.3615 L 1087.9213,535.0372 L 1069.6288,524.44682 L 1084.0703,508.56124 L 1075.4054,468.60661 L 1047.9667,439.72374 L 1033.5252,457.53484 L 1017.1583,488.82461 L 1017.1583,502.78467 L 1006.0865,502.78467 L 993.08923,517.70748 L 974.31537,517.70748 L 973.83399,526.37234 L 991.64509,551.88554 L 1005.1238,564.40145 L 1008.9748,574.02907 L 1003.6796,582.69393 z "
                            id="path2358" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 944.68457,577.75281 L 941.53371,550.25441 L 941.53371,550.25441 L 931.7947,550.8273 L 931.7947,550.8273 L 917.47261,540.22896 L 917.47261,540.22896 L 896.84881,504.42375 L 887.1098,520.75092 L 876.51146,525.33399 L 872.21483,590.92913 L 847.29441,602.95968 L 846.72152,612.12581 L 851.59103,618.14109 L 863.62158,619.57329 L 865.34023,605.82409 L 877.65722,605.25121 L 892.83863,592.93422 L 893.41151,573.74263 L 944.68457,577.75281 z "
                            id="path2172" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 822.85139,381.4448 L 814.71167,384.4047 L 809.90184,398.8342 L 793.6224,415.11363 L 801.76212,428.43317 L 789.55254,438.05283 L 789.55254,452.48233 L 783.63275,453.96228 L 776.60299,463.95193 L 788.81257,489.48104 L 801.76212,489.11106 L 808.42189,483.56125 L 821.37144,503.91054 L 809.16186,509.46035 L 808.0519,529.43965 L 787.33262,542.75919 L 787.33262,556.8187 L 794.36238,565.32841 L 793.99239,581.97783 L 773.27311,571.98817 L 772.16315,581.97783 L 796.95229,594.92738 L 794.73236,617.86658 L 786.96263,623.78637 L 790.29252,637.10591 L 781.04284,647.09556 L 788.81257,661.52506 L 796.21231,661.52506 L 795.10235,647.46555 L 801.39213,639.69582 L 821.37144,656.71523 L 842.83069,639.69582 L 851.71038,648.9455 L 865.02992,631.92609 L 864.28995,619.34653 L 851.71038,618.23657 L 846.16058,612.31677 L 846.90055,603.06709 L 872.42966,591.22751 L 876.49952,524.99981 L 887.96912,520.55996 L 896.84881,505.0205 L 905.35852,485.78117 L 903.87857,473.20161 L 896.84881,462.102 L 908.6884,462.84197 L 906.83847,453.96228 L 888.7091,448.41247 L 902.76861,432.13304 L 892.03898,428.06318 L 880.56938,428.43317 L 872.42966,436.94287 L 857.63018,428.06318 L 880.19939,408.82385 L 872.42966,405.12398 L 872.05968,398.09422 L 862.44001,396.98426 L 853.19033,404.01402 L 831.73108,403.27404 L 822.85139,381.4448 z "
                            id="path2174" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 786.1232,641.25534 L 768.35984,634.0744 L 755.50975,615.55515 L 745.30527,619.71254 L 744.54938,634.45235 L 753.99797,638.60973 L 753.99797,646.54655 L 727.91985,660.53047 L 723.38452,669.22318 L 732.07723,679.80561 L 739.63611,678.67178 L 741.52583,693.03364 L 763.82451,696.43514 L 763.44657,706.63962 L 773.65105,711.17495 L 784.98937,711.55289 L 806.15423,700.59252 L 810.68955,687.74243 L 805.39834,677.91589 L 805.39834,667.33346 L 821.64992,656.75103 L 801.6189,639.74356 L 795.19385,647.30244 L 795.94974,661.28636 L 789.14675,661.28636 L 781.20993,647.68038 L 786.1232,641.25534 z "
                            id="path2176" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 803.50862,741.03251 L 773.27311,745.56784 L 760.42302,758.79587 L 746.4391,760.68559 L 735.10078,757.66204 L 762.31274,739.89868 L 763.82451,722.51326 L 773.27311,711.17495 L 784.98937,711.55289 L 806.53217,700.59252 L 812.57927,713.82055 L 805.77628,722.89121 L 803.50862,741.03251 z "
                            id="path2178" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 728.29779,660.53047 L 716.20359,653.72748 L 705.99911,680.18355 L 689.36958,678.67178 L 692.39313,696.43514 L 689.36958,724.78093 L 707.88883,725.15887 L 720.73892,754.63849 L 735.47873,757.2841 L 762.31274,739.89868 L 763.44657,722.89121 L 772.89517,711.17495 L 763.44657,706.63962 L 763.82451,696.05719 L 741.14788,692.6557 L 739.25816,677.91589 L 731.69929,679.80561 L 723.00658,668.84524 L 728.29779,660.53047 z "
                            id="path2180" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 744.92732,619.71254 L 738.50228,610.64188 L 743.41555,601.57123 L 728.29779,600.05946 L 723.00658,594.01235 L 732.45518,587.20937 L 739.63611,584.18581 L 735.10078,575.49311 L 739.63611,569.82395 L 725.65219,538.07667 L 729.80957,532.40751 L 725.65219,524.84863 L 737.74639,517.28975 L 751.73031,517.6677 L 761.55685,529.7619 L 768.35984,518.42359 L 775.54077,530.13984 L 774.78489,544.87965 L 772.13928,552.06059 L 777.43049,565.28862 L 787.25703,556.59591 L 794.43797,565.66657 L 794.43797,581.54021 L 773.27311,571.71367 L 772.13928,581.54021 L 796.32769,594.76824 L 794.81591,618.20076 L 786.50115,623.49198 L 789.90264,636.72001 L 786.50115,641.25534 L 767.9819,634.0744 L 755.50975,615.55515 L 744.92732,619.71254 z "
                            id="path2182" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 803.07498,740.23684 L 815.28455,741.41841 L 814.49684,756.77885 L 800.31798,750.87099 L 789.28997,767.80685 L 764.87082,787.49971 L 756.59982,800.10315 L 747.14725,824.91615 L 746.35953,846.18445 L 738.48239,862.72645 L 715.63866,856.81859 L 704.2168,865.0896 L 696.73351,858.00016 L 692.79494,840.27659 L 710.12466,820.58372 L 705.39837,788.68128 L 720.36495,754.02184 L 735.33153,757.1727 L 745.96567,760.32356 L 760.53839,758.74813 L 773.53568,745.75084 L 803.07498,740.23684 z "
                            id="path2184" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 696.53062,246.72174 L 690.80178,259.13421 L 659.2932,271.06928 L 674.09269,285.86877 L 669.79606,292.075 L 654.99658,292.075 L 645.44852,309.2615 L 628.26202,309.2615 L 599.61786,274.4111 L 571.9285,211.39394 L 549.96797,189.43341 L 543.28433,195.16225 L 523.23342,168.90509 L 568.58668,124.50664 L 568.58668,110.18456 L 579.56694,103.97832 L 579.56694,91.088445 L 598.18565,89.656237 L 610.12072,100.1591 L 622.05579,90.13364 L 632.55865,90.13364 L 632.55865,103.50092 L 653.56437,130.71287 L 666.45424,137.87392 L 672.66048,154.10561 L 684.59555,166.04068 L 679.82152,193.25263 L 667.40905,194.68484 L 670.75087,222.3742 L 661.68022,226.19342 L 670.27347,239.5607 L 691.75659,240.99291 L 696.53062,246.72174 z "
                            id="path2179" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 533.25888,181.31757 L 509.86614,197.54926 L 517.50459,217.12277 L 509.38874,231.92226 L 520.369,250.06356 L 530.87186,251.01837 L 528.48485,267.72746 L 533.25888,282.52695 L 548.05836,280.61734 L 554.742,271.06928 L 561.42564,281.57214 L 561.90304,324.06099 L 578.61214,340.29268 L 589.5924,328.35761 L 595.32123,339.81528 L 605.82409,339.33788 L 623.9654,358.91139 L 642.1067,366.07243 L 657.38359,346.49892 L 656.90619,320.71917 L 645.44852,309.73891 L 627.78462,308.7841 L 600.09526,274.4111 L 571.9285,210.43913 L 549.96797,188.95601 L 543.28433,195.16225 L 533.25888,181.31757 z "
                            id="path2183" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 657.86099,346.49892 L 669.31866,356.04697 L 691.75659,352.22775 L 700.34984,334.08645 L 709.8979,343.1571 L 721.83296,343.1571 L 727.08439,350.79554 L 745.2257,335.04125 L 741.40648,317.37735 L 752.38674,316.89995 L 742.83869,306.87449 L 750.95453,271.54669 L 735.67764,271.54669 L 727.5618,282.04955 L 720.87816,268.20487 L 723.26517,259.13421 L 710.8527,246.72174 L 697.00802,246.72174 L 691.27919,258.65681 L 659.2932,271.54669 L 674.09269,285.39137 L 669.79606,292.075 L 655.47398,291.5976 L 644.97112,310.21631 L 656.90619,320.24177 L 657.86099,346.49892 z "
                            id="path2185" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 669.79606,356.04697 L 704.64646,381.82672 L 728.5166,383.25893 L 735.67764,371.32386 L 751.90934,372.27867 L 753.34155,349.84074 L 745.2257,335.04125 L 727.08439,350.31814 L 721.35556,342.6797 L 710.3753,342.6797 L 700.34984,334.08645 L 691.75659,351.75035 L 669.79606,356.04697 z "
                            id="path2187" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 509.38874,197.07186 C 509.54787,197.07186 509.70701,197.07186 509.38874,197.07186 z "
                            id="path2189" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 509.86614,197.54926 L 498.88588,184.65939 L 492.20224,190.38822 L 501.27289,202.80069 L 482.17678,229.05784 L 474.06093,229.05784 L 469.76431,249.58616 L 475.97055,255.7924 L 457.35184,286.82357 L 461.64846,300.19085 L 473.10613,308.3067 L 487.90561,345.06671 L 494.58925,351.75035 L 501.27289,365.59503 L 510.34354,355.56957 L 522.75602,355.56957 L 541.37472,373.23347 L 548.53576,402.83244 L 568.10928,402.83244 L 578.61214,389.46517 L 599.14046,406.65167 L 606.3015,389.94257 L 621.10098,398.53582 L 622.05579,391.37478 L 613.93994,378.4849 L 634.46826,373.71088 L 634.46826,363.68542 L 623.9654,358.91139 L 606.3015,339.33788 L 595.79864,339.81528 L 589.115,327.40281 L 578.61214,340.77009 L 560.94824,323.58359 L 561.90304,281.57214 L 554.742,271.54669 L 547.58096,280.61734 L 533.73628,282.04955 L 528.48485,268.20487 L 531.34927,251.01837 L 520.8464,250.06356 L 508.91134,231.92226 L 517.50459,216.64537 L 509.86614,197.54926 z "
                            id="path2191" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 704.2168,381.71931 L 703.62403,414.32194 L 700.06738,428.54854 L 712.51565,442.18237 L 727.92781,442.18237 L 735.63388,433.88351 L 748.67493,445.73902 L 759.93766,434.47629 L 766.45819,453.44509 L 782.46311,454.03787 L 790.16919,451.66677 L 789.57641,438.62572 L 802.02469,427.36299 L 793.13307,414.32194 L 809.73077,399.50256 L 815.06574,384.09041 L 822.77182,381.12653 L 816.25129,365.1216 L 822.77182,355.04443 L 813.88019,343.7817 L 793.72584,346.74558 L 788.39086,330.14787 L 777.12814,314.14295 L 767.05096,319.47792 L 752.82436,317.10682 L 741.56163,316.51405 L 745.71106,335.48285 L 753.41713,350.30223 L 751.63881,371.64213 L 736.22666,371.04936 L 728.52058,383.49763 L 704.2168,381.71931 z "
                            id="path2193" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 724.96393,537.61915 L 711.92288,519.24313 L 722.59283,515.0937 L 726.74226,505.01652 L 713.7012,503.83097 L 723.18561,493.16102 L 722.59283,464.11504 L 716.0723,467.67169 L 712.51565,442.77514 L 727.33503,442.18237 L 735.63388,433.88351 L 748.67493,445.73902 L 760.53044,434.47629 L 766.45819,452.25954 L 783.05589,454.63064 L 777.12814,463.52227 L 788.98364,489.60437 L 802.02469,488.41882 L 807.95244,482.49107 L 821.58627,503.83097 L 808.54522,509.75873 L 808.54522,528.72753 L 787.20531,542.95413 L 787.20531,556.58796 L 777.12814,564.88681 L 771.79316,552.43853 L 775.34981,545.32523 L 775.34981,529.91308 L 767.64374,518.65035 L 761.71599,529.91308 L 752.23158,518.05758 L 738.00498,517.4648 L 726.14948,524.5781 L 729.11336,532.87695 L 724.96393,537.61915 z "
                            id="path2195" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 620.63552,399.50256 L 620.63552,407.20864 L 641.38264,408.39419 L 653.23814,427.95576 L 656.7948,452.25954 L 684.65522,436.84739 L 712.51565,441.58959 L 699.4746,428.54854 L 703.62403,414.91471 L 704.2168,382.90486 L 669.83585,356.22998 L 657.38757,346.74558 L 641.97542,365.71438 L 634.86212,362.7505 L 634.86212,373.42046 L 613.52221,378.16266 L 622.41384,391.79648 L 620.63552,399.50256 z "
                            id="path2197" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 657.38757,452.85232 L 641.97542,497.31045 L 626.56327,499.68155 L 625.97049,455.22342 L 612.33666,455.22342 L 594.55341,438.03294 L 586.84734,438.03294 L 586.84734,430.32686 L 569.65686,416.69304 L 559.57968,403.05921 L 568.47131,402.46644 L 578.54848,389.42538 L 599.88839,407.20864 L 606.40891,389.42538 L 621.22829,398.90978 L 621.22829,406.61586 L 641.38264,407.80141 L 652.64537,426.77021 L 657.38757,452.85232 z "
                            id="path2199" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 626.56327,499.08877 L 639.60432,515.68648 L 659.1659,516.87203 L 653.23814,533.46973 L 667.46475,540.58303 L 682.8769,540.58303 L 684.06245,528.13475 L 695.91795,519.8359 L 687.02632,505.01652 L 698.28905,505.6093 L 705.40235,510.94428 L 713.10843,504.42375 L 722.59283,493.16102 L 722.59283,464.70782 L 715.47953,467.07892 L 712.51565,442.77514 L 685.248,436.84739 L 657.38757,451.66677 L 641.97542,497.90322 L 626.56327,499.08877 z "
                            id="path2201" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 722.59283,593.93279 L 717.85063,604.00996 L 708.959,603.41719 L 701.25293,585.04116 L 679.91302,584.44839 L 667.46475,591.56169 L 658.57312,585.63394 L 675.17082,572.59288 L 683.46967,572.59288 L 682.28412,563.10848 L 670.42862,563.10848 L 667.46475,539.99025 L 682.8769,540.58303 L 683.46967,528.13475 L 695.91795,520.42868 L 687.6191,504.42375 L 700.06738,505.6093 L 705.40235,511.53705 L 714.29398,503.83097 L 726.14948,505.01652 L 723.18561,514.50093 L 711.3301,518.65035 L 724.96393,537.02638 L 739.19053,569.62901 L 735.04111,574.96399 L 739.78331,583.85561 L 733.26278,586.22671 L 722.59283,593.93279 z "
                            id="path2203" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 658.57312,585.63394 L 648.49594,594.52556 L 634.26934,600.45331 L 615.89331,600.45331 L 602.25949,621.79322 L 589.81121,597.48944 L 593.36786,590.37614 L 565.50743,589.78336 L 551.87361,569.03623 L 538.23978,569.03623 L 534.09035,581.48451 L 542.3892,592.15446 L 534.68313,599.26776 L 525.7915,589.19059 L 517.49265,594.52556 L 500.30217,589.78336 L 487.26112,578.52064 L 475.40562,578.52064 L 482.51892,560.14461 L 468.88509,541.17581 L 490.225,542.36136 L 494.37442,528.72753 L 502.67327,522.207 L 518.08543,523.39255 L 535.86868,512.7226 L 565.50743,544.13968 L 592.18231,515.68648 L 580.91958,507.38763 L 583.88346,490.19715 L 612.92944,477.74887 L 626.56327,477.74887 L 626.56327,499.08877 L 639.60432,515.68648 L 658.57312,516.87203 L 652.64537,534.0625 L 666.87197,539.99025 L 670.42862,563.10848 L 682.28412,563.10848 L 682.8769,572.59288 L 676.35637,572.59288 L 658.57312,585.63394 z "
                            id="path2205" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 568.97656,416.72486 L 550.84321,414.32989 L 539.2105,429.04185 L 539.89477,439.30601 L 532.02559,455.04439 L 543.6583,456.07081 L 538.52622,465.65069 L 518.34004,465.65069 L 521.41929,481.04693 L 507.3916,491.65323 L 512.86582,509.44444 L 523.47212,519.70859 L 536.47339,512.52368 L 565.89731,544.00044 L 591.89985,515.94507 L 580.95141,507.3916 L 584.03066,490.96895 L 612.42817,477.2834 L 627.14013,477.62554 L 625.77157,455.38653 L 612.42817,455.04439 L 593.95268,437.93746 L 587.10991,438.2796 L 586.76777,430.06827 L 568.97656,416.72486 z "
                            id="path2207" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 568.12121,110.33971 L 558.88347,96.483097 L 514.23438,107.77367 L 505.50984,94.943473 L 473.69095,88.271769 L 464.96641,69.796283 L 446.49093,72.87553 L 435.71356,50.29438 L 425.4494,64.150995 L 408.00033,41.056637 L 385.93239,33.358517 L 370.53615,18.988695 L 361.2984,32.332102 L 342.82292,31.818894 L 352.57387,48.754756 L 331.53234,61.071747 L 331.01913,77.494402 L 320.75498,86.732145 L 324.86064,99.049136 L 348.98141,104.18122 L 358.73236,119.57745 L 373.6154,126.76237 L 387.47201,107.26046 L 396.19655,115.47179 L 420.31732,91.864225 L 433.14752,100.07555 L 450.08338,139.07936 L 460.34754,128.30199 L 481.38907,142.67181 L 482.92869,165.76617 L 498.83814,184.24166 L 510.14065,197.63281 L 532.98437,181.42498 L 523.47212,169.35863 L 568.63442,124.70953 L 568.12121,110.33971 z "
                            id="path2209" transform="translate(-242.7361,2.7315674)"
                            sodipodi:nodetypes="cccccccccccccccccccccccccccccccccccc" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 395.93397,114.96256 L 400.95068,126.15368 L 389.37366,126.53958 L 387.44416,147.37821 L 397.86348,156.63982 L 385.51466,164.74373 L 394.39037,175.93485 L 405.58149,177.09255 L 420.24571,192.91447 L 434.90993,234.59173 L 456.52036,246.55465 L 466.16788,227.25962 L 474.27179,229.18913 L 481.6039,229.18913 L 501.28483,202.56199 L 492.40911,190.98497 L 498.96942,184.42466 L 483.1475,165.12963 L 480.8321,142.7474 L 460.37937,128.08318 L 450.34595,139.2743 L 433.36633,99.91244 L 419.85981,91.808528 L 395.93397,114.96256 z "
                            id="path2212" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 487.81013,344.76833 L 452.90006,380.60935 L 456.15833,393.64244 L 445.45257,396.90071 L 433.35041,386.19496 L 409.1461,380.14388 L 413.80077,413.65755 L 396.113,429.48345 L 389.59645,411.33021 L 342.58422,411.79568 L 343.04968,421.10503 L 333.27486,421.10503 L 334.67127,436 L 321.1727,435.06906 L 312.79428,442.05108 L 300.69212,426.69065 L 305.81227,415.51942 L 287.19356,416.45036 L 275.55687,431.81079 L 265.31658,429.48345 L 265.31658,441.58561 L 255.07629,441.58561 L 253.67989,432.27626 L 244.836,431.34532 L 241.11226,442.05108 L 262.52377,469.97914 L 259.2655,487.66691 L 263.92018,495.1144 L 276.02234,482.0813 L 284.40076,489.99425 L 300.22666,489.52878 L 304.88133,480.21943 L 323.03457,489.99425 L 326.29285,478.35756 L 367.254,473.23741 L 386.33818,451.8259 L 414.26624,466.2554 L 426.83387,479.28849 L 440.33243,479.28849 L 441.72883,489.06332 L 431.02308,509.07843 L 427.7648,521.64605 L 449.17632,514.66404 L 459.41661,522.57699 L 486.41373,519.78418 L 497.58496,511.87123 L 503.17057,522.11152 L 518.06553,523.50792 L 524.11661,519.78418 L 512.47992,509.54389 L 507.82524,492.32159 L 521.32381,481.15037 L 518.06553,465.78993 L 538.08064,465.78993 L 543.66626,456.01511 L 531.5641,455.08418 L 539.94251,438.79281 L 538.54611,428.55252 L 551.11374,413.65755 L 569.73245,416.45036 L 559.49216,402.95179 L 548.32093,402.48633 L 541.33892,372.23093 L 522.72021,355.47409 L 511.08352,355.47409 L 501.3087,365.24891 L 494.32668,351.28488 L 487.81013,344.76833 z "
                            id="path2214" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 240.7184,442.11076 L 234.77474,442.11076 L 226.51965,423.61936 L 231.1425,415.03406 L 224.86863,398.19368 L 247.98288,390.9292 L 282.32405,391.58961 L 282.32405,401.16551 L 291.89996,401.16551 L 303.12688,385.97615 L 311.05176,405.12796 L 319.63706,412.06223 L 327.89215,400.50511 L 342.75131,412.06223 L 342.75131,420.64752 L 333.1754,420.64752 L 334.82642,435.83689 L 322.27869,434.84628 L 313.03299,442.11076 L 300.81545,426.92139 L 306.09871,415.69447 L 286.6167,416.02467 L 275.71998,432.20465 L 265.48367,429.56302 L 265.15347,441.78055 L 255.24736,441.12014 L 253.59634,432.86506 L 245.01105,431.21404 L 240.7184,442.11076 z "
                            id="path2218" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 224.86863,398.52388 L 187.88583,404.79775 L 188.87644,394.56144 L 196.14092,384.65533 L 166.4226,369.13577 L 177.64952,361.87129 L 184.5838,353.28599 L 193.16909,353.28599 L 189.20665,340.40806 L 201.42418,335.1248 L 227.18006,334.79459 L 233.45393,343.37989 L 255.90777,340.07785 L 295.20199,368.47536 L 325.25052,363.52231 L 336.14724,374.74923 L 318.31624,392.58022 L 311.71217,405.45816 L 302.79668,385.97615 L 291.56975,401.49572 L 281.99385,400.83531 L 282.65426,391.58961 L 247.65268,390.9292 L 224.86863,398.52388 z "
                            id="path2220" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 427.95179,521.05328 L 423.26528,534.86613 L 414.87891,528.94634 L 398.59947,529.193 L 385.77325,537.33271 L 378.12685,533.38618 L 366.0406,535.11279 L 365.54729,548.43233 L 356.42094,539.05932 L 330.02852,539.30598 L 317.44896,504.28053 L 332.98842,494.66086 L 323.61541,489.7277 L 326.32865,478.13477 L 367.52055,473.44827 L 386.51323,451.74236 L 414.13893,466.54184 L 426.96515,479.61472 L 440.28469,479.1214 L 441.76464,488.49441 L 431.15834,509.46035 L 427.95179,521.05328 z "
                            id="path2223" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 461.7519,300.44547 L 440.38415,309.82643 L 416.93174,276.47189 L 352.30732,269.69675 L 333.02423,247.80783 L 324.68559,266.56976 L 302.27551,253.01948 L 256.93418,273.3449 L 260.58234,216.01679 L 283.51358,214.97446 L 277.78077,177.97177 L 290.80989,177.97177 L 288.20406,151.91353 L 317.38929,125.33414 L 317.38929,115.43201 L 324.68559,99.275903 L 349.18033,104.48755 L 358.5613,119.60132 L 373.67507,126.89763 L 387.74652,106.57221 L 396.60632,115.43201 L 400.77563,126.37647 L 388.78885,126.37647 L 387.74652,147.74422 L 397.64865,157.12518 L 385.14069,164.94265 L 394.52166,175.88711 L 405.46612,176.92944 L 420.05873,193.08554 L 434.65134,234.77872 L 456.01909,246.24434 L 465.92122,227.48241 L 473.73869,228.52474 L 469.56937,248.329 L 475.82335,256.66763 L 457.58258,286.89518 L 461.7519,300.44547 z "
                            id="path2225" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 227.2278,334.84233 L 237.65109,299.40314 L 256.93418,272.82374 L 302.79668,252.49832 L 324.68559,266.56976 L 333.02423,246.76551 L 352.82848,269.69675 L 416.93174,275.95073 L 440.38415,309.82643 L 462.27307,299.9243 L 473.21752,308.7841 L 487.28897,344.74446 L 452.8921,380.18366 L 456.54025,393.73394 L 445.5958,397.3821 L 433.08784,385.91647 L 409.11427,379.6625 L 413.80475,414.58053 L 396.08515,429.17314 L 389.31001,411.45354 L 342.40519,411.45354 L 328.33374,400.50908 L 319.47394,412.49587 L 311.65647,405.19957 L 317.91045,392.69161 L 336.15121,374.97201 L 325.20676,364.02756 L 295.50037,368.19687 L 255.8757,340.16306 L 233.59085,343.33449 L 227.2278,334.84233 z "
                            id="path2227" transform="translate(-242.7361,2.7315674)"
                            sodipodi:nodetypes="ccccccccccccccccccccccccccccccc" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 707.63023,725.18274 L 660.93626,724.21998 L 671.52665,735.77312 L 691.26327,741.5497 L 663.82455,754.54699 L 653.71555,767.54428 L 644.56931,767.54428 L 633.01616,781.02295 L 601.24501,782.94847 L 597.39396,796.42714 L 602.68915,804.12924 L 609.90987,796.90853 L 616.6492,803.16648 L 604.61467,818.57068 L 601.24501,837.82592 L 608.46572,856.1184 L 588.7291,877.29917 L 564.66004,887.40817 L 555.99518,900.40546 L 560.80899,928.80695 L 577.17595,930.25109 L 574.76905,949.02496 L 579.10148,955.28291 L 541.07237,966.83606 L 561.77176,980.79611 L 566.58557,999.08859 L 591.61738,967.79882 L 603.65191,968.2802 L 604.61467,989.46097 L 627.72097,988.97959 L 629.64649,1011.6045 L 640.23688,1011.1231 L 659.49212,1037.1177 L 678.26598,1018.3438 L 699.44675,1018.3438 L 698.96537,999.08859 L 712.44404,982.24025 L 706.18609,974.53816 L 720.62752,946.61805 L 748.54763,949.50634 L 743.25243,919.17933 L 734.58757,906.66342 L 738.43862,862.85774 L 715.81371,856.59978 L 704.26056,865.74602 L 696.07709,857.56255 L 692.22604,839.75144 L 710.51852,820.4962 L 705.70471,788.72505 L 720.62752,753.58423 L 707.63023,725.18274 z "
                            id="path2229" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 516.80042,593.78161 L 517.82683,614.30993 L 486.00794,616.36276 L 454.70225,650.23449 L 451.62301,670.7628 L 464.96641,675.89488 L 486.52115,696.93641 L 499.86455,696.4232 L 512.69475,717.46473 L 507.56267,764.67986 L 520.39287,756.46853 L 523.98533,769.29873 L 531.68345,769.29873 L 537.84194,759.54778 L 563.50234,778.02327 L 594.29482,768.27232 L 594.29482,781.10252 L 601.47973,782.64214 L 633.29862,781.10252 L 644.5892,767.2459 L 653.82694,767.2459 L 663.57789,754.92891 L 691.80433,741.07229 L 671.27601,735.42701 L 661.01185,724.13643 L 689.23829,724.64964 L 692.31754,694.88358 L 689.23829,677.94772 L 706.17415,680.00055 L 716.43831,653.82694 L 728.2421,661.01185 L 753.90249,646.64203 L 754.4157,638.4307 L 744.66475,634.32504 L 745.17796,619.44201 L 739.01946,610.71747 L 743.63833,601.99294 L 728.2421,599.94011 L 723.11002,593.78161 L 717.97794,604.55898 L 708.22699,603.01935 L 701.04207,585.05707 L 680.00055,584.03066 L 667.68356,591.21557 L 658.95902,585.57028 L 647.66845,594.80803 L 633.81183,600.96652 L 614.82314,600.96652 L 601.99294,622.00805 L 589.16274,597.37407 L 593.78161,589.67595 L 565.55517,590.18915 L 551.18535,569.14763 L 538.86836,569.14763 L 534.76269,581.46462 L 541.94761,592.24199 L 534.24949,599.4269 L 525.52495,589.16274 L 516.80042,593.78161 z "
                            id="path2231" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 451.1456,670.49625 L 424.96802,679.40777 L 418.28438,693.88899 L 424.96802,709.48415 L 414.38559,716.16778 L 414.38559,731.20597 L 401.01831,741.7884 L 399.3474,754.04174 L 415.49953,795.25751 L 415.49953,813.63751 L 428.30984,825.33388 L 427.1959,848.72662 L 438.3353,866.54965 L 460.61409,858.75207 L 474.53834,881.03087 L 478.43713,869.3345 L 488.46258,869.3345 L 494.03228,898.85391 L 555.29897,899.96784 L 565.8814,886.60057 L 588.71716,877.68905 L 608.76808,855.41025 L 601.52747,837.58722 L 604.86929,818.65024 L 617.12263,803.05509 L 610.99596,797.48539 L 603.19838,804.16903 L 597.62868,796.37145 L 601.52747,783.00417 L 593.72989,780.77629 L 594.28686,767.40901 L 563.65352,777.99144 L 536.91896,759.05447 L 532.4632,770.19386 L 523.55169,769.07992 L 520.20987,755.71265 L 507.39956,764.06719 L 512.96926,717.83869 L 499.60198,696.67384 L 486.2347,696.67384 L 465.06985,676.06595 L 451.1456,670.49625 z "
                            id="path2235" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 86.47288,541.82272 L 86.97018,584.09275 L 101.39172,599.01159 L 118.29973,628.35197 L 118.29973,648.74104 L 134.21316,665.15176 L 135.20775,671.61659 L 144.15905,681.56248 L 144.15905,691.01108 L 132.22398,686.03813 L 125.75915,695.48673 L 131.72669,722.34063 L 134.21316,751.68101 L 148.1374,752.6756 L 156.09412,756.15666 L 157.586,744.22159 L 172.00754,733.28111 L 172.00754,718.36228 L 181.95343,711.89745 L 175.9859,697.47591 L 182.94802,682.05978 L 208.80734,673.60577 L 211.79111,651.72481 L 243.61796,618.40607 L 274.94751,616.4169 L 274.45022,597.02241 L 258.0395,592.54676 L 244.61254,581.10898 L 232.67748,580.61169 L 239.6396,562.70909 L 226.21265,543.81189 L 247.09902,545.30378 L 252.07196,531.37953 L 261.02326,523.92011 L 255.05573,514.47152 L 244.11525,521.93094 L 216.26676,524.9147 L 206.81816,517.45528 L 184.9372,524.9147 L 180.46155,538.34165 L 173.00213,531.37953 L 156.09412,531.87683 L 142.16987,540.33083 L 135.70504,535.35789 L 123.27268,537.34707 L 122.27809,551.27131 L 113.82408,541.82272 L 86.47288,541.82272 z "
                            id="path2237" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 237.60335,299.8328 C 237.43626,299.8328 237.26917,299.8328 237.60335,299.8328 z "
                            id="path2239" transform="translate(-242.7361,2.7315674)" />
                        <path
                            style="fill:{{mapCol}};fill-rule:evenodd;stroke:{{mapBor}};stroke-width:0.2;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none"
                            d="M 237.60335,299.33153 L 229.08171,291.81243 L 216.54989,298.32898 L 192.99006,299.33153 L 183.46588,280.78443 L 171.9366,280.78443 L 144.36659,300.83535 L 128.82713,298.83025 L 129.82968,307.35189 L 139.35386,320.88626 L 127.32331,331.41299 L 134.34113,371.51482 L 134.84241,407.1052 L 148.87805,408.60902 L 150.88314,433.17139 L 172.43787,456.73121 L 172.93915,467.25795 L 190.4837,468.76176 L 228.07917,520.89414 L 237.60335,542.95015 L 247.12754,542.44888 L 249.6339,553.97816 L 241.61353,566.00871 L 267.67972,596.08508 L 290.73828,590.57108 L 300.26246,579.0418 L 309.78665,589.06726 L 329.33629,582.04944 L 329.33629,538.4387 L 317.80701,504.35214 L 333.34647,494.82795 L 323.32102,489.31395 L 305.27519,479.78977 L 299.76119,489.81523 L 284.723,489.81523 L 276.20136,481.79486 L 264.17081,494.82795 L 258.65681,487.81013 L 262.667,469.26304 L 241.11226,442.1943 L 235.09699,442.1943 L 226.57535,422.64466 L 232.08935,414.12302 L 225.07153,398.58356 L 187.47606,404.59883 L 188.97988,394.57338 L 195.9977,384.54792 L 166.4226,369.00846 L 177.95188,361.99064 L 185.47097,352.46645 L 193.49134,352.96773 L 188.97988,340.4359 L 202.51425,334.9219 L 227.57789,334.42063 L 237.60335,299.33153 z "
                            id="path2241" transform="translate(-242.7361,2.7315674)" />
                    </g>
                </svg>
            </div>

        </div>
    </div>