import { Component, Input } from '@angular/core';
import { lineDataset, lineDS } from '../../models/graphs.model';
import { Chart } from 'chart.js';
import { durangoCommon } from '../../../shared/common';

@Component({
  selector: 'competitivo-pib-anual',
  templateUrl: './pib-anual.component.html',
  styleUrl: './pib-anual.component.css'
})
export class CompetitivoPibAnualComponent {

  @Input({ required: true }) ds?: string;

  public pibJSON: string = '{}';
  private lineData?: lineDataset;

  constructor() {
  }

  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.lineData = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }
    this.pibJSON = this.getPIBDS();
  }

  getPIBDS() {
    let comun = new durangoCommon;
    let colores = comun.getStdColors();
    this.lineData!.aspect = false;
    this.lineData!.legend = false;
    this.lineData!.cid = "chtPIB";
    let i = 0;
    for (let index = 0; index < this.lineData!.dataset.length; index++) {
      this.lineData!.dataset[index].color = colores[i];
      i = (i < colores.length-1) ? i+1 : 0;
    }
    return JSON.stringify(this.lineData);
  }


}
