<div class="container" *ngIf="dataCard">
    <p class="title">
        {{dataCard.title}}
        <button type="button" class="btn btn-white p-0" data-bs-toggle="tooltip" data-bs-placement="top"
            data-bs-html="true" title="{{dataCard.titleTooltip}}" *ngIf="dataCard.titleTooltip">
            <i class="bi bi-info-circle"></i>
        </button>
    </p>
    <p>{{ dataCard.period }}</p>
    <div class="card card-min"  [ngStyle]="{'height':  min_h}">
        <div class="card-body d-flex align-items-center">
            <div class="content">
                <h1>{{dataCard.value}}</h1>
                <p class="descripcion">{{dataCard.description}}</p>
            </div>
        </div>
    </div>
</div>