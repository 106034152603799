import { Component } from '@angular/core';
import { empleosGeneradosanioSubData, empleosGeneradosDetalle, empleosGeneradosMes, empleosGeneradosMesData, empleosGeneradosMesSubData, empleosMunicipio, generadorEmpleo, generadoresEmpleo, impulsoresSector, impulsoSector, inversionesObj, inversionSectorModel } from '../models/empleosGenerados.model';

@Component({
  selector: 'app-empleos-generados',
  templateUrl: './empleos-generados.component.html',
  styleUrl: './empleos-generados.component.css'
})

export class EmpleosGeneradosComponent {

  // EmpleosTotales
  public empleosData: string;
  public empleosNuevosData: string;
  public comparativaData: string;
  public montoformat: string = '--';
  public productividad: string = '--';
  public invTotal: string = '--';
  public inversiones: inversionSectorModel[] = [];
  public i: number = 0;
  public invTitle: string = '--';
  public invInver: string = '--';
  public periodoGeneradores: string = '--';
  public periodoImpulso: string = '--';
  public generadores: generadorEmpleo[] = [];
  public maxGeneradores: number = 0;
  public impulso: impulsoSector[] = [];
  public maxImpulso: number = 0;
  private meses = [];
  private emplObj: empleosGeneradosanioSubData[] = [];
  public emplObjDet: empleosGeneradosDetalle[] = [];
  private genTot: number = 0;
  private perTot: number = 0;
  private empTot: number = 0;
  public ultMes!: empleosGeneradosMesSubData;
  public ultAnio: number = 2000;
  private ObjMunicipios: empleosMunicipio[] = [];


  constructor() {
    this.meses = require('../../assets/json/meses.json');
    this.getEmploymentData();
    this.getEmploymentDetailData();
    this.empleosData = this.getEmpleosDS();
    this.empleosNuevosData = this.getEmpleosNuevosDS();
    this.comparativaData = this.getComparativaData();
    this.getStadistics();
    this.setInversiones();
    this.getEmpleosNuevosDS();
  }

  ngOnInit() {
    this.generateTops();
  }

  //#region Consumo JSON

  // Datos totales de empleo por año
  getEmploymentData() {
    const wsResponse = require('../../assets/json/empleoEstado.json');
    let anteriores: number = 0;
    let esteAnio: number = 2000;
    let nuevos: number = 0;
    let perdidos: number = 0;
    wsResponse.forEach((anio: any) => {
      esteAnio = parseInt(Object.keys(anio)[0]);
      let anioNvo: empleosGeneradosanioSubData = {
        anio: esteAnio,
        empleos: []
      }
      for (const mes of anio[esteAnio]) {
        let alpha: number = 0;
        let mesid = parseInt(Object.keys(mes)[0])
        let estemes = mes[mesid];
        if (anteriores == 0) {
          anteriores = parseInt(estemes)
        }
        alpha = parseInt(estemes) - anteriores;
        if (alpha > 0) {
          nuevos += alpha
        } else {
          perdidos += Math.abs(alpha);
        }
        anteriores = parseInt(estemes)

        let mesNvo: empleosGeneradosMesSubData = {
          mesId: mesid,
          mes: this.meses[mesid - 1],
          empleos: estemes,
          diferencia: alpha,
        }
        anioNvo.empleos.push(mesNvo);
        this.ultMes = mesNvo;
      };
      this.emplObj.push(anioNvo);
    });
    this.perTot = perdidos;
    this.genTot = nuevos;
    this.empTot = this.ultMes.empleos;
  }

  //Datos de empleo por municipio por mes
  getEmploymentDetailData() {
    const wsResponse = require('../../assets/json/empleosMesMunicipio.json');
    let anteriores: number = 0;
    let esteAnio: number = 2000;
    let nuevos: number = 0;
    let perdidos: number = 0;
    let anioant = 0;
    wsResponse.forEach((anio: any) => {
      esteAnio = parseInt(Object.keys(anio)[0]);
      this.ultAnio = esteAnio;
      let anioNvo: empleosGeneradosDetalle = {
        anio: esteAnio,
        meses: []
      }
      let lastsMun: empleosMunicipio[] = [];
      for (const mes of anio[esteAnio]) {
        let alpha: number = 0;
        let mesid = parseInt(Object.keys(mes)[0])
        let ultMunEmp = 0
        let mesNvo: empleosGeneradosMes = {
          mesId: mesid,
          mes: this.meses[mesid - 1],
          empleos: 0,
          municipios: []
        }

        let munAnt = 0;
        for (const municipio of mes[mesid]) {
          let munname = Object.keys(municipio)[0]
          let estemun = municipio[munname];

          if (munAnt == 0) {
            munAnt = estemun
          }

          //
          let alpha = 0;
          let existe = lastsMun.find(x => x.municipio === munname);
          if(existe){
            alpha = estemun - existe.empleos;
            existe.empleos = estemun;
          }else{
            let nvo: empleosMunicipio = {
              municipio: munname,
              empleos: estemun
            };
            lastsMun.push(nvo);
          }

          let munNvo: empleosGeneradosMesData = {
            municipio: munname,
            empleos: estemun,
            diferencia: alpha
          }
          mesNvo.municipios.push(munNvo);
          ultMunEmp += estemun;
          this.addMunicipioEmpleo(munNvo.municipio, munNvo.diferencia ? munNvo.diferencia : 0);
        }

        if (anteriores == 0) {
          anteriores = ultMunEmp;
        }
        mesNvo.empleos = ultMunEmp;
        alpha = mesNvo.empleos - anteriores;
        if (alpha > 0) {
          nuevos += alpha
        } else {
          perdidos += Math.abs(alpha);
        }
        anteriores = ultMunEmp;
        mesNvo.diferencia = alpha;

        anioNvo.meses.push(mesNvo);
        this.ultMes = mesNvo;
      };
      if (anioant == 0)
        anioant = this.ultMes.empleos;
      anioNvo.diferencia = this.ultMes.empleos - anioant;
      anioNvo.empleos = this.ultMes.empleos;
      anioant = this.ultMes.empleos;
      this.emplObjDet.push(anioNvo);
    });
  }

  addMunicipioEmpleo(municipio: string, alpha: number){
    let existe = this.ObjMunicipios.find(x => x.municipio === municipio);
    if(existe){
      existe.empleos += alpha;
    }else{
      let nvo: empleosMunicipio = {
        municipio: municipio,
        empleos: alpha
      };
      this.ObjMunicipios.push(nvo);
    }
  }

  //#endregion


  //#region Generar DS para gráficos
  getEmpleosDS() {
    return `[
    {
      "name": "Perdidos",
      "data": ` + this.perTot + `,
      "color": "rgb(255,195,0)"
    },
    {
      "name": "Foraneos",
      "data": 86000,
      "color": "rgb(45,47,239)"
    },
    {
      "name": "Totales",
      "data": ` + this.ultMes.empleos + `,
      "color": "rgb(181,23,158)"
    }
  ]`;
  }

  getEmpleosNuevosDS() {
    return `[
    {
      "name": "Nuevos",
      "data": ` + this.genTot.toString() + `,
      "color": "rgb(225,56,80)"
    },
    {
      "name": "Perdidos",
      "data": ` + this.perTot.toString() + `,
      "color": "rgb(238,238,238)"
    }
  ]`;
  }

  getComparativaData() {
    return `{
      "totales": ` + (this.genTot + this.perTot) + `,
      "generados": ` + this.genTot + `,
      "perdidos": ` + this.perTot + ` 
    }`;
  }

  getStadistics() {
    let top: number = 3;
    /*
      Por aquí debería estar el consumo de las estadísticas de minería...
    */
    //Top 3 generadores de empleo
    //let ordeEmpleo = this.emplObjDet[this.emplObjDet.length - 1].meses[this.emplObjDet[this.emplObjDet.length - 1].meses.length - 1].municipios;
    let ordeEmpleo = this.ObjMunicipios;
    ordeEmpleo = ordeEmpleo.sort((a,b) => b.empleos! - a.empleos! );
    ordeEmpleo.splice(top, 200);
    let maxempleos = ordeEmpleo.reduce((a, {empleos}) => a + empleos, 0);
    //
    let estadisticas: inversionesObj = {
      financiamientoTotal: 1670893748,
      productividad: 200,
      inversionEmpleo: 335493500,
      inversionesRealizadas: 67,
      inversionesSector: [
        { inversion: 300000, sector: "Inversión Ciencia y Tecnología" },
        { inversion: 187450, sector: "Servicios Socials y Comunales" },
        { inversion: 1760390, sector: "Industrias Extractivas" }
      ],
      generadoresEmpleo: {
        periodo: 'Diciembre 2022 a ' + this.meses[this.ultMes.mesId - 1] + ' ' + this.ultAnio.toString(),
        empleosGenerados: maxempleos,
        generadores: [
          { inversion: ordeEmpleo[0].empleos, municipio: ordeEmpleo[0].municipio, color: 'rgb(181,23,158)' },
          { inversion: ordeEmpleo[1].empleos, municipio: ordeEmpleo[1].municipio, color: 'rgb(13,63,76)' },
          { inversion: ordeEmpleo[2].empleos, municipio: ordeEmpleo[2].municipio, color: 'rgb(1,58,143)' }
        ]
      },
      impulsoresSector: {
        // Cambiar cuando se tengan datos de impulso
        periodo: 'Diciembre 2022 a ' + this.meses[this.ultMes.mesId - 1] + ' ' + this.ultAnio.toString(),
        impulso: 15000000,
        sectores: [
          { inversion: 10000000, sector: 'Pymes Agropecuarias', color: 'rgb(6,119,3)' },
          { inversion: 7500000, sector: 'Producción Ganadera', color: 'rgb(20,163,77)' },
          { inversion: 6200000, sector: 'Telecomunicaciones', color: 'rgb(20,188,87)' }
        ]
      }
    }
    this.montoformat = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(estadisticas.financiamientoTotal,);
    this.productividad = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(estadisticas.productividad,);
    this.invTotal = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(estadisticas.inversionEmpleo,);
    this.inversiones = estadisticas.inversionesSector;
    this.periodoGeneradores = estadisticas.generadoresEmpleo.periodo;
    this.periodoImpulso = estadisticas.impulsoresSector.periodo;
    this.generadores = estadisticas.generadoresEmpleo.generadores;
    this.maxGeneradores = estadisticas.generadoresEmpleo.empleosGenerados;
    this.impulso = estadisticas.impulsoresSector.sectores;
    this.maxImpulso = estadisticas.impulsoresSector.impulso;
  }

  //#endregion


  //#region Cambio de gráfica
  changeInversion(atras: boolean) {
    let invs = this.inversiones.length;
    if (atras) {
      if (this.i == 0) {
        this.i = invs - 1;
      } else {
        this.i -= 1;
      }
    } else {
      if (this.i == invs - 1) {
        this.i = 0;
      } else {
        this.i += 1;
      }
    }
    this.setInversiones();
  }

  setInversiones() {
    this.invTitle = this.inversiones[this.i].sector;
    this.invInver = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(this.inversiones[this.i].inversion,);
  }
  //#endregion


  //#region Recursos gráficos que no son componentes

  generateTops() {
    let div = document.getElementById('generadoresDiv');
    this.generadores.forEach(ele => {
      if (div) {
        let prog = this.generateProgressComponent(ele.municipio, ele.inversion, this.maxGeneradores, ele.color);
        div.innerHTML += prog;
      }
    });
    div = document.getElementById('impulsoDiv');
    this.impulso.forEach(ele => {
      if (div) {
        let prog = this.generateProgressComponent(ele.sector, ele.inversion, this.maxImpulso, ele.color, true);
        div.innerHTML += prog;
      }
    });
  }

  generateProgressComponent(title: string, now: number, max: number, color: string = 'rgb(20,163,77)', impulso: boolean = false) {
    let nowString = Intl.NumberFormat('es-MX', { style: 'decimal', maximumFractionDigits: 0 }).format(now,)
    let moneda = impulso ? '(MXN)' : '';
    let width = (now / max) * 100;
    let html = `
                <div class="container">
                    <div class="text-end text-primary">
                        <b>` + title + `</b>
                    </div>
                    <span class="h2">` + nowString + `</span>` + moneda + `
                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="` + now + `"
                        aria-valuemin="0" aria-valuemax="` + max + `" style="--bs-progress-bar-bg:` + color + ` !important;">
                        <div class="progress-bar" style="width: ` + width.toString() + `%"></div>
                    </div>
                </div><br>`;


    return html;
  }

  //#endregion


}
