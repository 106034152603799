<div class="row d-flex align-items-end">
    <div class="col-xl-4 h9">
        <app-card-stack [ds]=pymesEmplDS></app-card-stack>
    </div>
    <div class="col-md-4 col-sm-12 d-flex align-items-end">
        <div class="d-flex flex-column justify-content-between">
            <div class="container">
                <p class="title">Número de micro, pequeñas y medianas empresas</p>
                <div class="card card-min">
                    <div class="d-flex card-body">
                        <graph-circ class="content-graph" [ds]="dataEmpresasGraph"></graph-circ>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-card-label [data]='datosTasaCrecAnual' [min_h]="'15rem'"></app-card-label>
    </div>
</div>

<div class="row">
    <div class="col-md-12">
        <p class="title">Financiamiento a Pymes en millones de pesos</p>
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <graph-bar class="content-graph" [ds]="datosFinanciamiento"></graph-bar>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <p class="title">Exportaciones por Pymes*</p>
        <div class="container">
            <div class="card">
                <div class="card-body h-24">
                    <graph-bar class="content-graph" [ds]="datosExportaciones"></graph-bar>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <p class="title">Monto de inversión</p>
        <div class="row">
            <!-- <div class="col-md-4 col-sm-12"> -->
            <app-card-label [data]='datosCreditosOtorgados'></app-card-label>
            <!-- </div>
            <div class="col-md-4 col-sm-12"> -->
            <app-card-label [data]='datosMontoInversion'></app-card-label>
            <!-- </div> -->
        </div>

    </div>
</div>

<div class="row d-flex align-items-end">
    <div class="col-md-4 col-sm-12">
        <app-card-label [data]='datosAdopTecDig'></app-card-label>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-card-label [data]='datosInversionID'></app-card-label>
    </div>
    <div class="col-md-4 col-sm-12">
        <app-card-label [data]='datosCompetitividad'></app-card-label>
    </div>
</div>

<div class="row">
    <p class="title">{{datosResponsabilidadSocial.tituloCard}}</p>
    <div class="container">
        <div class="row">
            @for (item of datosResponsabilidadSocial.datos; track $index) {
            <div class="col-md-4 col-ms-12">
                <p class="title"> {{ item.titulo}} </p>
                <app-card-carrousel [data]="item.informacion"></app-card-carrousel>
            </div>
            }
        </div>
    </div>
</div>

<div class="row">
    <p class="title">Colaboración y Redes de apoyo</p>
    <div class="row">
        @for (item of datosRedesApoyo; track $index) {
        <div class="col-md-6 col-sm-12 mt-4">
            <div class="content-red">
                <div class="circulo"> {{$index + 1}} </div>
                <div class="descripcion">
                    <p class="subtitle"> {{ item }}
                    </p>
                </div>
            </div>

        </div>
        }
    </div>
</div>