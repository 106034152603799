<div class="container">
    <div class="my-5">
        <h1>
            Empleos Generados
        </h1>
        <p>
            <b>Durango impulsa el crecimiento laboral:</b>
            Conoce las oportunidades de empleo generadas a lo
            largo del estado, desglosadas por sector y municipio.
            Fomenta la inclusión y la igualdad de oportunidades
            para todos los duranguenses.
        </p>
    </div>

    <div class="row p-0">
        <div class="col-xl-5 p-1">
            <empleos-totales [ds]=empleosData [ultAnio]=ultAnio.toString() [ultMes]=ultMes.mes></empleos-totales>
        </div>
        <div class="col-xl-3 p-1 ">
            <empleos-nuevos [ds]=empleosNuevosData [ultAnio]=ultAnio.toString() [ultMes]=ultMes.mes></empleos-nuevos>
        </div>
        <div class="col-xl-4 p-1">
            <empleos-comparativa [ds]=comparativaData></empleos-comparativa>
        </div>
    </div>

    <div class="p-4"></div>

    <div class="row p-0">
        <div class="col-xl-4 p-1">
            <h3>
                Monto de Financiamiento
            </h3>
            <div class="graphContainer border rounded p-3 h9 bg-primary text-center text-white d-flex">
                <div class="m-auto">
                    <h2>{{montoformat}}</h2>
                    <h4>USD dolar</h4>
                </div>
            </div>
        </div>
        <div class="col-xl-2 p-1 ">
            <h3>
                Productividad
            </h3>
            <div class="graphContainer border rounded p-3 h9 d-flex">
                <div class="text-center m-auto">
                    <img src="../../../../assets/clock.gif" width="100">
                    <h2>{{productividad}}</h2>
                    <small>MXN / Hora trabajada</small>
                </div>
            </div>
        </div>
        <div class="col-xl-6 p-1">
            <empleos-historico [ds]=emplObjDet></empleos-historico>
        </div>
    </div>

    <div class="p-4"></div>

    <div class="row p-0">
        <h3>Inversión para el empleo</h3>
    </div>
    <div class="row p-0 border rounded p-3">
        <div class="col-xl-3 p-3 border-end ">
            <h3 class="text-light-emphasis">Total</h3>
            <h1 class="text-success">{{invTotal}}</h1>
            <h4 class="text-light-emphasis">MXN</h4>
        </div>
        <div class="col-xl-6 text-center">
            <div class="row">
                <div class="col-xl-1">
                    <button type="button" class="btn btn-white" (click)="changeInversion(true)">&#60;</button>
                </div>
                <div class="col-xl-10">
                    <h3>{{invTitle}}</h3>
                </div>
                <div class="col-xl-1">
                    <button type="button" class="btn btn-white" (click)="changeInversion(false)">&#62;</button>
                </div>
            </div><br>
            <div class="h1 text-success">{{invInver}}</div>
            <h4 class="text-light-emphasis">MXN</h4>
        </div>
        <div class="col-xl-3 text-center">
            <h3 class="text-light-emphasis">Inversiones Realizadas</h3>
            <h1 class="text-primary">67</h1>
            <h4 class="text-light-emphasis">Totales</h4>
        </div>
    </div>

    <div class="p-4"></div>

    <div class="row p-0">
        <div class="col-xl-6 p-1">
            <h3>
                Top Generadores de Empleo
            </h3>
            <p>Periodo de {{periodoGeneradores}}</p>
            <div class="graphContainer border rounded p-3 h9" id="generadoresDiv">

            </div>
        </div>
        <div class="col-xl-6 p-1 ">
            <h3>
                Impulso
            </h3>
            <p>Periodo de {{periodoImpulso}}</p>
            <div class="graphContainer border rounded p-3 h9" id="impulsoDiv">
                
            </div>
        </div>

        <div class="p-5"></div>

    </div>


    <app-root></app-root>