{
  "titulo": "Sostenibilidad y Responsabilidad Social",
  "datos": [
    {
      "titulo": "Politicas ambientales",
      "informacion": [
        {
          "subtitulo": "Gestion de residuos",
          "acciones": [
            "Implementar un sistema de reciclaje y reducción de residuos.",
            "Fomentar el uso de materiales biodegradables y sostenibles en los procesos productivos."
          ]
        },
        {
          "subtitulo": "Eficiencia Energética",
          "acciones": [
            "Realizar auditorías energéticas para identificar áreas de mejora.",
            "Invertir en tecnologías de energías renovables, como paneles solares."
          ]
        },
        {
          "subtitulo": "Uso Sostenible de Recursos",
          "acciones": [
            "Promover la conservación del agua y la gestión eficiente de este recurso.",
            "Fomentar prácticas agrícolas sostenibles si la empresa está en el sector agropecuario."
          ]
        }
      ]
    },
    {
      "titulo": "Politicas sociales",
      "informacion": [
        {
          "subtitulo": "Desarrollo Comunitario",
          "acciones": [
            "Colaborar con organizaciones locales para apoyar programas de educación y capacitación.",
            "Promover la compra de insumos a proveedores locales para impulsar la economía de la región."
          ]
        },
        {
          "subtitulo": "Condiciones Laborales",
          "acciones": [
            "Asegurar condiciones de trabajo justas y seguras para todos los empleados.",
            "Implementar programas de bienestar para los empleados, como formación continua y atención a la salud."
          ]
        },
        {
          "subtitulo": "Inclusión y Diversidad",
          "acciones": [
            "Fomentar la inclusión de grupos vulnerables en la fuerza laboral.",
            "Promover la igualdad de género y la diversidad en el entorno laboral."
          ]
        }
      ]
    },
    {
      "titulo": "Buen Gobierno Corporativo",
      "informacion": [
        {
          "subtitulo": "Transparencia y Ética",
          "acciones": [
            "Establecer un código de ética que rija la conducta empresarial.",
            "Fomentar la transparencia en la comunicación con los grupos de interés."
          ]
        },
        {
          "subtitulo": "Participación de los Empleados",
          "acciones": [
            "Crear canales de comunicación donde los empleados puedan expresar sus opiniones y sugerencias sobre la gestión empresarial.",
            "Involucrar a los empleados en iniciativas de sostenibilidad."
          ]
        },
        {
          "subtitulo": "Rendición de Cuentas",
          "acciones": [
            "Publicar informes periódicos sobre el desempeño en sostenibilidad y RSC.",
            "Establecer indicadores de rendimiento que midan el impacto social y ambiental de las actividades empresariales."
          ]
        }
      ]
    }
  ]
}
