import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.css'
})

export class HeaderComponent {
  public isCollapsed = true;

  clickHover(x: any){
    document.getElementById(x)!.click();
  }
}
