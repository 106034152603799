<div class="tab-container">
    <ul class="nav nav-tabs w-100 d-flex justify-content-between">
        <li class="nav-item flex-fill">
            <a class="nav-link active text-center" data-bs-toggle="tab" href="#empleos">Empleos</a>
        </li>
        <li class="nav-item flex-fill" *ngIf="showSectores">
            <a class="nav-link text-center" data-bs-toggle="tab" href="#sectores">Sectores</a>
        </li>
        <li class="nav-item flex-fill" *ngIf="showCiudadanos">
            <a class="nav-link text-center" data-bs-toggle="tab" href="#ciudadanos">Ciudadanos</a>
        </li>
        <li class="nav-item flex-fill" *ngIf="showMapaPoliticos">
            <a class="nav-link text-center" data-bs-toggle="tab" href="#mapaPoliticos">Mapa Políticos</a>
        </li>
    </ul>

    <div class="tab-content mt-3" style="color:white;">         
        <h5>Contenido de Empleos</h5>
        <div class="tab-pane container active" id="empleos">  
            <canvas id="employmentChart" class="chart-container"></canvas>
        </div>
        <div class="tab-pane container fade" id="sectores"  *ngIf="showSectores">
            <p>Contenido de Sectores</p>
            <canvas id="sectorChart" class="chart-container"></canvas>
        </div>
        <div class="tab-pane container fade" id="ciudadanos" *ngIf="showCiudadanos">
            <p>Contenido de Ciudadanos</p>
             <canvas id="pyramidChart" class="chart-container"></canvas>
        </div>
        <div class="tab-pane container fade" id="mapaPoliticos" *ngIf="showMapaPoliticos">
            <p>El mapa resalta en los municipios el Partido Político, coalición o candidatura independiente</p>
            <ul class="list-unstyled">
                <li *ngFor="let item of partidosItem">
                  <span [ngStyle]="{'background-color': item.color , opacity:0.9}" class="circle"></span> 
                  <div class="images-container">
                    <img *ngFor="let img of item.imagen" [src]="img" alt="Icono del partido" class="svg-icon">
                  </div>
                  {{ item.number }}
                </li>
              </ul>
        </div>
    </div>
    
    <div class="tab-content mt-3" style="margin-bottom: 45px; color:white;">
        <h5>Crecimiento Laboral</h5>
        <div class="col-md-12">
            <div id="newJobsChart" ></div>
        </div>
        <div class="col-md-12">
            <div id="existingJobsChart" ></div>
        </div>
    </div>
</div>
