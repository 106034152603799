<div class="container">
  <h3>Histórico de Empleos</h3>
</div>
<div class="container rounded border cbox h9">
  <div class="row">
    <div class="col-xl-1">
      <button type="button" class="btn btn-white" (click)="multiChange(true)">&#60;</button>
    </div>
    <div class="col-xl-10">
      <h3>{{anio}}</h3>
    </div>
    <div class="col-xl-1">
      <button type="button" class="btn btn-white" (click)="multiChange(false)">&#62;</button>
    </div>
  </div>
  <div class="">
    <graph-bar [ds]=historicoJSON></graph-bar>
  </div>
</div>