import { Component, Input } from '@angular/core';
import { barsDataDS, barsDataset } from '../../models/graphs.model';
import { durangoCommon } from '../../../shared/common';

@Component({
  selector: 'competitivo-inversion',
  templateUrl: './inversion.component.html',
  styleUrl: './inversion.component.css'
})

export class CompetitivoInversionComponent {

  @Input({ required: true }) ds?: string;
  
  public inversionJSON: string = '{}';
  private barData?: barsDataDS[];
  private comun = new durangoCommon;

  constructor() {
  }

  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.barData = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }
    this.inversionJSON = this.getInversionDS();
  }
  
  getInversionDS(){
    let resStr = '{}';
    let colores = this.comun.getStdColors();
    let i = 0;
    if(this.barData){
      for (let index = 0; index < this.barData.length; index++) {
        this.barData[index].color = colores[i];
        i = (i < colores.length-1) ? i+1 : 0;
      }
      let barDS: barsDataset = {
        label: 'Millones de pesos',
        aspect: true,
        dataset: this.barData
      }
      resStr = JSON.stringify(barDS);
    }

    return resStr;
    //return '{"label":"Millones de pesos","aspect":true,"dataset":[{"name":"2026","value":70,"color":"rgb(233, 233, 233)"},{"name":"2025","value":30,"color":"rgb(233, 233, 233)"},{"name":"2024","value":120,"color":"rgb(16, 104, 235)"},{"name":"2023","value":70,"color":"rgb(107, 160, 240)"},{"name":"2022","value":60,"color":"rgb(186, 210, 245)"}]}';
  }
}
