<div class="container mt-3">
    <h5>{{ dataCard.title }}</h5>
    <p>{{ dataCard.period }} </p>
    <div class="card">
        <div class="card-body">
            <ul class="nav justify-content-center">
                @for (item of dataCard.data; track $index) {
                <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': tabSelect == $index}" (click)="tabHandle($index)">{{
                        item.nombreTab }}</a>
                    @if (tabSelect === $index) {
                    <hr>
                    }
                </li>
                }
            </ul>
            <div class="row">
                <div class="col-sm-12">
                    <div class="d-flex justify-content-center mt-4">
                        <graph-line class="content-graph" [ds]="dataGrafica"></graph-line>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>