import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppComponent } from './app.component';
import { MapaComponent } from './mapa/mapa.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { InfoMapComponent } from './info-map/info-map.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DataService } from './data.service';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { BarComponent } from './graphs/bar/bar.component';
import { LineComponent } from './graphs/line/line.component';
import { CircComponent } from './graphs/circ/circ.component';
import { CompetitivoComponent } from './competitivo/competitivo.component';
import { AppRoutingModule } from './app-routing.module';
import { InicioComponent } from './inicio/inicio.component';
import { EmpleosGeneradosComponent } from './empleos-generados/empleos-generados.component';
import { EmpleosTotalesComponent } from './empleos-generados/totales/totales.component';
import { EmpleosNuevosComponent } from './empleos-generados/nuevos/nuevos.component';
import { EmpleosComparativaComponent } from './empleos-generados/comparativa/comparativa.component';
import { CompetitivoInversionComponent } from './competitivo/inversion/inversion.component';
import { CompetitivoPibAnualComponent } from './competitivo/pib-anual/pib-anual.component';
import { CompetitivoEmpleosComponent } from './competitivo/empleos/empleos.component';
import { EmpleosHistoricoComponent } from './empleos-generados/historico/historico.component';
import { CardTopComponent } from './card-top/card-top.component';
import { CardWithTabsComponent } from './card-with-tabs/card-with-tabs.component';
import { CardActEconComponent } from './card-act-econ/card-act-econ.component';
import { CardWithBarsComponent } from './card-with-bars/card-with-bars.component';
import { CardWithTabsAndGraphComponent } from './card-with-tabs-and-graph/card-with-tabs-and-graph.component';
import { CompetitivoMineriaComponent } from './competitivo/mineria/mineria.component';
import { CardStackComponent } from './card-stack/card-stack.component';
import { CompetitivoComercioComponent } from './competitivo/comercio/comercio.component';
import { CompetitivoEnergiaComponent } from './competitivo/energia/energia.component';
import { CardLabelComponent } from './card-label/card-label.component';
import { CardInformationComponent } from './card-information/card-information.component';
import { CompetitivoIndustriaComponent } from './competitivo/industria/industria.component';
import { CompetitivoPymesComponent } from './competitivo/pymes/pymes.component';
import { CardCarrouselComponent } from './card-carrousel/card-carrousel.component';

@NgModule({
  declarations: [
    AppComponent,
    MapaComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    InfoMapComponent,
    BarComponent,
    LineComponent,
    CardCarrouselComponent,
    CardLabelComponent,
    CardInformationComponent,
    CardTopComponent,
    CardWithBarsComponent,
    CardWithTabsComponent,
    CardWithTabsAndGraphComponent,
    CardActEconComponent,
    CardStackComponent,
    CircComponent,
    CompetitivoComponent,
    InicioComponent,
    CompetitivoComercioComponent,
    CompetitivoEmpleosComponent,
    CompetitivoEnergiaComponent,
    CompetitivoInversionComponent,
    CompetitivoPibAnualComponent,
    CompetitivoMineriaComponent,
    CompetitivoIndustriaComponent,
    EmpleosGeneradosComponent,
    EmpleosTotalesComponent,
    EmpleosNuevosComponent,
    EmpleosComparativaComponent,
    EmpleosHistoricoComponent,
    CompetitivoPymesComponent
],
  imports: [
    BrowserModule,
    HttpClientModule,
    LeafletModule,
    NgxChartsModule,
    NgbCollapseModule,
    AppRoutingModule,
  ],
  providers: [DataService],
  bootstrap: [InicioComponent],
})
export class AppModule {}
