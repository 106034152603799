[
    {
        "Sector": "Industrias de Transformación",
        "Empleos": [
            {
                "Año": 2023,
                "Meses":[
                    {
                        "Mes": "Abril",
                        "Empleos": 94935
                    },
                    {
                        "Mes": "Mayo",
                        "Empleos": 94321
                    },
                    {
                        "Mes": "Junio",
                        "Empleos": 93247
                    },
                    {
                        "Mes": "Julio",
                        "Empleos": 92711
                    },
                    {
                        "Mes": "Agosto",
                        "Empleos": 92843
                    },
                    {
                        "Mes": "Septiembre",
                        "Empleos": 92906
                    },
                    {
                        "Mes": "Octubre",
                        "Empleos": 92548
                    },
                    {
                        "Mes": "Noviembre",
                        "Empleos": 91276
                    },
                    {
                        "Mes": "Diciembre",
                        "Empleos": 88693
                    }
                ]
            },{
                "Año": 2024,
                "Meses": [
                    {
                        "Mes": "Enero",
                        "Empleos": 91346
                    },
                    {
                        "Mes": "Febrero",
                        "Empleos": 91967
                    },
                    {
                        "Mes": "Marzo",
                        "Empleos": 91519
                    },
                    {
                        "Mes": "Abril",
                        "Empleos": 91705
                    },
                    {
                        "Mes": "Mayo",
                        "Empleos": 90924
                    },
                    {
                        "Mes": "Junio",
                        "Empleos": 90379
                    },
                    {
                        "Mes": "Julio",
                        "Empleos": 88984
                    }
                ]
            }
        ]
    },
    {
        "Sector": "Industria de la Construcción",
        "Empleos": [
            {
                "Año": 2023,
                "Meses":[
                    {
                        "Mes": "Abril",
                        "Empleos": 17607
                    },
                    {
                        "Mes": "Mayo",
                        "Empleos": 17761
                    },
                    {
                        "Mes": "Junio",
                        "Empleos": 18000
                    },
                    {
                        "Mes": "Julio",
                        "Empleos": 18178
                    },
                    {
                        "Mes": "Agosto",
                        "Empleos": 19399
                    },
                    {
                        "Mes": "Septiembre",
                        "Empleos": 18643
                    },
                    {
                        "Mes": "Octubre",
                        "Empleos": 18835
                    },
                    {
                        "Mes": "Noviembre",
                        "Empleos": 18964
                    },
                    {
                        "Mes": "Diciembre",
                        "Empleos": 18189
                    }
                ]
            },{
                "Año": 2024,
                "Meses": [
                    {
                        "Mes": "Enero",
                        "Empleos": 18362
                    },
                    {
                        "Mes": "Febrero",
                        "Empleos": 18654
                    },
                    {
                        "Mes": "Marzo",
                        "Empleos": 18485
                    },
                    {
                        "Mes": "Abril",
                        "Empleos": 18900
                    },
                    {
                        "Mes": "Mayo",
                        "Empleos": 18729
                    },
                    {
                        "Mes": "Junio",
                        "Empleos": 19001
                    },
                    {
                        "Mes": "Julio",
                        "Empleos": 18838
                    }
                ]
            }
        ]
    },
    {
        "Sector": "Industrias Extractivas",
        "Empleos": [
            {
                "Año": 2023,
                "Meses":[
                    {
                        "Mes": "Abril",
                        "Empleos": 11286
                    },
                    {
                        "Mes": "Mayo",
                        "Empleos": 11308
                    },
                    {
                        "Mes": "Junio",
                        "Empleos": 11373
                    },
                    {
                        "Mes": "Julio",
                        "Empleos": 11191
                    },
                    {
                        "Mes": "Agosto",
                        "Empleos": 11206
                    },
                    {
                        "Mes": "Septiembre",
                        "Empleos": 11905
                    },
                    {
                        "Mes": "Octubre",
                        "Empleos": 10994
                    },
                    {
                        "Mes": "Noviembre",
                        "Empleos": 10821
                    },
                    {
                        "Mes": "Diciembre",
                        "Empleos": 10740
                    }
                ]
            },{
                "Año": 2024,
                "Meses": [
                    {
                        "Mes": "Enero",
                        "Empleos": 10983
                    },
                    {
                        "Mes": "Febrero",
                        "Empleos": 11053
                    },
                    {
                        "Mes": "Marzo",
                        "Empleos": 10954
                    },
                    {
                        "Mes": "Abril",
                        "Empleos": 11034
                    },
                    {
                        "Mes": "Mayo",
                        "Empleos": 11598
                    },
                    {
                        "Mes": "Junio",
                        "Empleos": 10602
                    },
                    {
                        "Mes": "Julio",
                        "Empleos": 10590
                    }
                ]
            }
        ]
    }
]