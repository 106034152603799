import { Component, Input } from '@angular/core';
import { barsDataDS, barsDataset } from '../../models/graphs.model';
import Chart from 'chart.js/auto';
import { empleosGeneradosDetalle } from '../../models/empleosGenerados.model';
import { durangoCommon } from '../../../shared/common';

@Component({
  selector: 'empleos-historico',
  templateUrl: './historico.component.html',
  styleUrl: './historico.component.css'
})
export class EmpleosHistoricoComponent {

  @Input({ required: true }) ds?: empleosGeneradosDetalle[];
  
  public historicoJSON: string = '{}';
  private barData?: barsDataDS[];
  public anio: string = (new Date().getFullYear() - 1).toString();
  public meses: string[] = [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic',];
  public esteMes = new Date().getMonth() + 1;
  public esteAnio = new Date().getFullYear();
  public multiY: number[] = [];
  public multiColors: string[] = ['rgb(233, 233, 233)'];
  private minYear = 4000;
  private maxYear = 2000;
  private mulYear: number[] = [];

  ngOnInit() {
    this.setMinYear();
    this.historicoJSON = this.getHistoricoDS();
  }

  multiNew() {
    this.barData = [];
    this.multiColors = [];
    this.multiY = [];
    this.mulYear = [];
    let thisAnio = this.ds!.find(x => x.anio.toString() === this.anio);
    //var meses = this.anio == this.esteAnio.toString() ? this.esteMes : 12
    var meses = thisAnio!.meses?.length
    for (let i = 0; i < meses; i++) {
      let val = (Math.floor(Math.random() * 100) + 1);
      //let thisMes = thisAnio!.meses.find(x => x.mesId-1 == i);
      let thisMes = thisAnio!.meses[i];
      let nvo: barsDataDS = {
        name: this.meses[thisMes.mesId-1],
        //name: thisMes.mes,
        value: thisMes ? thisMes?.empleos : 0
      };
      this.multiY.push(thisMes ? thisMes?.empleos : 0);
      this.mulYear.push(thisMes!.mesId);
      if(this.anio == this.esteAnio.toString() && i == (meses- 1)){
        nvo.color = 'rgb(225, 56, 80';
        this.multiColors.push('rgb(225, 56, 80');
      }else{
        nvo.color = 'rgb(233, 233, 233)';
        this.multiColors.push('rgb(233, 233, 233)');
      }
      this.barData.push(nvo);
    }
  }
  
  getHistoricoDS(){
    let resStr = '{}';
    this. multiNew();
    if(this.barData){
      let barDS: barsDataset = {
        label: 'Empleos',
        bgColor: '#FFFFFF',
        cid: 'crtHistorico',
        beginAt: 250000,
        dataset: this.barData
      }
      resStr = JSON.stringify(barDS);
    }

    return resStr;
  }

  
  multiChange(atras: boolean) {
    var lanio: number = parseInt(this.anio);
    if (atras) {
      if (lanio > this.minYear){
        this.anio = (lanio - 1).toString();
        this.multiNew();
      }
    } else {
      if (lanio < this.esteAnio){
        this.anio = (lanio + 1).toString();
        this.multiNew();
      }
    }
    
    let comun = new durangoCommon;
    comun.updateBarChart('crtHistorico', this.barData!);

  }

  setMinYear(){
    this.ds!.forEach(dt => {
      if(dt.anio < this.minYear)
        this.minYear = dt.anio;
      if(dt.anio > this.maxYear)
        this.maxYear = dt.anio;
      if(this.anio != this.maxYear.toString())
          this.anio = this.maxYear.toString();
    });
  }
}

