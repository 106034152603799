import { Component } from '@angular/core';
import { barsDataDS, barsDataset, circularDataDS, circularDataset } from '../../models/graphs.model';
import { empresasMineras, mineTabs, mineVisitantes } from '../../models/mineria.model';
import { durangoCommon } from '../../../shared/common';
import { dataCardTab, dataCardWithTabs } from '../../models/cardWithTabs.model';
import { stackCardContent, stackCardModel, stackCardPB } from '../../models/cardStack,model';
import { color } from 'd3';

@Component({
  selector: 'competitivo-mineria',
  templateUrl: './mineria.component.html',
  styleUrl: './mineria.component.css'
})
export class CompetitivoMineriaComponent {
  private comun = new durangoCommon;

  public mineriaCardDS: string = '';
  public mineriaProdDS: string = '';
  public mineriaEmplDS: string = '';
  
  public mineriaInveDS: string = '';
  public mineriaRegaDS: string = '';
  public mineriaInfrDS: string = '';
  
  public mineriaImpaDS: string = '';
  public mineriaExpoDS: string = '';
  
  public estaEmpresa: number = 0;

  public empresasMiner: empresasMineras[];

  constructor() {
    this.mineriaCardDS = this.getMineriaCardDS();
    this.mineriaProdDS = this.getMineriaProdDS();
    this.mineriaEmplDS = this.getMineriaEmplDS();
    this.mineriaInveDS = this.getMineriaInveDS();
    this.mineriaRegaDS = this.getMineriaRegaDS();
    this.mineriaInfrDS = this.getMineriaInfrDS();
    this.mineriaImpaDS = this.getMineriaImpaDS();
    this.mineriaExpoDS = this.getMineriaExpoDS();
    this.empresasMiner = this.getEmpresasMineras();
  }

  getMineriaCardDS() {
    // Sustituir por información productiva
    const dsFromWS = '[{"nombre":"Oro","valor":750346.22,"toneladas":360387},{"nombre":"Plata","valor":349652.1,"toneladas":147890},{"nombre":"Cobre","valor":298182.77,"toneladas":126980},{"nombre":"Zinc","valor":123456.78,"toneladas":852547}]';
    let res = '{}';
    try{
      let dataFromWS: mineTabs[] = JSON.parse(dsFromWS);
      let ds: dataCardWithTabs = {
        title: 'Producción Minera',
        titleTooltip: '<b>Minería</b><br>Medida en toneladas o valor monetario de los minerales extraídos en el estado',
        period: 'Periodo de Junio 2023 a Junio 2024',
        data: []
      }
      dataFromWS.forEach(dt => {
        let nvo: dataCardTab ={
          nombreTab: dt.nombre ? dt.nombre : '',
          valor: Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(dt.valor,),
          iconUrl: '../../assets/mineria_icon_2.png',
          descripcion: Intl.NumberFormat('es-MX', { style: 'decimal' }).format( dt.valor, ) + ' toneladas'
        }
        ds.data.push(nvo);
      });

      res = JSON.stringify(ds);
    }catch(ex: any){
      console.log(ex.message);
    }

    return res;
  }

  getMineriaProdDS() {
    // Sustituir por datos productivos
    const dtFromWS = '{"valor":750346.22,"toneladas":3502382}';

    let res = '{}';
    try{
      let dataFromWS: mineTabs = JSON.parse(dtFromWS);
      let valorMonetario = Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 0 }).format(dataFromWS.valor,);
      let Toneladas = dataFromWS.toneladas ? Intl.NumberFormat('es-MX', { style: 'decimal' }).format( dataFromWS.toneladas, ) : '';
      res = '{"title":"Valor de la Produccion","titleTooltip":"<b>Valor de la Producción</b><br/>Valor monetario total de los minerales extraídos y procesados en el estado durante un periodo específico, generalmente un año. Este indicador refleja la contribución económica directa de la industria minera.","period":"Periodo de Junio 2023 a Junio 2024","data":[{"title":"Valor Monetario","value":"' +
        valorMonetario +'","iconUrl":"../../assets/valor_monetario_icon.png"},{"title":"Toneladas","value":"' +
        Toneladas + '","iconUrl":"../../assets/toneladas_icon.png"}]}'
    }catch(ex: any){
      console.log(ex.message);
    }

    return res;
  }

  getMineriaEmplDS(){
    // Cambiar por información Productiva
    const dtFromWS = '[{"nombre":"Empleos Directos","valor":10580},{"nombre":"Empleos Indirectos","valor":2116}]';
    let res = '{}';
    try{
      let dataFromWS: mineTabs[] = JSON.parse(dtFromWS);
      let max = dataFromWS.reduce((n, {valor}) => n + valor, 0);
      let dsObject: stackCardModel = {
        title: 'Empleo',
        titleTooltip: '<b>Empleo</b><br>Número de personas empleadas directamente en la industria minera (trabajadores en minas, plantas de procesamiento, etc.) y empleos indirectos generados en sectores relacionados como transporte, servicios de apoyo, y manufactura.',
        period: 'Periodo a Junio 2024',
        data: []
      }
      dataFromWS.forEach(ds => {
        let fooTips = ds.nombre == 'Empleos Directos' ? 'Número de personas empleadas directamente en la industria minera (trabajadores en minas, plantas de procesamiento, etc.)'
          : ds.nombre == 'Empleos Indirectos' ? 'Empleos indirectos generados en sectores relacionados como transporte, servicios de apoyo, y manufactura.' : '';
        let color = ds.nombre == 'Empleos Directos' ? 'blue' : 'blueviolet'
        let nvo: stackCardContent = {
          title: '',
          value: Intl.NumberFormat('es-MX', { style: 'decimal' }).format( ds.valor, ),
          footer: ds.nombre ? ds.nombre : '',
          footerTooltip: fooTips
        }
        let pg: stackCardPB = {
          min: 0,
          max: max,
          value: ds.valor,
          color: color
        }
        nvo.progresbar = pg;
        dsObject.data.push(nvo);
      });

      res = JSON.stringify(dsObject);
    }catch(ex: any){
      console.log(ex.message);
    }

    return res;
  }

  getMineriaInveDS(){
    // Cambiar por valor productivo
    let inversión = 750346.22

    return '{"title":"Inversion en Exploración y Desarrollo","titleTooltip":"Monto invertido por empresas mineras en actividades de exploración y desarrollo de nuevos proyectos mineros en el estado.","color":"white","bgColor":"rgb(16,104,235)","data":[{"value":"'
    + Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 2 }).format(inversión,) +
    '","footer":" ","iconUrl":"../../assets/mineria_inversion_icon.png"}]}';
  }

  getMineriaRegaDS(){
    // Cambiar por valor productivo
    let regalias = 750346.22
    return '{"title":"Regalías y Contribuciones Fiscales","titleTooltip":"Ingresos generados por concepto de regalías mineras pagadas al gobierno estatal y contribuciones fiscales generadas por las empresas mineras.","data":[{"value":"'
    + Intl.NumberFormat('es-MX', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', maximumFractionDigits: 2 }).format(regalias,) +
    '","footer":"","iconUrl":"../../assets/regalias_icon.png"}]}';
  }

  getMineriaInfrDS(){
    // Cambiar por valor productivo
    let infraestructura = 35
    return '{"title":"Infraestructura Minera","titleTooltip":"<b>Infraestructura</b><br>Desarrollo de infraestructura relacionada con la minería, como nuevas minas, ampliación de plantas de procesamiento, mejoras en transporte y logística, entre otros.","data":[{"value":"'
    + infraestructura.toString() +
    '","footer":"Nuevas y remodelacion de Minas","iconUrl":"../../assets/infraestructura_icon.png"}]}';
  }

  getMineriaImpaDS(){
    return '{"title":"Impacto Social","titleTooltip":"<b>Impacto Social</b><br>Indicador relacionado con los programas de responsabilidad social corporativa implementados por las empresas mineras en el estado.","data":[{"nombreTab":"Gestión Social", "arcData": ' + this.getMineriaImpaDS3() + '}]}';
  }
  
  getMineriaImpaDS2() {
    let resStr = '{}';
    let arcDS: circularDataset ={
      dataset: [
        {
          "name": "Emisiones",
          "data": 86013,
          "color": "rgb(255,195,0)"
        },
        {
          "name": "Agua",
          "data": 46000,
          "color": "rgb(45,47,239)"
        },
        {
          "name": "Reforestación",
          "data": 37000,
          "color": "rgb(181,23,158)"
        },
        {
          "name": "Planificación",
          "data": 17000,
          "color": "rgb(213,213,213)"
        }
      ],
      rotate: 0,
      cutout: 50,
      title: "",
      width: 400,
      height: 300,
      bgColor: "#FFFFFF",
      legend: true,
      aspect: true,
      reverse: true,
      summary: false,
      cid: "chrt1"
    }
    resStr = JSON.stringify(arcDS);
    return resStr;
  }
  
  getMineriaImpaDS3() {
    let resStr = '';
    try{
      const wsResponse: mineVisitantes[] = require('../../../assets/json/IndustriaMinasVisitas.json');
      let barDS: barsDataset = {
        label: 'Visitas',
        cid: "chrt1",
        width: 400,
        height: 150,
        bgColor: "#FFFFFF",
        dataset: []
      }
      
      let arcDS: circularDataset ={
        dataset: [],
        rotate: 0,
        cutout: 50,
        title: "",
        width: 400,
        height: 350,
        bgColor: "#FFFFFF",
        legend: true,
        aspect: true,
        reverse: true,
        summary: false,
        cid: "chrt1"
      }
      let colores = this.comun.getStdColors();
      let i = 0;
      wsResponse.forEach((mina => {
        i = (i < colores.length - 1) ? i + 1 : 0;
        let nvo:barsDataDS = {
          name: mina.paseo,
          value: mina.visitantes,
          color: colores[i]
        }

        let nva: circularDataDS = {
          name: mina.paseo,
          data: mina.visitantes,
          color: colores[i]
        }

        barDS.dataset.push(nvo);
        arcDS.dataset.push(nva);
      }));

      resStr = JSON.stringify(arcDS);
      //resStr = JSON.stringify(barDS);
    }catch(ex){
      console.log(ex);
    }
    return resStr;
  }

  getMineriaExpoDS(){
    const dtFromWS = '[{"nombre":"Valor","valor":750346.22},{"nombre":"Volumen","valor":135980}]';
    let res = '{}';
    try{
      let dataFromWS: mineTabs[] = JSON.parse(dtFromWS);
      let max = dataFromWS.reduce((n, {valor}) => n + valor, 0);
      let dsObject: stackCardModel = {
        title: 'Exportación de Minerales',
        titleTooltip: '<b>Exportación de Minerales</b><br>Valor y volumen de minerales exportados desde el estado, que muestra la contribución al comercio exterior y la economía nacional.',
        period: '',
        data: []
      }
      dataFromWS.forEach(ds => {
        let fooTips = ds.nombre == 'Valor' ? 'Lorem Ipsum Dolor' : ds.nombre == 'Volumen' ? 'Lorem Ipsum Dolor' : '';
        let color = ds.nombre == 'Valor' ? 'blue' : 'blueviolet'
        let nvo: stackCardContent = {
          title: '',
          value: Intl.NumberFormat('es-MX', { style: 'decimal' }).format( ds.valor, ),
          footer: ds.nombre ? ds.nombre : ''
        }
        let pg: stackCardPB = {
          min: 0,
          max: max,
          value: ds.valor,
          color: color
        }
        nvo.progresbar = pg;
        dsObject.data.push(nvo);
      });

      res = JSON.stringify(dsObject);
    }catch(ex: any){
      console.log(ex.message);
    }

    return res;
  }

  getEmpresasMineras(){
    const empresas: empresasMineras[] = [{
      name: 'Sector Minero S.A. de C.V.',
      icon: '../../../assets/gray.png',
      produccion: 348981.01,
      concesiones: 2,
      emplDirectos: 340,
      emplIndirectos: 123,
      contibucion: 654879.23
    },
    {
      name: 'Minera Estatal S.A.',
      icon: '../../../assets/gray.png',
      produccion: 2737890,
      concesiones: 10,
      emplDirectos: 200,
      emplIndirectos: 100,
      contibucion: 26368490
    },
    {
      name: 'Mineros del Norte S.A. de C.V.',
      icon: '../../../assets/gray.png',
      produccion: 283749,
      concesiones: 5,
      emplDirectos: 433,
      emplIndirectos: 234,
      contibucion: 495736729
    },
    {
      name: "MINMEX",
      icon: "../../../assets/gray.png",
      produccion: 29847,
      concesiones: 1,
      emplDirectos: 10,
      emplIndirectos: 20,
      contibucion: 987659
    }
  ]
  
  return empresas;
  }

  openModal(index: number){
    this.estaEmpresa = index;
    /*
    const editTaskModal = new Modal('#mdlMineria').editTaskModal.show();
    editTaskModal.show();
    */
    document.getElementById("btnModal")!.click();
  }
}
