import { Component } from '@angular/core';
import { mineTabs } from '../../models/mineria.model';
import {
  stackCardContent,
  stackCardModel,
  stackCardPB,
} from '../../models/cardStack,model';
import { ExportacionesBarchrt, FinanciamientoBarchrt } from '../../models/pymes.model';
import { barsDataDS, barsDataset } from '../../models/graphs.model';
import { DataCardText, DatosItem } from '../../models/cardText.model';
import { DatosCardCarrousel } from '../../models/cardCarrousel.model';

@Component({
  selector: 'competitivo-pymes',
  templateUrl: './pymes.component.html',
  styleUrl: './pymes.component.css',
})
export class CompetitivoPymesComponent {
  dataEmpresasGraph!: string;
  datosTasaCrecAnual!: string;
  datosCreditosOtorgados!: string;
  datosMontoInversion!: string;
  datosInversionID!: string;
  datosAdopTecDig!: string;
  datosCompetitividad!: string;
  datosFinanciamiento!: string;
  datosExportaciones!: string;
  datosRedesApoyo!: string;

  datosResponsabilidadSocial!: DatosCardCarrousel;
  pymesEmplDS: string = '';

  constructor() {
    this.dataEmpresasGraph = this.getDataEmpresasGraph();
    this.datosTasaCrecAnual = this.getDatosTasaCrecAnual();
    this.datosCreditosOtorgados = this.getDatosCreditosOtorgados();
    this.datosMontoInversion = this.getDatosMontoInversion();
    this.datosInversionID = this.getDatosInversionID();
    this.datosAdopTecDig = this.getDatosAdopTecDig();
    this.datosCompetitividad = this.getCompetitividad();
    this.datosFinanciamiento = this.getDatosFinanciamiento();
    this.datosExportaciones = this.getDatosExportaciones();
    this.pymesEmplDS = this.getPymesEmplDS();
    this.datosRedesApoyo = this.getDatosRedesApoyo();

    // this.getDatosResponsabilidadSocial(); 
    this.datosResponsabilidadSocial = this.getDatosResponsabilidadSocial();
    // this.datosResponsabilidadSocial = '{"subtitulo":"Gestion de residuos","acciones":["Implementar un sistema de reciclaje y reducción de residuos.","Fomentar el uso de materiales biodegradables y sostenibles en los procesos productivos."]}'
  }

  getDataEmpresasGraph() {
    return '{"dataset": [{ "name": "PyMES", "data": 5.2, "color": "rgb(255,195,0)" },{ "name": "Grandes Empresas", "data": 0.3, "color": "rgb(181,23,158)" },{"name": "Microempresas", "data": 94.6, "color": "rgb(45,47,239)" }],  "rotate": 0,"cutout": 30,"title": "","width": 650,"height": 200,"bgColor": "#FFFFFF","legend": true,"aspect": true,"reverse": true,"summary": false,"cid": ""}';
  }

  getDatosTasaCrecAnual() {
    return '{"title": "Tasa de crecimiento anual*", "value":"2.3 %", "description":"No. de Pymes"}';
  }

  getDatosCreditosOtorgados() {
    return '{"value":"178", "description":"Créditos otorgados"}';
  }

  getDatosMontoInversion() {
    return '{"value":"$ 335,493,500", "description":"Pesos"}';
  }

  getDatosInversionID() {
    return '{ "title": "Inversión en I+D*","value":"$335,493,500", "description":"Pesos"}';
  }

  getDatosAdopTecDig() {
    return '{"title": "Nivel de adopción de tecnologías digitales","value":"800", "description":"Registros en e-commerce"}';
  }

  getCompetitividad() {
    return '{"title": "Ranking de competitividad","value":"17°", "description":"ICE (Índice de competitividad estatal)"}';
  }

  getPymesEmplDS() {
    // Cambiar por información Productiva
    const dtFromWS =
      '[{"nombre":"Empleos Directos","valor":13598},{"nombre":"Empleos Indirectos","valor":3569}]';
    let res = '{}';
    try {
      let dataFromWS: mineTabs[] = JSON.parse(dtFromWS);
      let max = dataFromWS.reduce((n, { valor }) => n + valor, 0);

      let dsObject: stackCardModel = {
        title: 'Empleo Pymes',
        titleTooltip:
          '<b>Empleo</b><br>Número de personas empleadas directamente en la industria minera (trabajadores en minas, plantas de procesamiento, etc.) y empleos indirectos generados en sectores relacionados como transporte, servicios de apoyo, y manufactura.',
        period: 'Periodo a Junio 2024',
        data: [],
      };
      dataFromWS.forEach((ds) => {
        let fooTips =
          ds.nombre == 'Empleos Directos'
            ? 'Lorem Ipsum Dolor'
            : ds.nombre == 'Empleos Indirectos'
            ? 'Lorem Ipsum Dolor'
            : '';
        let color = ds.nombre == 'Empleos Directos' ? 'blue' : 'blueviolet';
        let nvo: stackCardContent = {
          title: '',
          value: Intl.NumberFormat('es-MX', { style: 'decimal' }).format(
            ds.valor
          ),
          footer: ds.nombre ? ds.nombre : '',
          footerTooltip: fooTips,
        };
        let pg: stackCardPB = {
          min: 0,
          max: max,
          value: ds.valor,
          color: color,
        };
        nvo.progresbar = pg;
        dsObject.data.push(nvo);
      });

      res = JSON.stringify(dsObject);
    } catch (ex: any) {
      console.log(ex.message);
    }

    return res;
  }

  getDatosFinanciamiento() {
    let res = '{}';
    try {
      const data: Array<FinanciamientoBarchrt> = require('../../../assets/json/finaciamientoPymes.json');

      const dataGraph: barsDataset = {
        label: 'Millones de pesos',
        aspect: true,
        axis: 'y',
        bgColor: '#ffffff',
        showy: true,
        showx: false,
        width: 1200,
        height: 500,
        dataset: [],
        topBar: "dato"
      };

      if (data !== null && data.length !== 0) {
        data.forEach((el: FinanciamientoBarchrt, i: number) => {
          const item: barsDataDS = {
            name: el.municipio,
            value: el.financiamiento,
            color: i % 2 === 0 ? '#E73679' : '#A81952',
          };
          dataGraph.dataset.push(item);
        });
        
        res = JSON.stringify(dataGraph);        
      }
    } catch (error) {
      console.log(error);
      res = '{}';
    }
    return res;
  }

  getDatosExportaciones() {
    let res = '{}'
    try {
      const data: Array<ExportacionesBarchrt> = require('../../../assets/json/exportacionesPymes.json');

      const dataGraph: barsDataset = {
        label: 'Millones de pesos',
        aspect: true,
        axis: 'y',
        bgColor: '#ffffff',
        showy: true,
        showx: false,
        width: 700,
        height: 500,
        dataset: [],
        topBar: "dato"
      };

      if (data !== null && data.length !== 0) {
        data.forEach((el: ExportacionesBarchrt, i: number) => {
          const item: barsDataDS = {
            name: el.rubro,
            value: el.monto,
            color:'#1068EB'
          };
          dataGraph.dataset.push(item);
        });

        res = JSON.stringify(dataGraph);
        console.log(res);
        
      }
      
    } catch (error) {
      console.log(error);
      res = '{}';
    }

    return res;
  }

  getDatosRedesApoyo() {
    let res = []
    try {
      res = require('../../../assets/json/redesApoyoPymes.json')
      return res;
    } catch (error) {
      console.log(error);
      return []
    }
  }

  getDatosResponsabilidadSocial () {
    let resp;
    try {
      const data =  require('../../../assets/json/ResponsabilidadSocialPymes.json');
      console.log(data);
      resp = data;

    } catch (error) {
      console.log(error);
      resp =  {};
    }
    return resp;
  }
}
