<div class="container">
    <h5>{{ dataCard.title }}        
        <button type="button" class="btn btn-white p-0" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-html="true"
            title="{{dataCard.titleTooltip}}" *ngIf="dataCard.titleTooltip">
            <i class="bi bi-info-circle"></i>
        </button>
    </h5>
    <p>{{ dataCard.period }}</p>
    <div class="card">
        <div class="card-body">
            <div class="icon-content">
                <img src="{{dataCard.icon}}" alt="">
            </div>
            <div>
                <ul>
                    @for (item of dataCard.data; track $index) {
                    <li class="li-align">
                        <span class="texto">{{ item.nombre }}</span>
                        <span class="porcentaje">{{ item.porcentaje}}</span>
                    </li>
                    }
                </ul>
            </div>
        </div>
    </div>
</div>
