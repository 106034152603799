import { Component, Output, OnInit, Input, SimpleChanges, OnChanges, AfterViewInit, EventEmitter } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';
import { HttpClient } from '@angular/common/http';
import { GeoJSONData, PopulationData, SectorData } from '../models/durango.model';


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements AfterViewInit {

  private map!: L.Map;
  private baseLayer!: L.TileLayer;
  private geojsonLayer!: L.GeoJSON;
  private carreterasLayer!: L.GeoJSON;
  private readonly geojsonUrl = 'assets/durango.geojson';
  private readonly carreterasUrl = 'assets/durango-streets.geojson';
  private isMunicipiosMode: boolean = false; // Controlar el estado del mapa
  private isInitialState: boolean = true; // Controlar el estado inicial del mapa
  private sectorMarkers: L.LayerGroup = L.layerGroup();
  private optionSectores: number = 0; // Agrupación de marcadores para los sectores
  sectoresDataMarkers: SectorData[] = [];
  private defaultGeoJsonStyle = {
    color: '#2954b4', // Color de borde
    weight: 4,
    opacity: 0.3,
    //fillColor: 'rgba(28,74,157,.5)',
    fillColor: 'rgba(51,102,220,.3)',
    fillOpacity: 1
  };

  private reliefGeoJsonStyle = {
    color: '#000000', // Color de borde en negro
    weight: 2,
    opacity: 0.8,
    fillOpacity: 0 // Sin relleno para ver claramente sobre el relieve
  };

  private readonly municipiosGeoJsonStyle = {
    color: '#000000', // Color de borde en negro
    weight: 2,
    opacity: 0.8,
    fillOpacity: 0.6
  };

  private readonly carreterasStyle = {
    color: '#d5dbdb', // Color para carreteras
    weight: 6,
    opacity: 0.8
  };

  private highlightedStyle: L.PathOptions = {
    color: '#2954b4', // Color de borde más brillante
    weight: 1,        // Borde más grueso
    opacity: 1,
    fillColor: 'rgba(28,74,157,.5)', // Color de relleno diferente
    fillOpacity: 1,    // Algo de transparencia
  };

  private shadowStyle: L.PathOptions = {
    color: '#2954b4', // Color de la sombra
    weight: 8,        // Grosor del borde de la sombra (más grande que el borde normal)
    opacity: .5,     // Opacidad reducida para crear la sombra
    fillOpacity: 0    // Sin relleno, solo el borde
  };

  private currentPin: L.Marker | null = null; // Referencia al pin actual
  private shadowLayer: L.Layer | null = null;
  private selectedMunicipioLayer: L.Layer | null = null;

  private originalCenter: L.LatLngExpression | [] = [24.02032, -104.65756]; // Coordenadas válidas
  private originalZoom: number | null = null;

  @Input() mapAction!: string;
  @Output() municipalitySelected = new EventEmitter<any>();
  @Output() employeSelected = new EventEmitter<any>();

  constructor(private http: HttpClient) { }


  ngAfterViewInit(): void {
    this.initMap();
    this.initLoadGeoJSON(this.defaultGeoJsonStyle);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mapAction']) {
      const comboPress = this.mapAction.split('-');
      this.handleMapAction(comboPress[0], comboPress[1], comboPress[2]);
    }
  }

  private initMap(): void {
    this.originalCenter = [24.02032, -104.65756]; // Coordenadas centradas en Durango
    this.originalZoom = 7.15;
    this.map = L.map('map', {
      center: this.originalCenter, // Coordenadas centradas en Durango
      zoom: this.originalZoom,
      minZoom: 7.15,
      maxZoom: 15.45,
      zoomControl: false,
      attributionControl: false,
      dragging: false,
      layers: [],// Deshabilitar arrastre del mapa
    });

  }



  private initLoadGeoJSON(style: L.PathOptions): void {
    fetch(this.geojsonUrl)
      .then(response => response.json())
      .then(data => {
        if (this.geojsonLayer) {
          this.map.removeLayer(this.geojsonLayer); // Remove existing layer if it exists
        }
        this.geojsonLayer = L.geoJSON(data, {
          style: feature => {
            if (this.isMunicipiosMode) {
              // Verifica si la característica tiene un color definido en su propiedad 'fillColor'
              const featureFillColor = feature?.properties?.fillColor || this.getRandomColor();

              return {
                color: '#FFFFFF', // Color de borde
                weight: 2,
                opacity: 0.8,
                fillColor: featureFillColor,//this.getRandomColor(),
                fillOpacity: 0.6
              };
            } else if (this.isInitialState) {
              return this.defaultGeoJsonStyle;
            } else {
              return style;
            }
          },
          onEachFeature: (feature, layer) => {
            if (feature && feature.properties) {

              const munName = feature.properties.mun_name || 'Sin nombre';

              layer.on('mouseover', () => {
                layer.bindTooltip(munName, { permanent: false, direction: 'auto' }).openTooltip();
              });


              layer.on('mouseout', () => { layer.closeTooltip(); });
              // Evento cuando el usuario hace clic en un municipio
              layer.on('click', () => {
                // Obtener los límites del municipio como un polígono
                this.highlightMunicipio(layer);


                const bounds = (layer as L.Polygon).getBounds();

                // Obtener el centro del municipio
                const center = bounds.getCenter();
                debugger;
                // Emitir los datos del municipio seleccionado
                const populationData = this.transformPopulationData(feature.properties.poblacion);
                const empleoyeeData = this.transformPopulationData(feature.properties.empleos);
                const sectoresData = this.transformSectoresData(feature.properties.sectores);
                this.sectoresDataMarkers = sectoresData;
                this.municipalitySelected.emit({
                  munName: feature.properties.mun_name,
                  populationData: populationData,
                  sectoresData: sectoresData
                });

                this.employeSelected.emit({
                  munName: feature.properties.mun_name,
                  populationData: empleoyeeData
                });

                this.updateSectorMarkers(sectoresData);

                // Eliminar el pin actual si existe
                if (this.currentPin) {
                  this.map.removeLayer(this.currentPin);
                }
                
                // Colocar un nuevo pin en el centro del municipio
                const pinIcon = L.divIcon({
                  className: 'custom-pin',
                  html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M16 144a144 144 0 1 1 288 0A144 144 0 1 1 16 144zM160 80c8.8 0 16-7.2 16-16s-7.2-16-16-16c-53 0-96 43-96 96c0 8.8 7.2 16 16 16s16-7.2 16-16c0-35.3 28.7-64 64-64zM128 480l0-162.9c10.4 1.9 21.1 2.9 32 2.9s21.6-1 32-2.9L192 480c0 17.7-14.3 32-32 32s-32-14.3-32-32z"/></svg> `,

                  iconSize: [25, 41],
                  iconAnchor: [12, 41]
                });

                this.currentPin = L.marker(center, { icon: pinIcon })
                  .addTo(this.map)
                  .on('click', () => this.showPopup(feature.properties));

                // Enfocar el mapa en el municipio y hacer zoom
                this.map.setView(center, 10);
                // Restaurar la vista original cuando se cierre el popup
                this.currentPin.on('popupclose', () => {
                  this.restoreMapView();
                });
              });

            } else {
              layer.bindPopup('Sin información disponible');
            }
          },

        }).addTo(this.map);

        //this.map.setMinZoom(7.7); // Establece el nuevo mínimo zoom
        //this.map.setMaxZoom(7.7); // Establece el nuevo máximo zoom
      })
      .catch(error => {
        console.error('Error al cargar el archivo GeoJSON:', error)
      }
      );
  }

  private loadCarreteras(): void {

    fetch(this.carreterasUrl)
      .then(response => response.json())
      .then(data => {
        if (this.carreterasLayer) {
          this.map.removeLayer(this.carreterasLayer); // Remove existing layer if it exists
        }
        this.carreterasLayer = L.geoJSON(data, {
          style: this.carreterasStyle,

          onEachFeature: (feature, layer) => {
            if (feature && feature.properties) {
              const nom_tramo = feature.properties.nom_tramo || 'Sin nombre';
              layer.on('mouseover', () => {
                layer.bindTooltip(nom_tramo, { permanent: false, direction: 'auto' }).openTooltip();
              });


              layer.on('mouseout', () => { layer.closeTooltip(); });
              layer.on('click', () => {
                alert(`Carretera: ${nom_tramo}`);
              });

            } else {
              layer.bindPopup('Sin información disponible');
            }
          }


        }).addTo(this.map);

        this.map.setMinZoom(7.7); // Establece el nuevo mínimo zoom
        this.map.setMaxZoom(7.7); // Establece el nuevo máximo zoom
        // Centrar el mapa en unas coordenadas específicas y ajustar el nivel de zoom
        const newCenter: any = [24.02032, -104.65756]; // Ajusta las coordenadas según sea necesario
        const newZoom = 7.7; // Ajusta el nivel de zoom según sea necesario
        this.map.setView(newCenter, newZoom);
        // No es necesario ajustar los límites porque las carreteras deben superponerse al estado de Durango
      })
      .catch(error => console.error('Error al cargar el archivo GeoJSON de carreteras:', error));
  }


  private handleMapAction(action: string, decisionbool: string, valueSelected: string): void {
    debugger;
    this.optionSectores = valueSelected != undefined ? valueSelected === '' ?  0 : Number(valueSelected): 0;
    switch (action) {
      case 'relieve':
        this.changeToReliefMap();
        break;
      case 'pol':
        decisionbool == 'active' ? this.changeToMunicipiosMap() : this.changeToInitialMap();
        break;
      case 'dinamico':
        this.changeToInitialMap();
        break;
      case 'carreteras':
        this.loadCarreteras();
        break;
      // Agregar más casos si es necesario
      default:
        this.changeToInitialMap();
        this.updateSectorMarkers(this.sectoresDataMarkers);
        break;
    }
  }

  private changeToReliefMap(): void {
    if (this.baseLayer) {
      this.map.removeLayer(this.baseLayer);
    }
    this.baseLayer = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
    }).addTo(this.map);

    // Update GeoJSON style for relief map
    this.clearAllLayers();
    this.isMunicipiosMode = false;
    this.isInitialState = false;
    this.initLoadGeoJSON(this.reliefGeoJsonStyle);
  }

  private changeToMunicipiosMap(): void {
    if (this.baseLayer) {
      this.map.removeLayer(this.baseLayer);
    }

    this.clearAllLayers();
    this.isMunicipiosMode = true;
    this.isInitialState = false;
    this.initLoadGeoJSON(this.defaultGeoJsonStyle);
  }

  private changeToInitialMap(): void {
    if (this.baseLayer) {
      this.map.removeLayer(this.baseLayer);
    }

    this.clearAllLayers();
    this.isMunicipiosMode = false;
    this.isInitialState = true;
    this.initLoadGeoJSON(this.defaultGeoJsonStyle);
  }

  private clearAllLayers(): void {
    if (this.geojsonLayer) {
      this.map.removeLayer(this.geojsonLayer);
    }
    if (this.carreterasLayer) {
      this.map.removeLayer(this.carreterasLayer);
    }
  }

  private getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  private applyShadowEffect(layer: L.Layer): void {
    // Si ya existe una sombra, eliminarla
    if (this.shadowLayer) {
      this.map.removeLayer(this.shadowLayer);
    }

    // Duplicar la geometría del municipio seleccionado y aplicarle el estilo de sombra
    this.shadowLayer = L.geoJSON((layer as any).feature, {
      style: this.shadowStyle
    }).addTo(this.map);

    // Verifica si la sombra es un tipo Path antes de llamar a bringToBack
    if (this.shadowLayer instanceof L.Path) {
      this.shadowLayer.bringToBack();  // Usa bringToBack solo si es una instancia de Path
    }
  }

  private highlightMunicipio(layer: L.Layer): void {
    // Restaurar el estilo del municipio anteriormente seleccionado
    if (this.selectedMunicipioLayer) {
      (this.selectedMunicipioLayer as L.Path).setStyle(this.defaultGeoJsonStyle);
    }

    // Aplicar el estilo resaltado al municipio actualmente seleccionado
    (layer as L.Path).setStyle(this.highlightedStyle);

    // Aplicar un estilo de transparencia al resto de los municipios
    this.geojsonLayer.eachLayer((geoLayer) => {
      if (geoLayer !== layer) {
        (geoLayer as L.Path).setStyle({ fillOpacity: .1, opacity: 0.5 }); // Hacer transparentes los municipios no seleccionados
      }
    });
    // Aplicar el efecto de sombra
    this.applyShadowEffect(layer);

    // Actualizar la referencia del municipio seleccionado
    this.selectedMunicipioLayer = layer;
  }



  private showPopup(properties: any): void {
    const content = `
      <div class="popup-card">
        <h3><strong>${properties.mun_name}</strong></h3>
        <p><strong>Municipio:</strong> ${properties.mun_name}</p>
        <p><strong>Hombres:</strong> ${properties.rep_pobtot}</p>
        <p><strong>Mujeres:</strong> ${properties.rep_pobtot}</p>
        <p><strong>Población General:</strong> ${properties.rep_pobtot}</p>
      </div>
    `;
    if (this.currentPin) {
      this.currentPin.bindPopup(content).openPopup();
    }
  }

  private restoreMapView(): void {
    this.originalCenter = [24.02032, -104.65756]; // Coordenadas centradas en Durango
    this.originalZoom = 7.15;
    this.map.setView(this.originalCenter, this.originalZoom);

    // Restaurar el estilo original de todos los municipios
    this.geojsonLayer.eachLayer((geoLayer) => {
      (geoLayer as L.Path).setStyle(this.defaultGeoJsonStyle);
    });

    // Eliminar el pin actual si existe
    if (this.currentPin) {
      this.map.removeLayer(this.currentPin);
      this.currentPin = null; // Asegurarse de que se restablezca la referencia
    }

    this.sectorMarkers.clearLayers();
  }

  private transformPopulationData(poblacion: { [key: string]: { female: number, male: number } }[]): PopulationData[] {
    return poblacion.map(ageGroup => {
      const ageRange = Object.keys(ageGroup)[0]; // Ej. "0-4"
      const populationData = ageGroup[ageRange]; // Ej. { female: 17105, male: 17295 }

      return {
        ageRange: ageRange,
        male: populationData.male,
        female: populationData.female
      };
    });
  }

  private transformSectoresData(sectores: { [key: string]: { title: string, value: number, positions: [] } }[]): SectorData[] {
    return sectores.map(sectorGroup => {
      const option = Object.keys(sectorGroup)[0]; // Ej. "0", "1", etc.
      const sectorData = sectorGroup[option]; // Ej. { title: "Sector A", value: 123, positions: [...] }

      return {
        option: option,
        title: sectorData.title,
        value: sectorData.value,
        positions: sectorData.positions
      };
    });
  }

  private updateSectorMarkers(sectorData: SectorData[]) {
    this.sectorMarkers.clearLayers();
    let filteredSectores = sectorData.filter(sector => sector.option === this.optionSectores.toString());

    // Crear marcadores para los sectores filtrados
    filteredSectores.forEach((sector: SectorData) => {
      const positions = sector.positions; // Tomar las posiciones
      // Dibujar marcadores en cada posición      
      positions.forEach((position: any) => {
        position.forEach((markers: any) => {          
          const marker = L.circleMarker(markers, {
            color: '#3388ff',
            radius: 8,
          });
          this.sectorMarkers.addLayer(marker);
        });
        
      });
    });
    this.sectorMarkers.addTo(this.map);
  }
}