import { Component, Input, OnInit } from '@angular/core';
import { Chart, Title } from 'chart.js/auto';
import { circularDataset, circularDS } from '../../models/graphs.model';

@Component({
  selector: 'graph-circ',
  templateUrl: './circ.component.html',
  styleUrl: './circ.component.css'
})
export class CircComponent {

  constructor() {
  }

  @Input({ required: true }) ds?: string;
  cid: string = '';
  dataset?: circularDataset;
  public chart: any;
  private total: number = 0;

  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.dataset = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }

    this.cid = this.dataset?.cid ? this.dataset.cid : this.makeid();
  }

  ngAfterViewInit(): void {
    this.makeChart();
  }


  makeChart() {
    if (this.dataset) {
      var names: string[] = [];
      var datas: number[] = [];
      var bgc: string[] = [];
      var borders: number[] = [];
      var cutout: number = this.dataset.cutout ? this.dataset.cutout : 150;
      //var showTitle: boolean = this.dataset.title ? true : false;
      this.dataset.legend = this.dataset.legend ? true : false
      this.dataset.cutout = this.dataset.cutout ? this.dataset.cutout : 120
      this.dataset.rotate = this.dataset.rotate ? this.dataset.rotate : 0
      this.dataset.summary = this.dataset.summary ? this.dataset.summary : false
      this.dataset.custSummary = this.dataset.custSummary ? this.dataset.custSummary : ''

      this.dataset.dataset.forEach(sy => {
        names.push(sy.name);
        datas.push(sy.data);
        if (sy.color) {
          bgc.push(sy.color);
        } else {
          bgc.push("rgb(16, 104, 235)");
        }
        if (sy.border) {
          borders.push(sy.border);
        } else {
          borders.push(2);
        }
      });


      const datag = {
        labels: names,
        datasets: [{
          data: datas,
          backgroundColor: bgc,
          borderWidth: borders,
          cutout: cutout
        }]
      };

      this.chart = new Chart(this.cid, {
        type: 'doughnut',
        data: datag,
        options: {
          responsive: true,
          maintainAspectRatio: this.dataset.aspect,
          plugins: {
            legend: {
              display: this.dataset.legend,
              position: 'right',
              labels: {
                usePointStyle: true,
                boxWidth: 6
              },
              reverse: this.dataset.reverse
            },
            title: {
              display: false,
              text: this.dataset.title
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  switch (context.label) {
                    case 'Planificación':
                      return ['44% en consumo'];
                      break;
                    case 'Reforestación':
                      return ['27% más reforestación'];
                      break;
                    case 'Consumo':
                      return ['78% limpieza'];
                      break;
                    default:
                      let label = context.dataset.label ;
                      return label;
                      break;
                  }
                },
                footer: function (context) {
                  switch (context[0].label) {
                    case 'Planificación':
                      return ['Lorem ipsum dolom'];
                      break;
                    case 'Reforestación':
                      return ['1,897 árboles plantados a Junio 2024', '15% superior al año pasado'];
                      break;
                    case 'Consumo':
                      return ['Índice superior a 2023'];
                      break;
                    default:
                      return '';
                      break;
                  }
                }
              },/*
              titleFont: {
                size: 12
              },
              bodyFont: {
                size: 20
              },
              footerFont: {
                size: 10
              }*/
            }
          },
          rotation: this.dataset.rotate,
        },
        plugins: [
          this.pluginBgColor,
          this.pluginCenterText
        ]
      });
      
      if (this.dataset.width ? this.dataset.width : 0 > 0 &&
        this.dataset.height ? this.dataset.height : 0 > 0) {
        this.chart.resize(this.dataset.width, this.dataset.height);
      }

      if (this.dataset.summary) {
        this.dataset.dataset.forEach(dt => {
          this.total += dt.data;
        });
        var newDiv = document.createElement("h1");
        newDiv.classList.add('text-center')
        let sumData = Intl.NumberFormat('es-MX', { style: 'decimal' }).format( this.total, );
        if(this.dataset.custSummary != ''){
          sumData = this.dataset.custSummary;
        }
        var newContent = document.createTextNode(sumData);
        newDiv.appendChild(newContent);

        var currentDiv = document.getElementById(this.cid)?.parentNode;
        if(currentDiv)
          this.insertAfter(document.getElementById(this.cid), newDiv)
      }
    }

  }


  makeid(length: number = 5) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  pluginBgColor = {
    id: 'pluginBgColor',
    beforeDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = this.dataset?.bgColor || 'rgb(243, 246, 248)';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

  pluginCenterText = {
    id: 'centerText',
    afterDatasetsDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart;
      if (chart.config.type == 'doughnut' && this.dataset?.title) {
        ctx.save();
        const x = chart.getDatasetMeta(0).data[0].x;
        const y = chart.getDatasetMeta(0).data[0].y;

        //ctx.fillRect(x-10, y-10, 20, 20)
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = 'bold 24px sans-serif';

        let title = this.dataset?.title ? this.dataset?.title : '';
        let arrt = title.split('\n');
        if(arrt.length == 2){
          ctx.fillText(arrt[0], x, y-15);
          ctx.fillText(arrt[1], x, y+15);
        }else{
          ctx.fillText(this.dataset?.title, x, y);
          ctx.fillText(this.dataset?.title, x, y * 20);
        }

      }

    }
  }

  insertAfter(referenceNode: any, newNode: Node) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}
}
