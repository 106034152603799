import { Component, Input, SimpleChanges } from '@angular/core';
import { DataCardLabelIcon } from '../models/cardLabelIcon.model';
declare var bootstrap: any;


@Component({
  selector: 'app-card-label',
  templateUrl: './card-label.component.html',
  styleUrl: './card-label.component.css'
})
export class CardLabelComponent {
  @Input({required: true}) data!: string;
  @Input({required: false}) min_h!:string;
  
  dataCard!: DataCardLabelIcon;

  ngAfterViewInit(): void {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] !== undefined) {
      this.dataCard = JSON.parse(changes['data'].currentValue);      
    }
  }

}
