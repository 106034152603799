[
    {
        "municipio":"Rodeo",
        "financiamiento":"1930000"
    },
    {
        "municipio":"Canatlán",
        "financiamiento":"2000000"
    },
    {
        "municipio":"Santiago Papasquiaro",
        "financiamiento":"2000000"
    },
    {
        "municipio":"Peñón Blanco",
        "financiamiento":"2000000"
    },
    {
       "municipio":"El Oro",
        "financiamiento":"4010000"
    },
    {
        "municipio":"Nuevo Ideal",
        "financiamiento":"5190000"
    },
    {
        "municipio":"Cuencamé",
        "financiamiento":"6385000"
    },
    {
        "municipio":"Pueblo Nuevo",
        "financiamiento":"6800000"
    },
    {
        "municipio":"Poanas",
        "financiamiento":"7000000"
    },
    {
        "municipio":"Guadalupe Victoria",
        "financiamiento":"9500000"
    },
    {
        "municipio":"Lerdo",
        "financiamiento":"9670000"
    },
    {
        "municipio":"Vicente Guerrero",
        "financiamiento":"12500000"
    },
    {
        "municipio":"Gómez Palacio",
        "financiamiento":"110180000"
    },
    {
        "municipio":"Durango",
        "financiamiento":"156328500"
    }
]