import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { lineDataset, lineDS } from '../../models/graphs.model';


@Component({
  selector: 'graph-line',
  templateUrl: './line.component.html',
  styleUrl: './line.component.css'
})
export class LineComponent {

  constructor() {
  }

  @Input({ required: true }) ds?: string;
  dataset?: lineDataset;
  cid: string = '';

  /*
  serieX: string[] = [];
  serieY: lineDataset[] = [];
  cid: string = this.makeid();
  leyend: boolean = false;
  bgColor: string = 'rgb(243, 246, 248)';
  */
  public chart: any;


  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.dataset = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }

    this.cid = this.dataset?.cid ? this.dataset.cid : this.makeid();
  }

  ngAfterViewInit(): void {
    this.makeChart();
  }


  makeChart() {
    if (this.dataset) {
      var datasets: lineDS[] = [];
      var minI = 9999999;
      var maxI = 0;
      this.dataset.dataset.forEach(sy => {
        var ny: lineDS = {
          name: sy.name,
          data: sy.data,
          borderColor: sy.color ? sy.color : 'rgb(16, 104, 235)',
          tension: sy.tension ? sy.tension : 0.1,
          label: sy.label ? sy.label : '',
          borderWidth: sy.borderWidth ? sy.borderWidth : 2,
        };
        datasets.push(ny);
        minI = minI > Math.min.apply(null, sy.data) ? Math.min.apply(null, sy.data) : minI;
        maxI = maxI < Math.max.apply(null, sy.data) ? Math.max.apply(null, sy.data) : maxI;
      });

      minI = minI < 1 ? minI : minI - 5;
      maxI = maxI + 5;
      this.dataset.legend = this.dataset.legend ? true : false
      this.dataset.showx = this.dataset.showx ? true : false
      this.dataset.showy = this.dataset.showy ? true : false

      const data = {
        labels: this.dataset.dataLabels,
        datasets: datasets
      };

      this.chart = new Chart(this.cid, {
        type: 'line',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: this.dataset.aspect,
          plugins: {
            legend: {
              display: this.dataset.legend,
            },
            title: {
              display: false,
              text: ''
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let thisval = context.dataset.data[context.dataIndex];
                  let label = thisval?.toString();
                  if(typeof thisval == "number"){
                    let value = Intl.NumberFormat('es-MX', { style: 'decimal' }).format( thisval ? thisval : 0, );
                    switch (context.dataset.label) {
                      case "Industrias manufactureras":
                        label = "Índice de Producción Industrial: " + value ;
                      break;
                      default:
                        console.log(context)
                        label = context.dataset.label + ": " + value ;
                        break;
                    }
                  }
                  return label;
                },
              },
            }
          },
          scales: {
            y: {
              display: true,
              min: minI,
              max: maxI,
              grid: {
                display: this.dataset.showy,
              }
            },
            x: {
              beginAtZero: true,
              grid: {
                display: this.dataset.showx,
              }
            }
          }
        },
        plugins: [
          this.pluginBgColor,
        ]
      });
      
      if (this.dataset.width ? this.dataset.width : 0 > 0 &&
        this.dataset.height ? this.dataset.height : 0 > 0) {
        this.chart.resize(this.dataset.width, this.dataset.height);
      }
    }
  }


  makeid(length: number = 5) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }


  pluginBgColor = {
    id: 'pluginBgColor',
    beforeDraw: (chart: any, args: any, options: any) => {
      const { ctx } = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = this.dataset?.bgColor || 'rgb(243, 246, 248)';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

}
