import { Component } from '@angular/core';
import { MapaComponent } from './mapa/mapa.component';
import { DashboardComponent } from './dashboard/dashboard.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'mapa-interactivo';
  mapAction: string = '';
  selectedMunicipalityData: any = [];
  selectedEmployeeData: any = [];
}
