[
    {
        "paseo": "Viejo Oeste",
        "visitantes": 57987
    },
    {
        "paseo": "El Pueblito",
        "visitantes": 33156
    },
    {
        "paseo": "Teleférico",
        "visitantes": 30884
    },
    {
        "paseo": "Tunel",
        "visitantes": 29418
    },
    {
        "paseo": "Puente de Ojuela",
        "visitantes": 11937
    }

]