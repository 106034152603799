import { Component } from '@angular/core';
import { industriaActividadAnios, industriaActividadMeses, industriaActividadModel, industriaBarchrt, industriaMunicipioSector, industriaPlantas, muniMapaCoord } from '../../models/industria.model';
import { barsDataDS, barsDataset, lineDataDS, lineDataset } from '../../models/graphs.model';
import { stackCardContent, stackCardModel } from '../../models/cardStack,model';
import { durangoCommon } from '../../../shared/common';
import { sectoresEmpleos } from '../../models/empleosGenerados.model';

@Component({
  selector: 'competitivo-industria',
  templateUrl: './industria.component.html',
  styleUrl: './industria.component.css'
})
export class CompetitivoIndustriaComponent {

  public actividadIndustria?: industriaActividadModel;
  public lineJSON: string = '{}';
  public barJSON: string = '{}';
  public barJSON2: string = '{}';
  private meses: string[] = [];
  public maxMesesChart: number = 24;
  public PIBdata: string = '';
  public PIBdataPesos: string = '';
  private comun = new durangoCommon;
  private muniSector: industriaMunicipioSector[] = [];
  private minuI: number = 0;
  public municipio: string = '';
  public municipioMonto: string = '';
  public municipioSector: string = '';
  public mapCol: string = '#004e89';
  public mapBor: string = '#dfe6eb';
  public mapText: any;
  public empleosArr: sectoresEmpleos[] = [];
  private empInt: number = 0;
  public empTitle: string = 'Cargando...';


  constructor() {
  }

  ngOnInit() {
    this.lineJSON = this.cargaDatosLN();
    this.barJSON2 = this.cargaExportaciones();
    this.barJSON = '';
    this.PIBdata = this.generaPIBData();
    this.PIBdataPesos = this.generaPIBDataPesos();
    this.getMunicipioSector();
    this.getParquesIndustriales();
    this.cargaEmpleos();
  }




  private cargaDatosLN() {
    let res = '{}';
    try {
      this.meses = require('../../../assets/json/meses.json');
      this.actividadIndustria = require('../../../assets/json/IndustriasManufactureras.json');
      if (this.actividadIndustria != null && this.actividadIndustria.Actividad.length > 0) {
        // Todo esto es por si nos dan meses o años salteados
        let orderAnios: industriaActividadAnios[] = this.actividadIndustria.Datos.sort((a, b) => b.Año - a.Año);
        let anios: industriaActividadAnios[] = [];
        anios.push(orderAnios[0])
        if (orderAnios.length > 1)
          anios.push(orderAnios[1]);
        let sumame = 100;
        let meses: industriaActividadMeses[] = [];
        orderAnios.forEach(anio => {
          anio.Meses.forEach(mes => {
            mes.Año = anio.Año;
            mes.MesId = this.meses.indexOf(mes.Mes)
            mes.MesId -= sumame;
            // Esto es para quitar los anuales
            if (mes.Mes == 'Anual')
              mes.MesId = -999999;
            meses.push(mes);
          });
          sumame += 100;
        });
        orderAnios = [];
        meses.sort((a, b) => b.MesId! - a.MesId!);
        meses.splice(-(meses.length - this.maxMesesChart))
        meses.reverse();
        res = this.generaLineDS(meses);
      }
    } catch (ex) {
      console.log(ex)
      res = '{}';
    }
    return res;
  }


  private generaLineDS(mesesDS: industriaActividadMeses[]) {
    let lineDS: lineDataset = {
      dataset: [],
      dataLabels: []
    }
    let labels: string[] = [];
    let datasVal: number[] = [];
    let width = window.outerWidth * 0.6;
    let height = width * 0.4;

    mesesDS.forEach(mes => {
      labels.push(mes.Mes.substring(0, 3) + ' ' + mes.Año?.toString().substring(2, 4));
      datasVal.push(mes.Valor);
    });

    let datas: lineDataDS = {
      name: 'Industrias manufactureras',
      label: 'Industrias manufactureras',
      color: "#494a4a",
      data: datasVal,
      borderWidth: 1
    }

    lineDS.dataLabels = labels;
    lineDS.dataset.push(datas);
    lineDS.width = width;
    lineDS.height = height;
    lineDS.aspect = false;
    lineDS.bgColor = '#FFFFFF';
    lineDS.showx = true;
    lineDS.showy = true;

    return JSON.stringify(lineDS);
  }

  private generaPIBData() {
    let pib1: stackCardContent = {
      title: '',
      valueh1: '16.2%',
      contentClass: 'text-center',
      footer: 'Contribución al PIB estatal'
    }
    let model: stackCardModel = {
      title: '',
      period: '',
      data: [
        pib1
      ]
    }

    return JSON.stringify(model);
  }

  private generaPIBDataPesos() {
    let pib1: stackCardContent = {
      title: '',
      valueh1: '$ 48,693',
      contentClass: 'text-center',
      footer: 'Millones de Pesos'
    }
    let model: stackCardModel = {
      period: '',
      data: [
        pib1
      ]
    }

    return JSON.stringify(model);
  }



  private cargaEmpleos() {
    try {
      this.empleosArr = require('../../../assets/json/empleosMesIndustria.json');
      this.cambiaempleo();
    } catch (ex) {
      console.log(ex)
    }
  }

  public actEmpleo(atras:boolean){
    let emps = this.empleosArr.length;
    if (atras) {
      if (this.empInt == 0) {
        this.empInt = (emps - 1);
      } else {
        this.empInt -= 1;
      }
    } else {
      if (this.empInt == emps - 1) {
        this.empInt = 0;
      } else {
        this.empInt += 1;
      }
    }
    this.cambiaempleo();
  }

  public cambiaempleo(){
    let thisSect: sectoresEmpleos = this.empleosArr[this.empInt];
    
    let width = window.outerWidth * 0.75;
    let height = width * 0.25;
    let colores = this.comun.getStdColors();
    let ds: barsDataset = {
      label: 'Empleos en Sector',
      axis: 'x',
      beginAt: 85000,
      showx: false,
      showy: true,
      width: width,
      height: height,
      bgColor: '#FFF',
      cid: 'chrtEmpleoInd',
      dataset: []
    }

    let i = 0;
    this.empTitle = thisSect.Sector;
    thisSect.Empleos.forEach(dt => {
      dt.Meses.forEach(mes => {
        let nvo: barsDataDS = {
          name: mes.Mes.substring(0,3) + ' ' + dt.Año.toString().substring(2,dt.Año.toString().length),
          value: mes.Empleos,
        }
        ds.dataset.push(nvo)
      });
    });

    for (let index = 0; index < ds.dataset.length; index++) {
      ds.dataset[index].color = colores[i];
      i = (i < colores.length - 1) ? i + 1 : 0;
    }
    this.barJSON = JSON.stringify(ds);
    this.comun.updateBarChart('chrtEmpleoInd', ds.dataset);
  }

  private cargaExportaciones() {
    let res = '{}';
    try {
      let dataArr: industriaBarchrt[] = [];
      dataArr = require('../../../assets/json/IndustriasExportaciones.json');
      if (dataArr != null && dataArr.length > 0) {
        dataArr.sort((a, b) => b.monto - a.monto);
        res = this.generateBarDS(dataArr, 'Millones de Dólares');
      }
    } catch (ex) {
      console.log(ex)
      res = '{}';
    }
    return res;
  }

  private generateBarDS(dataArr: industriaBarchrt[], label: string = '') {
    let res = '';
    let width = window.outerWidth * 0.75;
    let height = width * 0.25;
    let colores = this.comun.getStdColors();
    let ds: barsDataset = {
      label: label,
      axis: 'y',
      beginAt: 0,
      showx: false,
      showy: true,
      width: width,
      height: height,
      bgColor: '#FFF',
      dataset: []
    }

    let i = 0;
    dataArr.forEach(dt => {
      let nvo: barsDataDS = {
        name: dt.rubro,
        value: dt.monto,
      }
      ds.dataset.push(nvo)
    });

    for (let index = 0; index < ds.dataset.length; index++) {
      ds.dataset[index].color = colores[i];
      i = (i < colores.length - 1) ? i + 1 : 0;
    }
    res = JSON.stringify(ds);
    return res;
  }


  private getMunicipioSector() {
    try {
      this.muniSector = require('../../../assets/json//IndustriasInvMunicipios.json');
      if (this.muniSector.length > 0) {
        this.municipio = this.muniSector[0].municipio;
        this.municipioMonto = Intl.NumberFormat('es-MX', { style: 'decimal' }).format(this.muniSector[0].monto,);
        this.municipioSector = this.muniSector[0].sector;
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  public minuChange(atras: boolean) {
    let max = this.muniSector.length;
    if (atras) {
      if (this.minuI == 0) {
        this.minuI = max - 1;
      } else {
        this.minuI -= 1;
      }
    } else {
      if (this.minuI < max - 1) {
        this.minuI += 1;
      } else {
        this.minuI = 0;
      }
    }

    this.municipio = this.muniSector[this.minuI].municipio;
    this.municipioMonto = Intl.NumberFormat('es-MX', { style: 'decimal' }).format(this.muniSector[this.minuI].monto,);
    this.municipioSector = this.muniSector[this.minuI].sector;
  }

  private getParquesIndustriales() {
    let res = '';
    try {
      let plantas: industriaPlantas[] = require('../../../assets/json//IndustriaPlantas.json');
      let municipios = require('../../../assets/json/municipios.json');
      if (plantas.length > 0) {
        let datos: muniMapaCoord[] = [];

        plantas.forEach(pl => {
          let exis = datos.find(x => x.municipio === pl.municipio);
          if (exis) {
            exis.plantas.push(pl.planta);
          } else {
            let mn = municipios.find((x: { municipio: string; }) => x.municipio === pl.municipio);
            let nvd: muniMapaCoord = {
              municipio: pl.municipio,
              plantas: [pl.planta],
              x: mn.x,
              y: mn.y
            }
            datos.push(nvd);
          }
        });

        let svg = document.getElementById('mapDurangoInd')
        if (svg) {
          let lastp = document.getElementById('layer1')
          if (lastp) {
            datos.forEach(dt => {
              let nvo = document.createElementNS('http://www.w3.org/2000/svg', 'text');
              let nvoContent = document.createTextNode('🎯');
              nvo.appendChild(nvoContent);
              let plantas: string = '';
              dt.plantas.forEach(pla => {
                plantas += "<li><span style='color: black'>" + pla + "</span></li>";
              });

              lastp?.appendChild(nvo);
              nvo.setAttribute('x', dt.x.toString());
              nvo.setAttribute('y', dt.y.toString());
              nvo.setAttribute('style', "font-size: 40px; font-style: normal; font-weight: bolder; fill: #ff0202; fill-opacity: 1; stroke: none; stroke-width: 1px; stroke-linecap: butt; stroke-linejoin: miter; stroke-opacity: 1; font-family: Bitstream Vera Sans;");
              nvo.setAttribute('onmousemove', "showTooltip(evt, `<div style='color: white; background-color: red; padding: 5px;'>" + dt.municipio + "</div><ol style='color: red; padding-right: 10px;'>" + plantas + "</ol>`);");
              nvo.setAttribute('onmouseout', "hideTooltip();");
            });
          }
        }

      }
    } catch (ex) {
      console.log(ex)
    }

    return res;
  }

}

