import { Component, Input } from '@angular/core';
import { comparativaEmpData } from '../../models/graphs.model';

declare var bootstrap: any;

@Component({
  selector: 'empleos-comparativa',
  templateUrl: './comparativa.component.html',
  styleUrl: './comparativa.component.css'
})
export class EmpleosComparativaComponent {
  @Input({ required: true }) ds?: string;

  private obj?: comparativaEmpData;
  public generados: number = 0;
  public perdidos: number = 0;
  public totales: number = 0;
  public generadostStr: string = '--';
  public perdidostStr: string = '--';
  
  ngOnInit(): void {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.obj = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }

    if(this.obj){
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      })

      this.totales = this.obj?.totales;
      this.generados = this.obj?.generados;
      this.perdidos = this.obj?.perdidos;
      this.progresbarr('progGen');
      this.progresbarr('progPerd');
      this.generadostStr = Intl.NumberFormat('es-MX', { style: 'decimal' }).format( this.generados, );
      this.perdidostStr = Intl.NumberFormat('es-MX', { style: 'decimal' }).format( this.perdidos, );
    }

  }

  progresbarr(element: string){
    let perc = (this.generados/this.totales) * 100;
    if(element == 'progPerd'){
      perc = (this.perdidos/this.totales) * 100;
    }
    let pb = document.getElementById(element);
    if(pb)
      pb.style.width = perc.toString() + '%';
  }


}
