import { Component, Input } from '@angular/core';
import { circularDataDS, circularDataset } from '../../models/graphs.model';

@Component({
  selector: 'empleos-nuevos',
  templateUrl: './nuevos.component.html',
  styleUrl: './nuevos.component.css'
})
export class EmpleosNuevosComponent {
  
  @Input({ required: true }) ds?: string;
  @Input({ required: true }) ultAnio?: string;
  @Input({ required: true }) ultMes?: string;

  public empleosJSON: string = '{}';
  private arcData?: circularDataDS[];
  
  constructor() {
  }

  ngOnInit() {
    try {
      if (this.ds) {
        const jsObject = JSON.parse(this.ds);
        this.arcData = jsObject;
      }
    } catch (e) {
      this.ds = undefined;
      console.log(e);
    }
    this.empleosJSON = this.getEmpleosDS();
  }

  getEmpleosDS() {
    let resStr = '{}';
    if(this.arcData){
      let nvos: number = 0;
      let ext: number = 0;
      this.arcData.forEach(dt => {
        if(dt.name == 'Nuevos'){
          nvos = dt.data;
        }else{
          ext = dt.data;
        }
      });
      var degrees: number = (((nvos/ext) * 100) ) - 350
      let arcDS: circularDataset ={
        dataset: this.arcData,
        rotate: Math.abs(degrees) + 45,
        cutout: 70,
        title: nvos.toString() + '\nNuevos',
        width: 250,
        height: 250,
        bgColor: "#FFFFFF",
        aspect: false
      }
      resStr = JSON.stringify(arcDS);

    }
    return resStr;
  }

}
