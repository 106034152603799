import { Component, Input, SimpleChanges } from '@angular/core';
import { dataCardTab, dataCardWithTabs } from '../models/cardWithTabs.model';
import { Chart } from 'chart.js';
import { circularDataset } from '../models/graphs.model';
import { durangoCommon } from '../../shared/common';

@Component({
  selector: 'app-card-with-tabs',
  templateUrl: './card-with-tabs.component.html',
  styleUrl: './card-with-tabs.component.css'
})
export class CardWithTabsComponent {
  @Input({required:  true}) data!: string;

  dataCard!: dataCardWithTabs;
  tabSelect: number = 0;
  dataSelect!: dataCardTab | undefined;
  arcDS?: string;
  barDS?: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] !== undefined) {
      this.dataCard = JSON.parse(changes['data'].currentValue);   
      this.selectContentCard(0);
    }
  }


  tabHandle(i: number) {
    this.tabSelect = i;
    this.selectContentCard(i);
  }

  selectContentCard(i : number) {
    const data = this.dataCard.data;
    this.dataSelect = data ? data.find((el, index) => index === i) : undefined;
    this.arcDS = undefined;
    if(this.dataSelect?.arcData){
      this.arcDS = JSON.stringify(this.dataSelect.arcData);
      let comun = new durangoCommon;
      comun.updateArcChart(this.dataSelect.arcData.cid!, this.dataSelect.arcData.dataset)
    }
    this.barDS = undefined;
    if(this.dataSelect?.barData){
      this.barDS = JSON.stringify(this.dataSelect.barData);
      let comun = new durangoCommon;
      comun.updateBarChart(this.dataSelect.barData.cid!, this.dataSelect.barData.dataset)
    }
  }
  

}
